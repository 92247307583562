import { CallActionTypes } from "../../../Globals"

const now = new Date()


export enum ActionTypes  {
    Prospect   = 0 , 
    Calls = 1, 
    Presentation = 2 , 
    Followup = 3 , 
    VisitSite = 4, 
    Closing = 5, 
    PlanMeeting = 6 , 
}


export type TCalender = {
    id : number, 
    title : string, 
    start : Date , 
    end : Date, 
    type : CallActionTypes,
    NumberOfReservs? : number 
}   

let data : TCalender[]  = [
  {
    id: 28,
    title: 'اجتماع مصغر مع ناس كبيره',
    start: new Date(new Date().setHours(new Date().getHours() +1)),
    end: new Date(new Date().setHours(new Date().getHours() + 2)),
    type : CallActionTypes.Prospect
  },
  {
    id: 29,
    title: 'اجتماع ثاني مصغر مع ناس كبيره',
    start: new Date(new Date().setHours(new Date().getHours() + 3)),
    end: new Date(new Date().setHours(new Date().getHours() + 4)),
    type : CallActionTypes.Prospect
  },
  {
    id: 30,
    title: 'اجتماع ثالث',
    start: new Date(new Date().setHours(new Date().getHours() + 5)),
    end: new Date(new Date().setHours(new Date().getHours() + 6)),
    type : CallActionTypes.Prospect
  },
  {
    id: 31,
    title: 'اجتماع مصغر مع ناس كبيره',
    start: new Date(new Date().setHours(new Date().getHours() + 7)),
    end: new Date(new Date().setHours(new Date().getHours() + 8)),
    type : CallActionTypes.Prospect
  },
  {
    id: 32,
    title: 'اجتماع ثاني مصغر مع ناس كبيره',
    start: new Date(new Date().setHours(new Date().getHours() + 9)),
    end: new Date(new Date().setHours(new Date().getHours() + 10)),
    type : CallActionTypes.Prospect
  },
  {
    id: 33,
    title: 'اجتماع ثالث',
    start: new Date(new Date().setHours(new Date().getHours() + 11)),
    end: new Date(new Date().setHours(new Date().getHours() + 12)),
    type : CallActionTypes.Prospect
  },
]


export default data 