import React from 'react'
import './Loading.css'
import { AnimatedTheTeam } from '../../components/Icons/Icons'

function Loading() {
    return (
        <div className='LoadingSC'>

            <div className='w-2/4 h-2/4'>
                {/* <AnimatedLogo /> */}
                <AnimatedTheTeam />
            </div>
        </div>
    )
}

export default Loading