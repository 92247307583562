import React, { ReactNode, useEffect, useRef } from 'react'
import './style.css'

type TGModal = {
    children: ReactNode,
    onCloseModal: () => void,
    OpenModal: boolean,
    tailwindHeight?: string
}


const GModal = ({ children, onCloseModal, OpenModal, tailwindHeight }: TGModal) => {
    //Refs
    const GModalRef = useRef<HTMLDivElement>(null)

    //constants 
    if(tailwindHeight === undefined) tailwindHeight = 'h-[85vh]'



    //functions 

    const hideGlobalModal = () => {
        GModalRef.current?.classList.add('hideGlobal');
        GModalRef.current?.classList.remove('showGlobal');
    };

    const showGlobalModal = () => {
        GModalRef.current?.classList.add('showGlobal');
        GModalRef.current?.classList.remove('hideGlobal');
    };

    useEffect(() => {
        if (OpenModal === true) {
            showGlobalModal()
        } else {
            hideGlobalModal()
        }
    }, [OpenModal])

    return (
        <div ref={GModalRef} className={`z-[100] w-full ${tailwindHeight} p-10 fixed top-0 flex justify-center items-center transition-all ease-in-out duration-[1000ms]`}>
            <div className="w-full h-full relative bg-slate-50 shadow-md rounded-xl p-5 overflow-y-auto">
                {children}
                <div className='absolute top-2 left-2 i-material-symbols-close-rounded text-3xl text-red-500 hover:cursor-pointer' onClick={() => {
                    hideGlobalModal()
                    onCloseModal()
                }}>
                </div>
            </div>
        </div>
    )
}

export default GModal