import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store';

export interface IActionFeed {
    status: 'success' | 'error' | 'warning' | 'info',
    message: string,
    idx? : number
}

const initialState: IActionFeed[] = [];

export const ActionsSlice = createSlice({
    name: 'ActionFeed',
    initialState,
    reducers: {
        AddAction: (state, data: PayloadAction<IActionFeed>) => {
            state.push({...data.payload,idx : new Date().getUTCMilliseconds()})
           // return [...state,{...data.payload,idx : new Date().getUTCMilliseconds()}]
        },
        DeleteActon: (state, data: PayloadAction<number>) => {
             return  state.filter(act => act.idx !== data.payload)
        }
    },
})

export const ActionState = (state: RootState) => state.ActionStore;


export const { AddAction, DeleteActon } = ActionsSlice.actions
export default ActionsSlice.reducer