// hoc/withAuth.tsx
import React, { ComponentType } from 'react';
import { Navigate } from 'react-router-dom';
import { CookiesData, getCookies } from '../Globals';


export interface WithAuthProps {
  test? : string;
}

const withAuth = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const ComponentWithAuth: React.FC<P & WithAuthProps> = (props) => {
    const isAuthenticated = getCookies(CookiesData.LoggedIn)
    if (!isAuthenticated) {
      // Redirect to login if the user is not authenticated
      window.location.href = 'https://the-team.co/screens/signin';
      return null;
    }

    // Render the wrapped component if authenticated
    return <WrappedComponent {...props} />;
  };

  return ComponentWithAuth;
};

export default withAuth;
