import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, TextField, useTheme } from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { Connect, fetchTodos } from '../../redux/slices/CheckConnection';
import { GetConnectionFromServer1, Login, getVersion } from '../../Service/Apis';
import { CustomTextField } from '../../CustomComps/Customs';
import { tokens } from '../../theme';
import { LoadingState, setLoadingFalse, setLoadingTrue } from '../../redux/slices/LoadingSlice';
import { AlertFeedback, EgButton, SnackbarFeedback } from '../Shared/Shared';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GeneralSettings from './SetupPannels/GeneralSettings/GeneralSettings';
import { Link } from 'react-router-dom';
import ProjectAdd from './SetupPannels/ProjectsData/ProjectAdd';


interface Inputs {
    name: string;
}

function Setup() {

    const Connection = useSelector((state: RootState) => state.connection.status);
    const Loading = useSelector(LoadingState);
    const dispatch = useDispatch<AppDispatch>();

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };



    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    //   const pingHostFromClient = ()=>{
    //     PingHost(); 
    //   }

    const [name, setName] = useState<string>();
    const { control, handleSubmit, reset, watch } = useForm<Inputs>({
        reValidateMode: 'onBlur'
    });

    const currentName = watch('name');

    const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
        // setName(data.name);
        // reset();
        
    };

    // const onSubmitHandler: SubmitHandler<ILogin> = (values: ILogin) => {
    //     
    //   };

    return (
        <>
            <div>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    // aria-controls="panel1bh-content"
                    // id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            الإعدادات العامه
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>الإعدادات العامه الخاصه بشركه التسويق</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <GeneralSettings />
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    // aria-controls="panel1bh-content"
                    // id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            المشاريع
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>اضافه مدن , مشاريع , مباني و وحدات</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ProjectAdd />
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    //aria-controls="panel2bh-content"
                    //id="panel2bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}> الهيكل الوظيفي</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>
                            اضافه و تعديل و حذف للمستخدمين الحالين و وظائفهم
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack direction='row' justifyContent='space-around'>
                            <Box width='20%'>
                                <Link to='/dashboard/users'>
                                    <EgButton HandleClick={() => { }} >
                                        <Typography fontWeight='bold' variant="h6">المستخدمين</Typography>
                                    </EgButton>
                                </Link>
                            </Box>
                            <Box width='20%'>
                                <Link to='/dashboard/jobs'>
                                    <EgButton HandleClick={() => { }} >
                                        <Typography fontWeight='bold' variant="h6">الوظائف</Typography>
                                    </EgButton>
                                </Link>
                            </Box>
                        </Stack>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            الدفع
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>
                            تحديد انواع و خطط الدفع
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
                            amet egestas eros, vitae egestas augue. Duis vel est augue.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>التعريفات</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>تحديد التعريفات العامه المستخدمه في البرنامج</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
                            amet egestas eros, vitae egestas augue. Duis vel est augue.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    )
}

export default Setup




/** */

{/* <Button
onClick={()=> {
    // Login({Password : 'ahmed', UserName: "ahmed"}).then(res => {
    //     // getVersion().then(res => 
    // }).catch(err => {});
   
    dispatch(fetchTodos({password : 'ahmed', userName : 'ahmed'}))
}}
>
<Typography variant="h6" color="initial">Connect</Typography>
</Button>
<Button
onClick={()=> {
    getVersion().then(res => {
        
        // getVersion().then(res => 
    }).catch(err => {
   
    // dispatch(fetchTodos({password : 'ahmed', userName : 'ahmed'}))
}}
>
<Typography variant="h6" color="initial">getVersion</Typography>
</Button> */}
//  <form onSubmit={handleSubmit(onSubmit)}>
// <Controller
//     control={control}
//     name='name'
//     defaultValue=''
//     rules={
//         {
//             required: true,
//             minLength: 10
//         }
//     } 
// render={({
//     // field: { onChange, onBlur, value, name, ref },
//     field,
//     fieldState: { invalid, isTouched, isDirty, error },
//     formState,
// }) => {
//     return (
//         <>
//             {/* <TextField {...field} id={field.name} name={field.name} helperText={error?.message ?? ""} /> */}
//             <CustomTextField label='إسم الشركه' colors={colors} theme={theme} {...field} name={field.name} />
//             {formState.errors.name && formState.errors.name.type}
//         </>
//     )
// }}
// />
// <Controller
//     control={control}
//     name='name'
//     defaultValue=''
//     rules={
//         {
//             required: true,
//             minLength: 10
//         }
//     }
//     render={({
//         // field: { onChange, onBlur, value, name, ref },
//         field,
//         fieldState: { invalid, isTouched, isDirty, error },
//         formState,
//     }) => {
//         return (
//             <>
//                 {/* <TextField {...field} id={field.name} name={field.name} helperText={error?.message ?? ""} /> */}
//                 <CustomTextField label='التليفون' colors={colors} theme={theme} {...field} name={field.name} />
//                 {formState.errors.name && formState.errors.name.type}
//             </>
//         )
//     }}
// />
//                 <Controller
//     control={control}
//     name='name'
//     defaultValue=''
//     rules={
//         {
//             required: true,
//             minLength: 10
//         }
//     }
//     render={({
//         // field: { onChange, onBlur, value, name, ref },
//         field,
//         fieldState: { invalid, isTouched, isDirty, error },
//         formState,
//     }) => {
//         return (
//             <>
//                 {/* <TextField {...field} id={field.name} name={field.name} helperText={error?.message ?? ""} /> */}
//                 <CustomTextField label='احمد ابو عماد' colors={colors} theme={theme} {...field} name={field.name} />
//                 {formState.errors.name && formState.errors.name.type}
//             </>
//         )
//     }}
// />
// <Button
//     type='submit'
//     title='حفظ'
// >
//     <Typography variant="h5">حفظ</Typography>
// </Button>
// <TextField  id="name" name="name" helperText="Enter your name and press [Enter]" />
// </form>
{/* <div>
{Loading.toString()}
{Connection}
{currentName && <div>Current: {currentName}</div>}
{name && <div>Submitted: {name}</div>}
</div> */}