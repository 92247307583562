import { UserModel } from './../../Service/models/UserModel/UserModel';
import { createSlice, PayloadAction, createAsyncThunk, AsyncThunkPayloadCreator } from '@reduxjs/toolkit'
import { GetAllChildsAPI, GetAllUsers, GetCompany, GetConnectionFromServer1, getUserById, Login, RegisterUser, SaveCompany, SaveUserAPI } from '../../Service/Apis';
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingFalse, setLoadingTrue } from './LoadingSlice';
import { hideFeedBack, showFeedBack } from './Feedback';
import { saveCompanyRQ } from '../../Service/models/company/SaveCompanyRQ';
import { GetCompanyRS } from '../../Service/models/company/GetCompanyRS';
import { AppDispatch, RootState } from '../store';
import { AxiosError } from 'axios';
import { TeamModel } from '../../Service/models/TeamModel/TeamModel';
import { GetError } from '../../Globals';



export interface ITeamSlice {
  Team: TeamModel,
  Error: string,
  Loading: boolean,
  Empty: TeamModel, 
}

const initialState: ITeamSlice = {
  Team: {} as TeamModel,
  Error: "",
  Loading: false,
  Empty: {} as TeamModel, 
}

export const TeamSlice = createSlice({
  name: 'Team',
  initialState,
  reducers: {
    // GetAllUsersReducer: (state, action: PayloadAction<UserModel[]>) => {
    //   state.status = action.payload 
    // },
    // SetCurrentUserReducer : (state, action : PayloadAction<UserModel>) => {
    //   state.currentUser = action.payload 
    // }

  },
  extraReducers: (builder) => {

    builder.addCase(GetAllUserChildrenThunk.fulfilled,
      (state, { payload }) => {
        // We add all the new todos into the state
        // and change `status` back to `idle`:
        // state.list.push(...payload);
        // state.status = "idle";
         state.Team = payload as TeamModel;
      });

    // When a server responses with an error:
    builder.addCase(GetAllUserChildrenThunk.rejected,
      (state, { payload }) => {
        // We show the error message
        // and change `status` back to `idle` again.
        // if (payload) state.error = payload.message;
        state.Error = payload as string; 

      });



  }
})

type FetchTodosError = {
  message: string;
};

export const GetAllUserChildrenThunk = createAsyncThunk<TeamModel | string>(
  // The first argument is the action name:
  "Team/AllChilds",

  // The second one is a function
  // called payload creator.
  // It contains async logic of a side-effect.
  // We can perform requests here,
  // work with device API, 
  // or any other async APIs we need to.

  // The second argument, `thunkApi`, is an object
  // that contains all those fields
  // and the `rejectWithValue` function:

  // The third type-parameter is an object with:
  // `{dispatch?, state?, extra?, rejectValue?}`` fields.
  //
  // `extra` is useful when we need to pass 
  // some static data to the request function,
  // like jwt-token or HTTP-headers.
  //
  // `rejectValue` is useful when we need to type 
  // possible errors.

  async (team, thunkApi) => {

    thunkApi.dispatch(setLoadingTrue());

    return GetAllChildsAPI().then(res => {
      thunkApi.dispatch(setLoadingFalse());
      //thunkApi.dispatch(showFeedBack({ show: true, message: "تمت إضافه او تعديل المستخدمosz", status: "success" }))
      return res as TeamModel[]
    }).catch(err => {
      return  GetError(err,thunkApi) 
    });
  }
);


export const TeamState = (state: RootState) => state.TeamStore;
export const {} = TeamSlice.actions

export default TeamSlice.reducer