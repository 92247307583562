import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store';

export interface FeedBack {
  status  : 'success' | 'error' | 'warning' | 'info', 
  message : string, 
  show : boolean
}

const initialState: FeedBack = {
  status : 'success', 
  message : 'تمت العمليه بنجاح',
  show : false
}

export const FeedbackSlice = createSlice({
  name: 'FeedBack',
  initialState,
  reducers: {
    showFeedBack: (state,data : PayloadAction<FeedBack>) => {
        state.message = data.payload.message 
        state.show = state.status == 'success' ?false : true 
        // state.show = false
        state.status = data.payload.status
    },
    hideFeedBack : (state) =>{
      state.show = false ;
    }
  },
})

export const FeedbackState = (state: RootState) => state.feedback.message;
export const FeedbacStatus = (state: RootState) => state.feedback.status;
export const FeedbacFlag = (state: RootState) => state.feedback.show;


export const { showFeedBack ,hideFeedBack} = FeedbackSlice.actions
export default FeedbackSlice.reducer