import React, { useEffect, useState } from 'react'
import MulitDD from '../../../components/MultiDD'
import { GetAllUserDetail, SaveSalary } from '../../../Service/Apis'
import { USER_Detail } from '../../../Service/models/UserModel/UserModel';
import { DropDownData } from '../Actions/ActionsTypesScreen/AppointMents';
import { GetCurrentYearNumber, GetErrorAxios, commafy } from '../../../Globals';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { CustomTextFieldFullHeight } from '../../../CustomComps/Customs';
import { useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import { UserSalaries } from '../../../Service/models/Salary_Model';
import { EgButton } from '../../Shared/Shared';
import { AddAction } from '../../../redux/slices/ErrorsSlice';
import { setLoadingFalse, setLoadingTrue } from '../../../redux/slices/LoadingSlice';

const UpdateSalary = ({ salary }: { salary: UserSalaries }) => {

    //constants 
    const dispatch = useDispatch<AppDispatch>()
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const MainInputStyle = { marginBottom: 2, width: '100%', backgroundColor: 'white' };

    //states
    const [users, setUsers] = useState<USER_Detail[]>([]);
    const [usersDD, setUsersDD] = useState<DropDownData[]>([])
    const [DefaultUserIds, setDefaultUserIDS] = useState<number[]>([])
    const [UserMonth, setDefaultUserMonth] = useState<number[]>([])
    const [userYear, setDefaultUserYear] = useState<number[]>([])

    const [saveSalary, setSaveSalary] = useState<UserSalaries>(salary)
    const [ammoundDisplay, setAmoundDisplay] = useState<string>("0")

    //functions
    const getUsersDropDown = async () => {
        GetAllUserDetail({ WithAdmin: false, WithNewUsers: false }).then(res => {
            setUsers(res)
            setUsersDD(res.map(item => ({ id: item.ID, desc: item.FULL_NAME })))
        }).catch(err => {
            GetErrorAxios(err, dispatch)
        })
    }

    const SaveSallary = () => {
        dispatch(setLoadingTrue())
        SaveSalary(saveSalary).then(res => {
            dispatch(setLoadingFalse())
            dispatch(AddAction({ message: "تم تعديل المرتب بنجاح", status: 'success' }))
            window.location.reload()
        }).catch(err => {
            GetErrorAxios(err, dispatch)
        })
    }

    //useEffects
    useEffect(() => {
        getUsersDropDown()
    }, [])

    useEffect(() => {
        setSaveSalary(salary)

        if (salary.AMOUNT !== undefined) {
            setAmoundDisplay(salary.AMOUNT.toString())
        }
        setDefaultUserIDS([salary.USER_ID!])
        setDefaultUserMonth([salary.MONTH!])
        setDefaultUserYear([salary.YEAR!])
    }, [salary])


    return (
        <div className='mt-10 flex flex-col justify-start gap-2 items-start'>
            {/* المستخدم */}
            <div className='w-1/2'>
                <p>المستخدم</p>
                <div className='w-full mt-1'>
                    {usersDD.length > 0 && (<MulitDD OuterDropDownData={usersDD} SelectedIds={(ids) => {
                        setSaveSalary(prev => ({ ...prev, USER_ID: ids[0] }))

                    }} SingleSelection InitialSelectedIds={DefaultUserIds} />)}
                </div>
            </div>

            {/* الشهر */}
            <div className='w-1/2'>
                <p>الشهر</p>
                <div className='w-full mt-1'>
                    <MulitDD OuterDropDownData={[
                        { id: 1, desc: "يناير" },
                        { id: 2, desc: "فبراير" },
                        { id: 3, desc: "مارس" },
                        { id: 4, desc: "ابريل" },
                        { id: 5, desc: "مايو" },
                        { id: 6, desc: "يونيو" },
                        { id: 7, desc: "يوليو" },
                        { id: 8, desc: "اغسطس" },
                        { id: 9, desc: "سبتمير" },
                        { id: 10, desc: "اكتوبر" },
                        { id: 11, desc: "نوفمبر" },
                        { id: 12, desc: "ديسمبر" },
                    ]} SelectedIds={(ids) => {
                        setSaveSalary(prev => ({ ...prev, MONTH: ids[0] }))
                    }} SingleSelection InitialSelectedIds={UserMonth} />
                </div>
            </div>

            {/* السنه */}
            <div className='w-1/2'>
                <p>السنه</p>
                <div className='full mt-1'>
                    <MulitDD OuterDropDownData={[
                        { id: GetCurrentYearNumber(), desc: GetCurrentYearNumber().toString() },
                        { id: GetCurrentYearNumber() - 1, desc: (GetCurrentYearNumber() - 1).toString() },
                    ]} SelectedIds={(ids) => {
                        setSaveSalary(prev => ({ ...prev, YEAR: ids[0] }))
                    }} SingleSelection InitialSelectedIds={userYear} />
                </div>
            </div>

            {/* المرتب  */}
            <div className='w-1/2'>
                <label htmlFor="SalaryAmount">المرتب</label>
                <div>
                    <CustomTextFieldFullHeight height={25} id='SalaryAmount' value={ammoundDisplay} type={''} onChange={(e: any) => {
                        let val = e.target.value;
                        if (!isNaN(val)) {
                            let AmountNumber = parseFloat(val)
                            setSaveSalary(prev => ({ ...prev, AMOUNT: AmountNumber }))
                            setAmoundDisplay(val)
                        }
                    }} style={MainInputStyle}
                        colors={colors} theme={theme} />
                </div>
            </div>

            {/* حفظ و ارسال */}
            <div className='grow'>
                <EgButton HandleClick={() => {
                    SaveSallary()
                }}>

                    <h5>تعديل</h5>
                </EgButton>
            </div>
        </div>
    )
}

export default UpdateSalary