import { GetUsersWithAdmins, UserModel } from './../../Service/models/UserModel/UserModel';
import { createSlice, PayloadAction, createAsyncThunk, AsyncThunkPayloadCreator } from '@reduxjs/toolkit'
import { GetAllUsers, GetCompany, GetConnectionFromServer1, getUserById, Login, RegisterUser, SaveCompany, SaveUserAPI } from '../../Service/Apis';
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingFalse, setLoadingTrue } from './LoadingSlice';
import { hideFeedBack, showFeedBack } from './Feedback';
import { saveCompanyRQ } from '../../Service/models/company/SaveCompanyRQ';
import { GetCompanyRS } from '../../Service/models/company/GetCompanyRS';
import { AppDispatch, RootState } from '../store';
import { AxiosError } from 'axios';
import { GetError } from '../../Globals';
import { AddAction } from './ErrorsSlice';



export interface IUserSlice {
  status: UserModel[],
  Error: string,
  Loading: boolean,
  Empty: [], 
  currentUser : UserModel 
}

const initialState: IUserSlice = {
  status: [],
  Error: "",
  Loading: false,
  Empty: [], 
  currentUser : {} as UserModel
}

export const UserSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    GetAllUsersReducer: (state, action: PayloadAction<UserModel[]>) => {
      state.status = action.payload 
    },
    SetCurrentUserReducer : (state, action : PayloadAction<UserModel>) => {
      state.currentUser = action.payload 
    }

  },
  extraReducers: (builder) => {

    // When we send a request,
    // `fetchTodos.pending` is being fired:
    builder.addCase(SaveUserThunk.pending, (state) => {
      // At that moment,
      // we change status to `loading` 
      // and clear all the previous errors:
      // state.status = "loading";
      // state.error = null;
    });

    // When a server responses with the data,
    // `fetchTodos.fulfilled` is fired:
    builder.addCase(SaveUserThunk.fulfilled,
      (state, { payload }) => {
        // We add all the new todos into the state
        // and change `status` back to `idle`:
        // state.list.push(...payload);
        // state.status = "idle";
        // state.status = payload as saveCompanyRQ;
      });

    // When a server responses with an error:
    builder.addCase(SaveUserThunk.rejected,
      (state, { payload }) => {
        // We show the error message
        // and change `status` back to `idle` again.
        // if (payload) state.error = payload.message;
        // state.status = "idle";

      });


    builder.addCase(GetAllUsersThunk.pending, (state) => {
      // At that moment,
      // we change status to `loading` 
      // and clear all the previous errors:
      // state.status = "loading";
      // state.error = null;
    });

    // When a server responses with the data,
    // `fetchTodos.fulfilled` is fired:
    builder.addCase(GetAllUsersThunk.fulfilled,
      (state, { payload }) => {
        // We add all the new todos into the state
        // and change `status` back to `idle`:
        // state.list.push(...payload);
        // state.status = "idle";
         state.status = payload as UserModel[] ;
      });

    // When a server responses with an error:
    builder.addCase(GetAllUsersThunk.rejected,
      (state, { payload }) => {
        // We show the error message
        // and change `status` back to `idle` again.
        // if (payload) state.error = payload.message;
        state.Error = payload as string; 

      });
    builder.addCase(GetCurrentUserThunk.fulfilled,
      (state, { payload }) => {
        // We add all the new todos into the state
        // and change `status` back to `idle`:
        // state.list.push(...payload);
        // state.status = "idle";
         state.currentUser = payload as UserModel;
      });

    // When a server responses with an error:
    builder.addCase(GetCurrentUserThunk.rejected,
      (state, { payload }) => {
        // We show the error message
        // and change `status` back to `idle` again.
        // if (payload) state.error = payload.message;
        state.Error = payload as string; 

      });



  }
})

type FetchTodosError = {
  message: string;
};

export const SaveUserThunk = createAsyncThunk<void, UserModel>(
  // The first argument is the action name:
  "User/Save",

  // The second one is a function
  // called payload creator.
  // It contains async logic of a side-effect.
  // We can perform requests here,
  // work with device API, 
  // or any other async APIs we need to.

  // The second argument, `thunkApi`, is an object
  // that contains all those fields
  // and the `rejectWithValue` function:

  // The third type-parameter is an object with:
  // `{dispatch?, state?, extra?, rejectValue?}`` fields.
  //
  // `extra` is useful when we need to pass 
  // some static data to the request function,
  // like jwt-token or HTTP-headers.
  //
  // `rejectValue` is useful when we need to type 
  // possible errors.

  async (User, thunkApi) => {

    thunkApi.dispatch(setLoadingTrue());

    return SaveUserAPI(User).then(res => {
      thunkApi.dispatch(setLoadingFalse());
      //thunkApi.dispatch(showFeedBack({ show: true, message: "تمت إضافه او تعديل المستخدمosz", status: "success" }))
      thunkApi.dispatch(AddAction({ message: "تم بتجاح", status: "success" }))
    }).catch(err => {
      return GetError(err, thunkApi)
    });
  }
);
export const RegisterUserThunk = createAsyncThunk<string, UserModel>(
  // The first argument is the action name:
  "User/Register",

  // The second one is a function
  // called payload creator.
  // It contains async logic of a side-effect.
  // We can perform requests here,
  // work with device API, 
  // or any other async APIs we need to.

  // The second argument, `thunkApi`, is an object
  // that contains all those fields
  // and the `rejectWithValue` function:

  // The third type-parameter is an object with:
  // `{dispatch?, state?, extra?, rejectValue?}`` fields.
  //
  // `extra` is useful when we need to pass 
  // some static data to the request function,
  // like jwt-token or HTTP-headers.
  //
  // `rejectValue` is useful when we need to type 
  // possible errors.

  async (User, thunkApi) => {

    thunkApi.dispatch(setLoadingTrue());

    return RegisterUser(User).then(res => {
      thunkApi.dispatch(setLoadingFalse());
      thunkApi.dispatch(showFeedBack({ show: true, message: "تمت إضافه او تعديل المستخدم", status: "success" }))
      return "Done"
    }).catch(err => {
      let ERR = err as AxiosError
      let ErrMsg =  err.response.data.Error as string  || err.response.data as string ||  ERR.message + " At " + ERR.config?.url as string ;
      thunkApi.dispatch(setLoadingFalse());
      thunkApi.dispatch(showFeedBack({ show: true, message: ErrMsg, status: 'error' }))
      return err;
    });
  }
);

export const GetAllUsersThunk = createAsyncThunk<UserModel[] | string, null>(
  // The first argument is the action name:
  "User/GetAll",

  // The second one is a function
  // called payload creator.
  // It contains async logic of a side-effect.
  // We can perform requests here,
  // work with device API, 
  // or any other async APIs we need to.

  // The second argument, `thunkApi`, is an object
  // that contains all those fields
  // and the `rejectWithValue` function:

  // The third type-parameter is an object with:
  // `{dispatch?, state?, extra?, rejectValue?}`` fields.
  //
  // `extra` is useful when we need to pass 
  // some static data to the request function,
  // like jwt-token or HTTP-headers.
  //
  // `rejectValue` is useful when we need to type 
  // possible errors.

  async (User, thunkApi) => {

    thunkApi.dispatch(setLoadingTrue());

    return GetAllUsers().then(res => {
      thunkApi.dispatch(setLoadingFalse());
      thunkApi.dispatch(showFeedBack({ show: true, message: "تم تحديث المستخدمين", status: "success" }))
      return res
    }).catch(err => {
      let ErrMsg = err.response.data.Error || err.response.data as string;
      thunkApi.dispatch(setLoadingFalse());
      thunkApi.dispatch(showFeedBack({ show: true, message: ErrMsg, status: 'error' }))
      return ErrMsg
    });
  }
);
export const GetCurrentUserThunk = createAsyncThunk<UserModel | string, null>(
  // The first argument is the action name:
  "User/GetCurrent",

  // The second one is a function
  // called payload creator.
  // It contains async logic of a side-effect.
  // We can perform requests here,
  // work with device API, 
  // or any other async APIs we need to.

  // The second argument, `thunkApi`, is an object
  // that contains all those fields
  // and the `rejectWithValue` function:

  // The third type-parameter is an object with:
  // `{dispatch?, state?, extra?, rejectValue?}`` fields.
  //
  // `extra` is useful when we need to pass 
  // some static data to the request function,
  // like jwt-token or HTTP-headers.
  //
  // `rejectValue` is useful when we need to type 
  // possible errors.

  async (User, thunkApi) => {
    thunkApi.dispatch(setLoadingTrue());

    return getUserById().then(res => {
      thunkApi.dispatch(setLoadingFalse());
      thunkApi.dispatch(showFeedBack({ show: true, message: "المستخدم الحالي", status: "success" }))
      return res
    }).catch(err => {
      let ErrMsg = err.response.data.Error || err.response.data as string;
      thunkApi.dispatch(setLoadingFalse());
      thunkApi.dispatch(showFeedBack({ show: true, message: ErrMsg, status: 'error' }))
      return ErrMsg
    });
  }
);


export const UsersState = (state: RootState) => state.UserStore;
export const {GetAllUsersReducer, SetCurrentUserReducer} = UserSlice.actions

export default UserSlice.reducer