import styled from "styled-components";
import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography'
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Snackbar, Switch, SxProps, TextField, Theme, useTheme } from "@mui/material";
import { number } from "yup";
import { useDispatch, useSelector } from "react-redux";
import { FeedbacFlag, FeedbacStatus, FeedbackState, hideFeedBack } from "../../redux/slices/Feedback";
import { AppDispatch } from "../../redux/store";
import { tokens } from "../../theme";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from "@mui/system";
import { HTMLAttributes } from "react";





export const FullWidthDiv = styled.div`
background-color: black;
height : 100vh; 
`;


export function AlertFeedback({ message, status }: { message: string, status: 'success' | 'error' | 'warning' | 'info' }) {
  const [open, setOpen] = React.useState(true);

  return (
    <Box sx={{ width: '50%' }}>
      <Collapse in={open}>
        <Alert
          severity={status}
          sx={{ mb: 2, width: '100%' }}
          action={
            <Button>
              <Typography variant="body1" color="initial">إغلاق</Typography>
            </Button>
          }
        >
          <Typography variant="body1" color="initial">{message}</Typography>
        </Alert>
      </Collapse>
    </Box>
  );
}

export function SnackbarFeedback() {
  // const [open, setOpen] = React.useState(true);

  const feedbackMessage = useSelector(FeedbackState);
  const feedbackStatus = useSelector(FeedbacStatus);
  const feedbackFlag = useSelector(FeedbacFlag);

  const dispatch = useDispatch<AppDispatch>();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    // setOpen(false);
    dispatch(hideFeedBack());
  };

  return (
    <Box sx={{ width: '50%' }}>
      <Snackbar
        open={feedbackFlag}
        autoHideDuration={number == null || number === undefined ? number : 2000}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Alert severity={feedbackStatus} sx={{ width: '100%' }}>
          {feedbackMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}


// export const EG_Button = styled(Button)(() => ({

// }));

interface ISharedButton extends HTMLAttributes<HTMLElement> {
  children: JSX.Element,
  HandleClick: React.MouseEventHandler<HTMLButtonElement> | undefined,
  Action? : 'submit' | 'reset' | 'button', 
  Animated? : boolean
}

export function EgButton({ children, HandleClick,Action, Animated = false,...props }: ISharedButton) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let sx: SxProps<Theme> | undefined = {
    background: "#e7c498",
    height: '100%',
    color: "#000",
    borderRadius: '8px',
    '&:hover': {
      background: "#000",
      color : '#e7c498'
      // background: colors.primary[600]
    }
  }

  return <Button type={Action}  onClick={HandleClick} sx={sx} fullWidth >
    {children}
  </Button>
};


// export const EGButton = styled(Button)<{ colors: any }>(({ colors }) => ({
//   background: `${colors.primary[500]}`,
//   height: '100%',
//   color: 'white',
//   borderRadius: '8px',
//   '&:hover': {
//     background: `${colors.primary[600]}`,
//   }
// }));


interface IEGAccordion {
  children: JSX.Element,
  Title: string,
  Desc: string,
  PanelIdentfier: string
}

export function EGAccordion({ children, Title, Desc, PanelIdentfier }: IEGAccordion) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [expanded, setExpanded] = React.useState<string | false>(false);

  let sx: SxProps<Theme> | undefined = {
    background: colors.primary[500],
    height: '100%',
    color: "white",
    borderRadius: '8px',
    '&:hover': {
      background: colors.primary[600]
    }
  }

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return <Accordion expanded={expanded === PanelIdentfier} onChange={handleChange(PanelIdentfier)}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
    >
      <Typography sx={{ width: '33%', flexShrink: 0, fontSize: 22 }}>
        {Title}
      </Typography>
      <Typography sx={{ color: 'text.secondary' }}>{Desc}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      {children}
    </AccordionDetails>
  </Accordion>
}

interface IEGAutoComplete {
  // children: JSX.Element,
  label: string
}

export function EGAutoComplete({ label }: IEGAutoComplete) {


  interface UserType {
    code: number;
    Name: string;
  }

  const Users: UserType[] = [
    { code: 1, Name: '3attar' },
    { code: 2, Name: '3mad' },
    { code: 3, Name: 'Ref3at' },
    { code: 4, Name: 'abu gamal' },
  ]

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState<UserType | null>(Users[0]);

  const EgTextField = styled(TextField)`
  color : ${colors.primary[500]}
  & .MuiInputBase-root {
    height: 70px;
    font-size : 22px;
  }
  & label.Mui-focused {
    color: ${colors.primary[500]};
  }
  & .MuiOutlinedInput-root {
    border-radius: 8px;
    &.Mui-focused fieldset {
      border-color: ${colors.primary[500]};
    }
  }`;


  const sxForBoxLi: SxProps<Theme> = {
    width: '100%',
    "&:hover": { backgroundColor: `${colors.primary[500]} ! important`, color: 'white' }
  }



  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return <div className="w-full">
    <Autocomplete
      id="country-select-demo"
      value={value}
      onChange={(event: any, newValue: UserType | null) => {
        setValue(newValue);
      }
      }
      sx={{ width: '100%' }}
      options={Users}
      autoHighlight
      getOptionLabel={(option) => option.Name}
      renderOption={(props, option) => (
        <Box component="li" sx={sxForBoxLi} {...props}>
          {option.Name} + {option.code}
        </Box>
      )}
      renderInput={(params) => (
        <EgTextField
          {...params}
          sx={{ width: '100%' }}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  </div>
}

interface ISwitchProps {
  title: string
}

export function EGSwitch({ title }: ISwitchProps) {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [checked, setChecked] = React.useState(true);

  const switchSxStyle: SxProps<Theme> | undefined = {
    "& .Mui-checked": {
      color: `${colors.primary[500]} !important`
      // transform: "translateX(25px) !important"
    },
    "& .MuiSwitch-track": {
      backgroundColor: `${colors.blueAccent[800]} !important`
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <Stack direction={"row"} justifyContent='center' alignItems='center' >
        <Typography variant="h4">{title}</Typography>
        <Switch
          sx={switchSxStyle}
          checked={checked}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Stack>
    </>

  );
}

export const convertToBase64 = (url: Blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(url);
  return new Promise((res) => {
    reader.onloadend = () => {
      res(reader.result);
    }
  })
}

export const convertBlobToByteArray = (url : Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.readyState === FileReader.DONE) {
        const arrayBuffer = reader.result as any;
        const byteArray = new Uint8Array(arrayBuffer);
        resolve(byteArray);
      } else {
        reject(new Error('Failed to convert Blob to byte array.'));
      }
    };
    reader.readAsArrayBuffer(url);
  });
};




