import React, { useCallback, useEffect, useMemo } from 'react'
import Typography from '@mui/material/Typography'
import { Avatar, Box, Button, Divider, IconButton, InputAdornment, Menu, MenuItem, Stack, styled, TextField, useTheme } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { tokens } from '../../../theme';
import { Notification, SetupIcon } from '../../../components/Icons/Icons';
import './TopBarLong.css';
import { getNotificationElement, getSetupElement } from '../../../JS/functions';
import { Link, useNavigate } from 'react-router-dom';
import './TopBarLong.css'
import { EgButton } from '../../Shared/Shared';
import { useDispatch, useSelector } from 'react-redux';
import { SetCurrentUserReducer, UsersState } from '../../../redux/slices/UserSlice';
import { HavePermission, PERMISSIONS } from '../../../Globals';
import { AppDispatch } from '../../../redux/store';
import { LogOutThunk } from '../../../redux/slices/CheckConnection';
import { UserModel } from '../../../Service/models/UserModel/UserModel';

function TopBarLong() {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useDispatch<AppDispatch>();
  const UsersData = useSelector(UsersState);

  const WhiteBorderTextField = styled(TextField)`

  & .MuiInputBase-root {
    height: 25px;
    padding: 20px 6px
  }

  & label.Mui-focused {
    color: ${colors.primary[500]};
  }

  & .MuiOutlinedInput-root {
    border-radius: 8px;
    &.Mui-focused fieldset {
      border-color: ${colors.primary[500]};
    }
  }`;

  const TopBarSearchButton = styled(Button)({
    background: `#e7c498`,
    height: '100%',
    color: '#000',
    borderRadius: '8px',
    '&:hover': {
      background: `#000`,
      color: '#e7c498',
    }
  });

  const LogNotification = useCallback(() => getNotificationElement('#e7c498', "#000"), []);
  const SetupDomManipulation = useCallback(() => getSetupElement('#e7c498', "#000"), []);
  const navigate = useNavigate();

  useEffect(() => {

    
    


    const fruits: Map<string, number> = new Map([
      ["apples", 500],
      ["bananas", 300],
      ["oranges", 200]
    ]);

    for (const x of fruits.keys()) {
      
    }


    if (UsersData.currentUser.PERMISSIONS) {

      let isOk = Object.entries(UsersData.currentUser.PERMISSIONS).filter(perm => perm[0] === 'ADD_CITIES')[0][1];
    }



    LogNotification();
    SetupDomManipulation();
    return () => {
    }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {

    setAnchorEl(null);

  };

  const SIGNOUT = () => {
    dispatch(LogOutThunk()).then(res => {

      dispatch(SetCurrentUserReducer({} as UserModel))
    });
  }


  return (
    <Box height='100%' display='flex' justifyContent='center'>
      <Stack width='95%' height='100%' direction='row' justifyContent='space-between' alignItems='center'>
        <Box width='30%'>
          <Stack direction='row' justifyContent='space-between'>
            <WhiteBorderTextField fullWidth={true} id="outlined-basic" variant="outlined" placeholder='بحث' InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box marginLeft={2}>
                    <SearchIcon />
                  </Box>
                </InputAdornment>
              ),
            }} />
            <Box marginRight={3} width='58px' >
              <TopBarSearchButton >
                <Typography fontWeight='bold' variant="h6">بحث</Typography>
              </TopBarSearchButton>
            </Box>
          </Stack>
        </Box>
        <Box>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <div className='w-16' id='Setup'>
              {UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.SYS_ADMIN, UsersData.currentUser.PERMISSIONS) && (
                <Link to='/dashboard/Setup'>
                  <div className='w-10 h-10 p-2 rounded-md secondaryBG hover:primaryBG'>
                    <SetupIcon color={"#000"} />
                  </div>
                </Link>
              )}
            </div>
            <div className='w-16' id='NotifiContainer'>
              <div className='w-10 h-10 p-2 rounded-md secondaryBG hover:primaryBG hover:cursor-pointer'>
                <Notification  color={"#000"} />
              </div>
            </div>
            {UsersData.currentUser && (

              <Box marginRight={2} width='60px'>
                <IconButton
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <Avatar src={UsersData.currentUser.PROFILE_PIC_URL} />
                </IconButton>
              </Box>
            )}
          </Stack>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => {
            handleClose();
            navigate('/')
          }}> الصفحه الرئيسيه </MenuItem>
          <MenuItem onClick={() => {
            handleClose();
            navigate('/dashboard/EditUser')
          }}>الملف الشخصي</MenuItem>
          <Divider light />
          <MenuItem onClick={SIGNOUT}> تسجيل الخروج </MenuItem>
        </Menu>
      </Stack>
    </Box>

  )
}

export default TopBarLong