import React, { useEffect } from 'react'
import style from './Button.module.css'
import Spinner from '../Spinner'
import { Checked, ErrorIcon } from '../Icons/Icons'

export type animationType = "IDLE" | "LOADING" | "SUCCESS" | "ERROR" | "DISABLED"
export type ButtType = "Solid" | "Outline" | "Ghost"

type ButtonAnimatedProps = {
    children: JSX.Element,
    onClick: () => void,
    variant?: ButtType,
    animate?: animationType
}


const ButtonAnimated = (Props: ButtonAnimatedProps) => {
    const { children, onClick, animate = 'IDLE', variant = 'Solid' } = Props

    const buttonRef = React.useRef<HTMLDivElement>(null)

    // state to store animate value 
    const [animateV, setAnimate] = React.useState<animationType>('IDLE')


    const handleCLick = () => {
        setAnimate('LOADING')
        onClick()
        if (buttonRef.current) {
            if (animateV === 'IDLE') {
                let currentElementWidth = buttonRef.current.getBoundingClientRect().width
                buttonRef.current.style.width = `${currentElementWidth + 50}px`
            }

            buttonRef.current.style.cursor = 'wait'

            // prevent button from being clicked
            buttonRef.current.onclick = () => {
                return false
            }
        }

    }

    useEffect(() => {
        setAnimate(animate)
        if (animate === 'DISABLED') {
            if (buttonRef.current) {
                buttonRef.current.onclick = () => {
                    return false
                }

                // make buttonRef hover cursor wait
                buttonRef.current.style.cursor = 'not-allowed'

            }
        }

        if (animate === 'SUCCESS') {
            if (buttonRef.current) {
                let currentButton = buttonRef.current

                // change cursor to pointer
                currentButton.style.cursor = 'pointer'

                // change background to green 
                currentButton.style.backgroundColor = '#f3faf7'

                // change text color to green
                currentButton.style.color = ' #057a55'

                // remove onclick event
                // currentButton.onclick = handleCLick
            }
        }

        if (animate === 'ERROR') {
            if (buttonRef.current) {
                let currentButton = buttonRef.current

                // change cursor to pointer
                currentButton.style.cursor = 'pointer'

                // change background to green 
                currentButton.style.backgroundColor = '#fef6f6'

                // change text color to green
                currentButton.style.color = '#e02424'

                // remove onclick event
                // currentButton.onclick = handleCLick
            }
        }

        if(animateV === "LOADING" || animate === "LOADING"){
            if (buttonRef.current) {
                let currentButton = buttonRef.current

                // change cursor to pointer
                currentButton.style.cursor = 'wait'

                // change background to green 
                currentButton.style.backgroundColor = '#ebf5ff'

                // change text color to green
                currentButton.style.color = '#1c64f2'

                // remove onclick event
                // currentButton.onclick = handleCLick
            }
        }
        


    }, [animate,animateV])

    const svgChecked = () => (
        <div className={`w-8 h-8`}>
            <Checked />
        </div>
    )
    const svgCancelled = () => (
        <div className={`w-8 h-8 `}>
            <ErrorIcon />
        </div>
    )

    return (
        <div ref={buttonRef} onClick={handleCLick} className={`relative p-3 hover:cursor-pointer transition-all duration-500 w-fit h-16 items-center flex justify-between text-blue-600 bg-blue-50 rounded-lg shadow-md overflow-hidden`}>
            {children}
            {animateV === 'LOADING' && (
                <div className={`absolute top-1/2 left-3 transform -translate-y-1/2 transition-all  duration-[1000] ${style.fadeInAnimate}`}>
                    <Spinner color='primary' size='medium' />
                </div>
            )}
            {animateV === 'SUCCESS' && (
                <div className={`absolute top-1/2 left-3 transform -translate-y-1/2 transition-all duration-500 ${style.fadeInAnimate}`}>
                    {svgChecked()}
                </div>
            )}
            {animateV === 'ERROR' && (
                <div className={`absolute top-1/2 left-3 transform -translate-y-1/2 transition-all duration-500 ${style.fadeInAnimate}`}>
                    {svgCancelled()}
                </div>
            )}
        </div>
    )
}




export default ButtonAnimated