import { useEffect, useState } from 'react';
import DevExtremTable from '../../../../components/shared/EGDExtremeTable';
import {  RequestIcon } from '../../../../components/Icons/Icons';
import { GetAllFinishingRequest } from '../../../../Service/Apis';
import { useNavigate } from 'react-router-dom';
import { GetErrorAxios } from '../../../../Globals';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
import { FINISHING_REQUEST } from '../../../../Service/models/Finishing';

const FinishingApps = () => {
    const [FinishState, setFinishState] = useState<FINISHING_REQUEST[]>([]);
    
    const nav = useNavigate();
    const dispatch = useDispatch<AppDispatch>()
    const handleClick = (id : any, FinishReq : FINISHING_REQUEST) => {
        nav(`/dashboard/Request?id=${id}`,{state  : FinishReq as FINISHING_REQUEST});
    };
 
    useEffect(() => {
        GetAllFinishingRequest()
            .then((res) => {
                setFinishState(res);
            })
            .catch((err) => {
                GetErrorAxios(err, dispatch)
            });
    }, []);
    return (

        <div>
            <DevExtremTable
                ID="ID"
                tableData={[
                    { Caption: "رقم الطلب", Type: 'number', Value: 'ID' },
                    {Caption:"إسم العميل",Type:'string',Value:'CUSTOMER_NAME'},
                    { Caption: "العنوان", Type: 'string', Value: 'UNIT_ADDRESS' },
                ]}
                dataSource={FinishState}
                Actions={(data) => {
                    let currentData = data.data as FINISHING_REQUEST;
                    return (
                        <div className="flex items-center gap-4 hover:cursor-pointer p-1">
                            <div className='flex flex-col items-center hover:text-[#e7c498]'>
                                <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1 hover:cursor-pointer' onClick={() => handleClick(currentData.ID,currentData!)}>
                                    <RequestIcon />
                                </div>
                                <p className='text-sm font-bold'>الطلب</p>
                            </div>
                            {/* <div className='flex flex-col items-center hover:text-[#e7c498]'>
                                <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1 hover:cursor-pointer' onClick={() => {
                                }}>
                                    <EditLogo />
                                </div>
                                <p className='text-sm font-bold'>إجراءات</p>
                            </div> */}
                        </div>
                    )
                }}
            />
        </div>
    );
}

export default FinishingApps;
