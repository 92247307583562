import React, { FC, useEffect, useState } from 'react'
import { THETEAM } from '../../components/Icons/Icons'
import { useTheme } from '@mui/material'
import { tokens } from '../../theme'
import { CustomTextFieldFullHeight } from '../../CustomComps/Customs'
import { JOB_EXPERIENCE_MODEL, JOINUS_MODEL } from '../../Service/models/JOINUS_MODEL'
import './JOIN.css'
import { Attendance, DropDownData, DropTextField } from '../DashBoard/Actions/ActionsTypesScreen/AppointMents'
import { EgButton } from '../Shared/Shared'
import { GetErrorAxios, Input_Error_State, getYearMonthDayFromDate, ConverCurrentDateToString } from '../../Globals'
import { GET_ALL_STATIONS, send_join_us_request } from '../../Service/Apis'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import { AddAction } from '../../redux/slices/ErrorsSlice'
import { useNavigate } from 'react-router-dom'

const JoinUs = () => {
  return (
    <div className='w-full min-h-[100vh] bg-[#f5f5f5] flex items-center justify-center pb-5 JOINUS_BG'>
      <div className='relative w-[80%] bg-gray-100 rounded-lg shadow-md flex flex-col items-center mt-20 p-4 bg-opacity-90 backdrop-blur'>
        <div className='z-50 w-[100px] h-[100px] flex flex-col bg-white shadow-md rounded-full justify-center items-center absolute top-[-50px] left-[50%] -translate-x-2/4'>
          <div className='w-1/2'>
            <THETEAM />
          </div>
          {/* <h2 className='text-5xl font-bold'>THE TEAM</h2>
          <h2>للخدمات العقاريه</h2> */}
        </div>
        <div className='flex-grow w-full mt-[50px] mb-2'>
          <JoinUsForm />
        </div>
      </div>
    </div>
  )
}

export default JoinUs

const JoinUsForm: FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MainInputStyle = { marginBottom: 2, width: '100%', backgroundColor: 'white' };
  const [inputsValidator, setInputsValidators] = useState<{ Faculty_from: Input_Error_State, Faculty_to: Input_Error_State, University: Input_Error_State, Faculty: Input_Error_State, Name: Input_Error_State, Age: Input_Error_State, Address: Input_Error_State, Mobile: Input_Error_State, Email: Input_Error_State }>({ Faculty: Input_Error_State.Initial, Faculty_from: Input_Error_State.Initial, Faculty_to: Input_Error_State.Initial, University: Input_Error_State.Initial, Address: Input_Error_State.Initial, Age: Input_Error_State.Initial, Email: Input_Error_State.Initial, Mobile: Input_Error_State.Initial, Name: Input_Error_State.Initial })
  const [Req, setReq] = useState<JOINUS_MODEL>({ WORK_AS_MARKETING: false, EDUCATION_LEVEL: 0, GENDER: 0 } as JOINUS_MODEL);
  const [Exps, setExps] = useState<JOB_EXPERIENCE_MODEL[]>([]);
  const [branchesDropDown, setBranchesDropDown] = useState<DropDownData[]>([]);

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate();

  useEffect(() => {
    GET_ALL_STATIONS().then(res => {
      setBranchesDropDown(res.map(station => ({ id: station.STATION_ID, desc: station.STATION_NAME })))
    }).catch(err => {
      GetErrorAxios(err, dispatch)
    })
  }, [])



  const validateInputs = (): boolean => {
    if (Req.FULL_NAME === undefined || Req.FULL_NAME.length < 1) {
      dispatch(AddAction({ message: "برجاء ادخال الإسم", status: "error" }))
      return false
    }

    if (Req.ADDRESS === undefined || Req.ADDRESS.length < 1) {
      dispatch(AddAction({ message: "برجاء ادخال العنوان", status: "error" }))
      return false
    }

    if (Req.AGE === undefined || typeof Req.AGE !== 'number' || Req.AGE < 10 || Req.AGE > 100) {
      dispatch(AddAction({ message: "السن يجب ان يكون بين 10 و 100 سنه ورقم", status: "error" }))
      return false
    }

    if (Req.MOBILE_NO === undefined || Req.MOBILE_NO.length !== 11) {
      dispatch(AddAction({ message: "برجاء إدخال رقم محمول صحيح", status: "error" }))
      return false
    }

    if (Req.EMAIL === undefined || !Req.EMAIL.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
      dispatch(AddAction({ message: "برجاء إدخال نمط بريد", status: "error" }))
      return false
    }

    if (Req.STATION_ID === undefined|| Req.STATION_ID === null) {
      dispatch(AddAction({ message: "برجاء إختيار الفرع", status: "error" }))
      return false
    }

    if (Req.UNIVERSTY === undefined || Req.UNIVERSTY.length < 3) {
      dispatch(AddAction({ message: "برجاء إدخال الجامعه", status: "error" }))
      return false
    }

    if (Req.FACULTY === undefined || Req.FACULTY.length < 3) {
      dispatch(AddAction({ message: "برجاء إدخال الكليه", status: "error" }))
      return false
    }


    if (Req.TIME_FROM === undefined || getYearMonthDayFromDate(Req.TIME_FROM) === '1910-02-01' || getYearMonthDayFromDate(Req.TIME_FROM) === getYearMonthDayFromDate(new Date())) {
      dispatch(AddAction({ message: " تاريخ بدايه الجامعه يجب ان لا يوافق اليوم او عدم ترك الخانه فارغه", status: "error" }))
      return false
    }
    if (Req.TIME_TO === undefined || getYearMonthDayFromDate(Req.TIME_TO) === '1910-02-01' || getYearMonthDayFromDate(Req.TIME_TO) === getYearMonthDayFromDate(new Date())) {
      dispatch(AddAction({ message: " تاريخ نهايه الجامعه يجب ان لا يوافق اليوم او عدم ترك الخانه فارغه", status: "error" }))
      return false
    }



    let isExperienceOk: boolean = true;

    for (var i = 0; i < Exps.length; i++) {
      let experience = Exps[i];

      if (experience.COMPANY_NAME.length < 3) {
        dispatch(AddAction({ message: `اقل عدد حروف 3  - في الشركه ${experience.COMPANY_NAME}`, status: "error" }))
        isExperienceOk = false
        break;
      }

      if (experience.POSTION.length < 1) {
        dispatch(AddAction({ message: `الوظيفه لايمكن ان تكون فارغه في الشركه ${experience.COMPANY_NAME}`, status: "error" }))
        isExperienceOk = false
        break;
      }

      if (experience.TIME_FROM === undefined || getYearMonthDayFromDate(experience.TIME_FROM) === '1910-02-01' || getYearMonthDayFromDate(experience.TIME_FROM) === getYearMonthDayFromDate(new Date())) {
        dispatch(AddAction({ message: `تاريخ البدايه لايمكن ان يكون فارغ او يساوي اليوم في الشركه ${experience.COMPANY_NAME}`, status: "error" }))
        isExperienceOk = false
        break;
      }

      if (experience.TIME_TO === undefined || getYearMonthDayFromDate(experience.TIME_TO) === '1910-02-01' || getYearMonthDayFromDate(experience.TIME_TO) === getYearMonthDayFromDate(new Date())) {
        dispatch(AddAction({ message: `تاريخ النهايه لايمكن ان يكون فارغ او يساوي اليوم في الشركه ${experience.COMPANY_NAME}`, status: "error" }))
        isExperienceOk = false
        break;
      }
    }


    return isExperienceOk;
  }


  const sendReqToServer = () => {

    if (validateInputs()) {
      let ReqOBJ: JOINUS_MODEL = { ...Req, JOB_EXPERIENCE: Exps };
      send_join_us_request(ReqOBJ).then(res => {
        dispatch(AddAction({ message: "تم إرسال الـسيره الذاتيه", status: "success" }))
        navigate('/')
      }).catch(err => {
        GetErrorAxios(err, dispatch)
      })
    }
  }


  return <div className='w-full flex flex-col'>
    <div>
      <div className='flex flex-wrap md:justify-between'>
        <div className='w-full md:w-[30%] flex flex-col gap-1 my-2'>
          <label htmlFor="FullName">الإسم</label>
          <div>
            <CustomTextFieldFullHeight height={25} value={Req.FULL_NAME} id='FullName' type={''} onChange={(e: any) => {
              let val = e.target.value as string;
              setReq(prev => ({ ...prev, FULL_NAME: val }))
              if (val.length > 0) {
                setInputsValidators(prev => ({ ...prev, Name: Input_Error_State.Success }))
              } else {
                setInputsValidators(prev => ({ ...prev, Name: Input_Error_State.Fail }))
              }
            }} style={MainInputStyle}
              colors={colors} theme={theme} />
            {inputsValidator.Name === Input_Error_State.Fail && (
              <h6 className='font-bold text-red-600' >* هذا الحقل إجباري</h6>
            )}
            {(inputsValidator.Name === Input_Error_State.Success || inputsValidator.Name === Input_Error_State.Initial) && (
              <h6 className='font-bold text-green-600'>&nbsp;</h6>
            )}
          </div>
        </div>
        <div className='w-full md:w-[30%] flex flex-col gap-1 my-2'>
          <label htmlFor="Age">السن</label>
          <div>
            <CustomTextFieldFullHeight height={25} value={isNaN(Req.AGE) ? 0 : Req.AGE} id='Age' type={''} onChange={(e: any) => {
              let val = e.target.value;
              if (!isNaN(val)) {
                setReq(prev => ({ ...prev, AGE: val.length > 0 ? parseInt(val) : 0 }))
              } else {
                ///dispatch(AddAction({ message: "برجاء كتابه ارقام", status: "error" }))
              }
              if (val.length > 0) {
                setInputsValidators(prev => ({ ...prev, Age: Input_Error_State.Success }))
              } else {
                setInputsValidators(prev => ({ ...prev, Age: Input_Error_State.Fail }))
              }
            }} style={MainInputStyle}
              colors={colors} theme={theme} />
            {inputsValidator.Age === Input_Error_State.Fail && (
              <h6 className='font-bold text-red-600' >* هذا الحقل إجباري</h6>
            )}
            {(inputsValidator.Age === Input_Error_State.Success || inputsValidator.Age === Input_Error_State.Initial) && (
              <h6 className='font-bold text-green-600'>&nbsp;</h6>
            )}
          </div>
        </div>

        <div className='w-full md:w-[30%] flex flex-col gap-1 my-2'>
          <label htmlFor="Mobile">رقم الهاتف</label>
          <div>
            <CustomTextFieldFullHeight height={25} value={Req.MOBILE_NO} id='Mobile' type={''} onChange={(e: any) => {
              let val = e.target.value as string;

              setReq(prev => ({ ...prev, MOBILE_NO: val }))
              if (val.length > 0) {
                setInputsValidators(prev => ({ ...prev, Mobile: Input_Error_State.Success }))
              } else {
                setInputsValidators(prev => ({ ...prev, Mobile: Input_Error_State.Fail }))
              }
            }} style={MainInputStyle}
              colors={colors} theme={theme} />
            {inputsValidator.Mobile === Input_Error_State.Fail && (
              <h6 className='font-bold text-red-600' >* هذا الحقل إجباري</h6>
            )}
            {(inputsValidator.Mobile === Input_Error_State.Success || inputsValidator.Mobile === Input_Error_State.Initial) && (
              <h6 className='font-bold text-green-600'>&nbsp;</h6>
            )}
          </div>
        </div>
      </div>
      <div className='w-full md:w-1/2 flex flex-col gap-1 my-2'>
        <label htmlFor="Address">العنوان</label>
        <div>
          <CustomTextFieldFullHeight height={25} value={Req.ADDRESS} id='Address' type={''} onChange={(e: any) => {
            let val = e.target.value as string;

            setReq(prev => ({ ...prev, ADDRESS: val }))
            if (val.length > 0) {
              setInputsValidators(prev => ({ ...prev, Address: Input_Error_State.Success }))
            } else {
              setInputsValidators(prev => ({ ...prev, Address: Input_Error_State.Fail }))
            }
          }} style={MainInputStyle}
            colors={colors} theme={theme} />
          {inputsValidator.Address === Input_Error_State.Fail && (
            <h6 className='font-bold text-red-600' >* هذا الحقل إجباري</h6>
          )}
          {(inputsValidator.Address === Input_Error_State.Success || inputsValidator.Address === Input_Error_State.Initial) && (
            <h6 className='font-bold text-green-600'>&nbsp;</h6>
          )}
        </div>
      </div>
      <div className='w-full md:w-1/2 flex flex-col gap-1 my-2'>
        <label htmlFor="Email">البريد الإلكتروني</label>
        <div>
          <CustomTextFieldFullHeight height={25} value={Req.EMAIL} id='Email' type={''} onChange={(e: any) => {
            let val = e.target.value as string;

            setReq(prev => ({ ...prev, EMAIL: val.trim() }))
            if (val.length > 0) {
              setInputsValidators(prev => ({ ...prev, Email: Input_Error_State.Success }))
            } else {
              setInputsValidators(prev => ({ ...prev, Email: Input_Error_State.Fail }))
            }
          }} style={MainInputStyle}
            colors={colors} theme={theme} />
          {inputsValidator.Email === Input_Error_State.Fail && (
            <h6 className='font-bold text-red-600' >* هذا الحقل إجباري</h6>
          )}
          {(inputsValidator.Email === Input_Error_State.Success || inputsValidator.Email === Input_Error_State.Initial) && (
            <h6 className='font-bold text-green-600'>&nbsp;</h6>
          )}
        </div>
      </div>
      <div className='w-1/2 md:w-1/3 my-5'>
        <div className="flex w-full justify-between items-center gap-2">
          <label className='text-base'>فرع الشركه</label>
          <DropTextField initialText='الفرع' data={[
            ...branchesDropDown
          ]} onchange={e => {
            setReq(prev => ({ ...prev, STATION_ID: e.target.value as number }))
          }} />
        </div>
      </div>
      <div className='w-1/2 md:w-1/3 my-5'>
        <div className="flex w-full justify-between items-center gap-2">
          <label className='text-base'>النوع</label>
          <DropTextField initalValue={0} data={[
            { id: 0, desc: "ذكر" },
            { id: 1, desc: "أنثى" },
          ]} onchange={e => {
            setReq(prev => ({ ...prev, GENDER: e.target.value as number }))
          }} />
        </div>
      </div>
      <div className='w-1/2 md:w-1/3 my-5'>
        <div className="flex w-full justify-between items-center gap-2">
          <label className='text-base'>المؤهل الدراسي</label>
          <DropTextField initalValue={0} data={[
            // { id: 0, desc: "دبلوم" },
            // { id: 1, desc: "ثانوي عام" },
            { id: 0, desc: "بكالريوس" },
            { id: 1, desc: "ماجستير" },
            { id: 2, desc: "دكتوراه" },
          ]} onchange={e => {
            setReq(prev => ({ ...prev, EDUCATION_LEVEL: e.target.value as number }))
          }} />

        </div>
      </div>
      <div className='flex gap-3 mr-2 my-4'>
        <input type="checkbox" checked={Req.WORK_AS_MARKETING} id="PrevExp" name="explain" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {
          if (e.target.checked === true) {
            setReq(prev => ({ ...prev, WORK_AS_MARKETING: e.target.checked }))
          }
          if (e.target.checked === false) {
            setReq(prev => ({ ...prev, WORK_AS_MARKETING: e.target.checked }))
          }
        }} />
        <label htmlFor='PrevExp' className='text-base'>سبق العمل كمسوق عقاري </label>
      </div>
      <div className='w-full flex-wrap flex items-start justify-between '>
        <div className='w-full md:w-1/3 flex flex-col gap-1 '>
          <label htmlFor="Email">جامعه</label>
          <div>
            <CustomTextFieldFullHeight height={25} value={Req.UNIVERSTY} id='University' type={''} onChange={(e: any) => {
              let val = e.target.value as string;
              setReq(prev => ({ ...prev, UNIVERSTY: val }))
              if (val.length > 0) {
                setInputsValidators(prev => ({ ...prev, University: Input_Error_State.Success }))
              } else {
                setInputsValidators(prev => ({ ...prev, University: Input_Error_State.Fail }))
              }
            }} style={MainInputStyle}
              colors={colors} theme={theme} />
            {inputsValidator.University === Input_Error_State.Fail && (
              <h6 className='font-bold text-red-600' >* هذا الحقل إجباري</h6>
            )}
            {(inputsValidator.University === Input_Error_State.Success || inputsValidator.University === Input_Error_State.Initial) && (
              <h6 className='font-bold text-green-600'>&nbsp;</h6>
            )}
          </div>
        </div>
        <div className='w-full md:w-1/3 flex flex-col gap-1 '>
          <label htmlFor="Faculty">كليه</label>
          <div>
            <CustomTextFieldFullHeight height={25} value={Req.FACULTY} id='Faculty' type={''} onChange={(e: any) => {
              let val = e.target.value as string;

              setReq(prev => ({ ...prev, FACULTY: val }))
              if (val.length > 0) {
                setInputsValidators(prev => ({ ...prev, Faculty: Input_Error_State.Success }))
              } else {
                setInputsValidators(prev => ({ ...prev, Faculty: Input_Error_State.Fail }))
              }
            }} style={MainInputStyle}
              colors={colors} theme={theme} />
            {inputsValidator.Faculty === Input_Error_State.Fail && (
              <h6 className='font-bold text-red-600' >* هذا الحقل إجباري</h6>
            )}
            {(inputsValidator.Faculty === Input_Error_State.Success || inputsValidator.Faculty === Input_Error_State.Initial) && (
              <h6 className='font-bold text-green-600'>&nbsp;</h6>
            )}
          </div>
        </div>
        <div>
          <Attendance title="من" isDate isTime initialDate={new Date(1910, 1, 1)} data={(clientAttendance) => {
            setReq(prev => ({ ...prev, TIME_FROM: clientAttendance }))

            // if (getYearMonthDayFromDate(clientAttendance) !== getYearMonthDayFromDate(new Date())) {

            //   setInputsValidators(prev => ({ ...prev, Faculty_from: Input_Error_State.Success }))
            // }else{
            //   setInputsValidators(prev => ({ ...prev, Faculty_from: Input_Error_State.Fail }))
            // }
          }} />

          {/* {inputsValidator.Faculty_from === Input_Error_State.Fail && (
            <h6 className='font-bold text-red-600' >* هذا الحقل إجباري</h6>
          )}
          {(inputsValidator.Faculty_from === Input_Error_State.Success || inputsValidator.Faculty === Input_Error_State.Initial) && (
            <h6 className='font-bold text-green-600'>&nbsp;</h6>
          )} */}
        </div>
        <div className='flex flex-col'>
          <Attendance title="الى" isDate isTime initialDate={new Date(1910, 1, 1)} data={(clientAttendance) => {
            setReq(prev => ({ ...prev, TIME_TO: clientAttendance }))
          }} />
        </div>
      </div>
      <div className='my-5'>
        <div className="h-[5em]">
          <h1 className='font-bold'>لزياده نسبه قبولك ضيف خبراتك</h1>
          <div className='w-40 h-10'>
            <EgButton HandleClick={() => {
              let MaxIdNumber: number = 0;
              Exps.map(exp => {
                if (exp.CAREER_ID ?? 0 >= MaxIdNumber) {
                  MaxIdNumber = exp.CAREER_ID ?? 0
                }
              })
              setExps(prev => [...prev, { COMPANY_NAME: "", POSTION: "", TIME_FROM: new Date(), TIME_TO: new Date(), CAREER_ID: MaxIdNumber + 1 }])
            }}>
              <div className='flex justify-around items-center w-full'>
                <h5 className='font-bold'>اضف خبراتك</h5>
                <div className='i-ion-plus font-bold text-xl'>
                </div>
              </div>
            </EgButton>
          </div>
        </div>
      </div>
      {Exps.length > 0 && (
        <div className='p-2 rounded-lg shadow bg-white'>
          {Exps.map((exp, idx) => {
            return <div key={exp.CAREER_ID}>
              <Experience key={exp.CAREER_ID} idx={idx} ExperienceObj={exp} exp_remove={(id) => {
                let exps_modified = Exps.filter(exp => exp.CAREER_ID !== id);
                setExps(exps_modified)
              }}
                return_expoerience={(data) => {
                  //Remove Element 
                  let exps_modified = Exps.filter(exp => exp.CAREER_ID !== data.CAREER_ID);
                  //Add New Object To Array
                  exps_modified.push(data);
                  //Sort Array 
                  let Soreted_Arr = exps_modified.sort((a, b) => a.CAREER_ID! - b.CAREER_ID!)
                  //Set New Array 
                  setExps(Soreted_Arr);
                }}
              />
            </div>
          })}
        </div>
      )}
    </div>
    <div className='w-40 h-10 self-end mt-5'>
      <EgButton HandleClick={() => {
        sendReqToServer()
      }}>
        <div className='flex justify-around items-center w-full'>
          <h3 className='font-bold'>إنضم الينا</h3>
          <div className='i-material-symbols-keyboard-double-arrow-left-rounded font-bold text-2xl'>
          </div>
        </div>
      </EgButton>
    </div>
  </div>
}

const Experience = ({ idx, exp_remove, ExperienceObj, return_expoerience }: { return_expoerience: (obj: JOB_EXPERIENCE_MODEL) => void, ExperienceObj: JOB_EXPERIENCE_MODEL, idx: number, exp_remove: (id: number) => void }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MainInputStyle = { marginBottom: 2, width: '100%', backgroundColor: 'white' };
  const [inputsValidator, setInputsValidators] = useState<{ CompanyName: Input_Error_State, CompanyPosition: Input_Error_State, CompanyTimeFrom: Input_Error_State, CompanyTimeTo: Input_Error_State }>({ CompanyName: Input_Error_State.Initial, CompanyPosition: Input_Error_State.Initial, CompanyTimeFrom: Input_Error_State.Initial, CompanyTimeTo: Input_Error_State.Initial })

  return <div key={ExperienceObj.CAREER_ID} className='w-full'>
    <div className={idx % 2 === 0 ? 'relative w-full flex-wrap gap-2 flex p-2 rounded items-start justify-between bg-gray-50' : 'relative w-full flex-wrap gap-2 flex p-2 rounded items-start justify-between bg-gray-200'}>
      <div className='w-full flex md:w-1/3 flex-col gap-1 '>
        <label htmlFor="Company">الشركه</label>
        <div>
          <CustomTextFieldFullHeight height={25} id='Company' type={''} onChange={(e: any) => {
            let val = e.target.value as string;
            return_expoerience({ ...ExperienceObj, COMPANY_NAME: val })
            if (val.length >= 3) {
              setInputsValidators(prev => ({ ...prev, CompanyName: Input_Error_State.Success }))
            } else {
              setInputsValidators(prev => ({ ...prev, CompanyName: Input_Error_State.Fail }))
            }
          }} style={MainInputStyle}
            colors={colors} theme={theme} />
          {inputsValidator.CompanyName === Input_Error_State.Fail && (
            <h6 className='font-bold text-red-600' >* هذا الحقل إجباري</h6>
          )}
          {(inputsValidator.CompanyName === Input_Error_State.Success || inputsValidator.CompanyName === Input_Error_State.Initial) && (
            <h6 className='font-bold text-green-600'>&nbsp;</h6>
          )}
        </div>
      </div>
      <div className='w-full md:w-1/3 flex flex-col gap-1 '>
        <label htmlFor="JOB">الوظيفه</label>
        <div>
          <CustomTextFieldFullHeight height={25} id='JOB' type={''} onChange={(e: any) => {
            let val = e.target.value as string;
            return_expoerience({ ...ExperienceObj, POSTION: val })
            if (val.length >= 3) {
              setInputsValidators(prev => ({ ...prev, CompanyPosition: Input_Error_State.Success }))
            } else {
              setInputsValidators(prev => ({ ...prev, CompanyPosition: Input_Error_State.Fail }))
            }
          }} style={MainInputStyle}
            colors={colors} theme={theme} />
          {inputsValidator.CompanyPosition === Input_Error_State.Fail && (
            <h6 className='font-bold text-red-600' >* هذا الحقل إجباري</h6>
          )}
          {(inputsValidator.CompanyPosition === Input_Error_State.Success || inputsValidator.CompanyPosition === Input_Error_State.Initial) && (
            <h6 className='font-bold text-green-600'>&nbsp;</h6>
          )}
        </div>
      </div>
      <Attendance title="من" isDate isTime initialDate={new Date(1910, 1, 1)} data={(clientAttendance) => {
        return_expoerience({ ...ExperienceObj, TIME_FROM: clientAttendance })
      }} />
      <Attendance title="الى" isDate isTime initialDate={new Date(1910, 1, 1)} data={(clientAttendance) => {
        return_expoerience({ ...ExperienceObj, TIME_TO: clientAttendance })
      }} />
      <div className='absolute top-1 left-1 i-material-symbols-close-rounded text-xl text-red-400 font-bold hover:cursor-pointer' onClick={() => exp_remove(ExperienceObj.CAREER_ID!)}>

      </div>

    </div>
  </div>
}