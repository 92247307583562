import React, { useEffect, useState } from 'react'
import { TRANSACTIONS } from '../../Service/models/TransActions_model'
import { BoxName } from '../../Globals'

const TransDetails = ({ Trans }: { Trans: TRANSACTIONS }) => {

    const [trans, setTrans] = useState<TRANSACTIONS>({} as TRANSACTIONS)

    useEffect(() => {
        setTrans(Trans)
    }, [Trans])

    return (
        <div>
            {trans.TRANSACTION_DETALS?.map((itm, idx) => {
                return <>
                    <div className='flex gap-10 w-full mb-5'>
                        <div className='w-1/5' >
                            <h6>الرقم المرجعي للمعامله</h6>
                            <h5>{itm.TRANSACTION_ID}</h5>
                        </div>
                        <div className='w-1/5'>
                            <h6>الصندوق</h6>
                            <h5>{BoxName(itm.DESTINATION_ID)}</h5>
                        </div>
                        <div className='w-1/5'>
                            <h6>القيمه</h6>
                            <h5>{itm.AMOUNT}</h5>
                        </div>
                        <div className='w-1/5'>
                            <h6>الرقم المرجعي للصفقه</h6>
                            <h5>{itm.DEAL_ACTION_ID}</h5>
                        </div>
                        <div className='w-1/5'>
                            <h6>الملاحظات</h6>
                            <h5>{trans.NOTES}</h5>
                        </div>
                    </div>
                    <hr className='w-full h-1' />
                </>
            })}
        </div>
    )
}

export default TransDetails