import { Stack, Typography, useTheme } from "@mui/material";
import Modal from "@mui/material/Modal";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { SubmitHandler } from "react-hook-form/dist/types/form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomTextField } from "../../../CustomComps/Customs";
import { GetErrorAxios, ResponseStatus } from "../../../Globals";
import { GetAllUserDetail } from "../../../Service/Apis";
import { OwnersModel } from "../../../Service/models/OwnersModel/Owners";
import { USER_Detail } from "../../../Service/models/UserModel/UserModel";
import { EditLogo, THETEAM } from "../../../components/Icons/Icons";
import DevExtremTable from "../../../components/shared/EGDExtremeTable";
import { AddAction } from "../../../redux/slices/ErrorsSlice";
import { GetAllOwnersThunk, OwnerState, SaveOwnersThunk } from "../../../redux/slices/OwnersSlice";
import { AppDispatch } from "../../../redux/store";
import { tokens } from "../../../theme";
import { EgButton } from "../../Shared/Shared";
import { DropDownData, DropTextField } from "../Actions/ActionsTypesScreen/AppointMents";

type SearchType = {
  ID: number,
  number: number,
  value: string,
  Modifier: number
}


const Owners = () => {
  const [open, setOpen] = useState(false);
  const [openFeed, setOpenFeed] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpenFeed = () => setOpenFeed(true);
  const handleClose = () => setOpen(false);
  const handleCloseFeed = () => setOpenFeed(false);
  const dispatch = useDispatch<AppDispatch>();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MainInputStyle = { marginBottom: 0, width: '100%', backgroundColor: 'white' };

  const [reload, setReload] = useState<boolean>(false);
  const [outerData, setOuterData] = useState<OwnersModel>({} as OwnersModel);
  const [OwnersState, setOwnersState] = useState<OwnersModel[]>([]);

  const [searchFactors, setSearchFactors] = useState<number[]>([0]);
  const [searchBarCount, setsearchBarCount] = useState<SearchType[]>([{ ID: 1, number: 1, value: "", Modifier: 0 }]);

  const OwnersRef = useRef<OwnersModel[]>([]);
  const OwnersHolder = useRef<OwnersModel[]>([]);
  const AllOwners = useSelector(OwnerState);


  useEffect(() => {
    dispatch(GetAllOwnersThunk()).then(res => {
      OwnersRef.current = res.payload as OwnersModel[]
      OwnersHolder.current = res.payload as OwnersModel[]
    });


  }, [reload])

  useEffect(() => {
    setOwnersState(AllOwners.AllOwners);


  }, [AllOwners.AllOwners])

  return (
    <div>
      <div className='flex gap-4 items-center'>
        <div className='i-material-symbols-add-box-outline-rounded text-4xl text-gray-500 hover:cursor-pointer my-3' onClick={() => {
          setOuterData({} as OwnersModel)
          handleOpen()
        }}>
        </div>
        <h2 className='text-xl font-bold'>إضافه مالك</h2>
      </div>
      <DevExtremTable ID="USER_ID" tableData={[
        { Caption: "الإسم", Type: 'string', Value: 'OWNER_NAME' },
        { Caption: "نسبة المشاركة", Type: 'number', Value: 'SHARED_PREC' },
      ]}
        dataSource={OwnersState}
        Actions={(data) => {
          let currentOwner = data.data as OwnersModel;
          return (
            <div className=" flex items-center gap-4 hover:cursor-pointer  p-1">
              <div className='flex flex-col items-center hover:text-[#e7c498]'>
                <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1 hover:cursor-pointer' onClick={() => {
                  //modelFN(row);
                  handleOpen();
                  setOuterData(currentOwner);

                }}>
                  <EditLogo />
                </div>
                <p className='text-sm font-bold'>تعديل</p>
              </div>
            </div>
          )
        }}
      />
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalBody reload={() => {
            //setReload((old) => (!old));
            handleClose();
          }
          } outerData={outerData} />
        </Modal>
      </div>
    </div >
  )
}

export default Owners


const ModalBody = ({ reload, outerData }: { outerData?: OwnersModel, reload: () => void }) => {


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch<AppDispatch>();
  const [userIDDropDown, setUserIDDropDown] = useState<DropDownData[]>([]);
  const [Branches, setUserID] = useState<USER_Detail[]>([]);
  const [selectedUserID, setselectedUserID] = useState<number>(-1);

  const MainInputStyle = { marginBottom: 20, width: '100%' };
  const navigate = useNavigate();



  const { register, setError, control, handleSubmit, reset, watch, formState: { errors } } = useForm<OwnersModel>({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: { USER_ID: outerData?.USER_ID ?? -1, SHARED_PREC: outerData?.SHARED_PREC ?? 0 }
  });

  // const { ref: UsersRef, ...NameProps } = register("USER_ID", {
  //   required: "برجاء إختيار المؤسس",
  // });

  const { ref: OwnerCountRef, ...OwnerCountProps } = register("SHARED_PREC", {
    required: "ادخل نسبة الملكية",
    min: 0.01,
    valueAsNumber: true,
  });

  const onSubmit: SubmitHandler<OwnersModel> = async (data: OwnersModel) => {

    if (selectedUserID === -1) {
      dispatch(AddAction({ message: "برجاء اختيار المالك", status: "error" }));
      return;
    }

    data.USER_ID = selectedUserID;


    dispatch(SaveOwnersThunk(data)).then(res => {
      let resp = res.payload as ResponseStatus

      if (resp.Status === 'Success') {
        window.location.reload();
      }
    }).catch(err => {
      let ERR = err as AxiosError


      let ErrMsg = err.response.data.Error as string || err.response.data as string || ERR.message + " At " + ERR.config?.url as string;

      dispatch(AddAction({ message: ErrMsg, status: "error" }))
    });
  };


  useEffect(() => {
    GetAllUserDetail({ WithAdmin: true, WithNewUsers: false }).then(res => {
      setUserID(res);
      setUserIDDropDown(res.map((user, idx) => ({ id: user.ID, desc: user.FULL_NAME })))
    }).catch(err => {
      GetErrorAxios(err, dispatch)
    })

  }, [])

  useEffect(() => {
    if (outerData?.USER_ID) {
      setselectedUserID(outerData.USER_ID);
    }
  }, [outerData?.USER_ID]);


  return <div className="w-[20%] h-[80vh] absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-1/2">
    <div className='h-full w-full flex justify-center items-center'>
      <div className='bg-[#EDF3F4] rounded-3xl w-full h-full flex flex-col items-center'>
        <div className='h-1/3 p-2 mb-10'>
          <THETEAM />
        </div>
        <form className='w-4/5 h-2/3 flex flex-col  justify-start items-center' onSubmit={handleSubmit(onSubmit)}>
          {!outerData?.USER_ID && (
            <div className='w-full flex flex-row gap-4'>
              <label className='text-base'>إسم المالك</label>
              <DropTextField initalValue={(outerData?.USER_ID) ? outerData.USER_ID : -1} data={[
                { id: -1, desc: "إسم المالك" }, ...userIDDropDown
              ]} onchange={e => {
                let val = e.target.value

                setselectedUserID(parseInt(val))
              }} />
            </div>
          )}
          <div className='w-full flex flex-row gap-4'>
            <label className='number'>نسبة الملكية</label>
            <CustomTextField placeholder='برجاء إدخال نسبة الملكية' inputRef={OwnerCountRef} {...OwnerCountProps} type={'number'} inputProps={{
              step: "any",
            }} style={MainInputStyle} error={!!errors.SHARED_PREC}
              helperText={errors?.SHARED_PREC?.message}
              colors={colors} theme={theme} />
          </div>
          <div className='w-full grow flex flex-col justify-around'>
            <div className='w-2/3 self-center'>
              <EgButton Action={'submit'} HandleClick={() => {
                //dispatch(fetchTodos({ password: 'ahmed', userName: 'ahmed' }));
              }}>
                <Stack direction={'row'} justifyContent='center'>
                  {outerData?.USER_ID && (
                    <Typography sx={{ marginLeft: '10px' }} variant="h5">تعديل</Typography>
                  )}
                  {!outerData?.USER_ID && (
                    <Typography sx={{ marginLeft: '10px' }} variant="h5">إضافه</Typography>
                  )}

                </Stack>
              </EgButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
}


