import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { DaysNames, GetErrorAxios, getDateTimeOnly, getYearMonthDayFromDate } from '../../../../../Globals'
import { getAllProjects } from '../../../../../Service/Apis'
import { ACTIONS, APPOINTMENT } from '../../../../../Service/models/UserModel/Actions/ActionsModel'
import { AppDispatch } from '../../../../../redux/store'
import { GovAndCities } from '../../../../Rate/Rate'
import { ProjectModel } from '../../../../../Service/models/projects/ProjectModel'


const DisplayDayTime = (date: Date): string => {
  return `${DaysNames[new Date(date).getDay()]} - ${getYearMonthDayFromDate(date)} - ${getDateTimeOnly(date)}`
}
const DisplayTimeOnly = (date: Date): string => {
  return `${getDateTimeOnly(new Date(date))}`
}



const AppointmentWindow = ({ History }: { History: ACTIONS }) => {


  const [Govs, setGovs] = React.useState<GovAndCities[]>();
  const [firstSuggestionPorject, changeFirstSuggestionPorject] = React.useState<ProjectModel>({} as ProjectModel);
  const [secondSuggestionPorject, changeSecondSuggestionPorject] = React.useState<ProjectModel>({} as ProjectModel);
  const [allProjects, changeAllProjects] = React.useState<ProjectModel[]>([]);


  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {

    getAllProjects().then(res => {
      changeAllProjects(res)
      let selectedFirstProject = res.find(x => x.ID === History.APPOINTMENT?.FIRST_SUGGESTED_PROJECT_ID)
      if (selectedFirstProject) {
        changeFirstSuggestionPorject(selectedFirstProject)
      }
      let selectedSecondProject = res.find(x => x.ID === History.APPOINTMENT?.SECOND_SUGGESTED_PROJECT_ID)
      if (selectedSecondProject) {
        changeSecondSuggestionPorject(selectedSecondProject)
      }
    }).catch(err => {
      GetErrorAxios(err, dispatch)
    });

  }, [])

  let AppointMent: APPOINTMENT = History.APPOINTMENT!
  return (
    <div>
      <div className="w-full flex flex-col">
        <div className="flex flex-wrap gap-4 w-full min-h-full">
          <div className='gap-2  flex flex-col flex-wrap'>
            <h3>موعد الإجتماع الأساسي</h3>
            <h3 className="font-bold text-xl"> {DisplayDayTime(AppointMent.APPOINTMENT_TIME!)}</h3>
          </div>
          <div className='gap-2  flex flex-col flex-wrap'>
            <h3>زمن حضور الوكيل</h3>
            <h3 className="font-bold text-xl">{DisplayTimeOnly(AppointMent.AGENT_ATTENDANCE_TIME)}</h3>
          </div>
          <div className='gap-2  flex flex-col flex-wrap'>
            <h3>زمن حضور المدير</h3>
            <h3 className="font-bold text-xl">{DisplayTimeOnly(AppointMent.LEADER_ATTENDANCE_TIME)}</h3>
          </div>
          <div className='gap-2  flex flex-col flex-wrap'>
            <h3>زمن حضور العميل</h3>
            <h3 className="font-bold text-xl">{DisplayTimeOnly(AppointMent.CUSTOMER_ATTENDANCE_TIME)}</h3>
          </div>
        </div>
        <div className="flex flex-wrap gap-4 w-full min-h-full mt-5">
          <div className='gap-2  flex flex-col flex-wrap'>
            <h3>هل العميل مناسب ؟</h3>
            <h3 className="font-bold text-xl"> {AppointMent.IS_SUTIABLE === false ? "لا" : "نعم"}</h3>
          </div>
        </div>
        <div className="flex flex-wrap gap-4 w-full min-h-full mt-5">
          <div className='gap-2  flex flex-col flex-wrap'>
            <h3>ترشيح أول</h3>
            <h3 className="font-bold text-xl">{firstSuggestionPorject.PROJECT_NAME}</h3>
          </div>
          <div className='gap-2  flex flex-col flex-wrap'>
            <h3>ترشيح ثاني</h3>
            <h3 className="font-bold text-xl">{secondSuggestionPorject.PROJECT_NAME}</h3>
          </div>
        </div>
        <div className="flex flex-wrap gap-4 w-full min-h-full mt-5">
          <div className='gap-2  flex flex-col flex-wrap'>
            <h3>طلب خارجي</h3>
            <h3 className="font-bold text-xl">{AppointMent.HAS_REQUEST && AppointMent.HAS_REQUEST === true ? "نعم" : "لأ"}</h3>
          </div>
          <div className='gap-2  flex flex-col flex-wrap'>
            <h3>تحديد موعد اخر</h3>
            <h3 className="font-bold text-xl">{AppointMent.NEED_SECOND_MEETING && AppointMent.NEED_SECOND_MEETING === true ? "نعم" : "لأ"}</h3>
          </div>
        </div>
        {AppointMent.HAS_REQUEST && AppointMent.HAS_REQUEST === true && (
          <h1>محتاج طلب جديد</h1>
        )}
        {AppointMent.NEED_SECOND_MEETING && AppointMent.NEED_SECOND_MEETING === true && (
          <div>
            <h1>بيانات الإجتماع</h1>
            <div>
              <h1>موعد الإجتماع الثاني</h1>
              <h1>{"test"}</h1>
            </div>
          </div>
        )}
      </div >
    </div>
  )
}

export default AppointmentWindow