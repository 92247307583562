import { useState } from "react";
import { DevelopmentCompany_Model } from "../../../Service/models/DevolpCompanyModel";
import { ImageType } from "./projectList";
import { convertToBase64 } from "../../Shared/Shared";

const Proooo = () => {
    const [DevelopRequest, setDevelopRequest] = useState<DevelopmentCompany_Model>({} as DevelopmentCompany_Model);
    const [projectimgs, setProjectimgs] = useState<ImageType[]>([]);

    const FilesMaxSize2mb = (input: any): boolean => {
        if (input.files.length > 0) {
            const fileSize = input.files[0].size; // in bytes
            const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB

            if (fileSize > maxSizeInBytes) {
                alert('حجم الملف اكبر من 2 ميجا , برجاء اختيار ملف حجمه اصغر');
                input.value = ''; // Clear the file input
                return false;
            }
        }
        return true;
    }

    const onFileAdded = async (e: any) => {
        const files = e.target.files;

        if (FilesMaxSize2mb(e.target)) {
            let imagesBase64: ImageType[] = []

            let MaxIdNumber: number = 0;
            for (const [key, val] of Object.entries(files)) {
                let convertVal = val as any;

                let data = await convertToBase64(convertVal) as string;

                if (MaxIdNumber === 0) {
                    for (const project of projectimgs) {
                        if ((project.ID ?? 0) >= MaxIdNumber) {
                            MaxIdNumber = project.ID ?? 0;
                        }
                    }

                    MaxIdNumber++;
                } else {
                    MaxIdNumber++;
                }

                setDevelopRequest(prev => ({ ...prev, COMPANY_LOGO_BASE: data }));
            }
        }
    }

    return (
        <>
            <div className='grow h-52 border-[5px] border-gray-600 border-dashed mt-10 rounded-lg flex justify-center items-center gap-4'>
                <label htmlFor="OtherFiles">
                    <div className='i-material-symbols-add-a-photo-outline-rounded text-6xl text-gray-500 hover:cursor-pointer'>
                    </div>
                </label>
                <input style={{ visibility: 'hidden', display: 'none' }} type="file" accept="*/*" id="OtherFiles" onChange={onFileAdded} />
                <h3>إضافه ملفات اخرى</h3>
            </div>
            {DevelopRequest.COMPANY_LOGO_BASE && (
                <div className="image-preview">
                    <img src={DevelopRequest.COMPANY_LOGO_BASE} alt="Uploaded" />
                </div>
            )}
        </>
    );
}

export default Proooo;
