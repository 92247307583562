import React, { FC, useEffect, useState } from 'react'
import { CustomTextField, CustomTextFieldFullHeight } from '../../../CustomComps/Customs'
import { Box, InputAdornment, useTheme } from '@mui/material'
import { tokens } from '../../../theme';
import { useNavigate } from 'react-router-dom';
import { ConvertDateToValue, Input_Error_State } from '../../../Globals';

interface EGDateProps {
    initialValue?: Date,
    DateValue: (data: Date) => void
}

const EGDate: FC<EGDateProps> = ({ initialValue, DateValue }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const MainInputStyle = { width: '100%', backgroundColor: 'white' };
    const [inputsValidator, setInputsValidators] = useState<{ ValidateValue: Input_Error_State }>({ ValidateValue: Input_Error_State.Initial })
    // const [Req, changeReq] = useState<{ Value: string }>({ Value: initialValue ?? new Date().toString() })
    const [initialDateVal, setInitialDateVal] = useState<string>("")

    useEffect(() => {
        if (initialValue !== undefined) {
            setInitialDateVal(ConvertDateToValue(new Date(initialValue)))
        }
    }, [initialValue])


    return (
        <div>
            <div className='w-full md:w-[50%] flex flex-col gap-1'>
                <div>
                    <input className="appearance-none" type="date" value={initialDateVal} onChange={e => {
                        let dateStringToDate = e.target.value
                        // changeReq({ Value: dateStringToDate })
                        setInitialDateVal(ConvertDateToValue(new Date(e.target.value)))
                        DateValue(new Date(dateStringToDate))
                    }} />
                </div>
            </div>
        </div>
    )
}

export default EGDate