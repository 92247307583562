import { Stack, useTheme } from "@mui/material";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography/Typography";
import axios, { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { SubmitHandler } from "react-hook-form/dist/types/form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomTextField, CustomTextFieldFullHeight } from "../../../CustomComps/Customs";
import { DateRegEx, DaysNames, GetErrorAxios, MaxNumberOfArr, ResponseStatus, getDateTimeOnly, getYearMonthDayFromDate } from "../../../Globals";
import { ContactsModel } from "../../../Service/models/ContactsModel/ContactsMOdel";
import { PermissionModel } from "../../../Service/models/Permission/Permission";
import { CopyIcon, EditLogo, LogoExcel, RateIcon, THETEAM, UserActionLogo } from "../../../components/Icons/Icons";
import { DropDownType } from "../../../components/shared/EGDropDown/EGDropDOwn";
import { ContactState, GetAllContactsThunk, SaveContactThunk } from "../../../redux/slices/ContactsSlice";
import { AddAction } from "../../../redux/slices/ErrorsSlice";
import { AppDispatch } from "../../../redux/store";
import { tokens } from "../../../theme";
import { EgButton } from "../../Shared/Shared";
import ModalAction from "../Actions/ActionsModal";
import { DropDownData, DropTextField } from "../Actions/ActionsTypesScreen/AppointMents";
import * as XLSX from 'xlsx';
import { GET_ALL_STATIONS } from "../../../Service/Apis";
import DevExtremTable from "../../../components/shared/EGDExtremeTable";
import { STATION } from "../../../Service/models/STATION";
import { Add } from "@mui/icons-material";


type IUserPermissions = {
  permission: PermissionModel,
  status: boolean;
}

export type IUserTableData = {
  Id: number,
  Name: string,
  Pic: string,
  Email: string,
  Job: string,
  subRows?: IUserTableData[]
}

type InvitationResponse = {
  INVITATION_URL: string
}

type SearchType = {
  ID: number,
  number: number,
  value: string,
  Modifier: number
}

type TPagination = {
  page: number,
  start: number,
  end: number,
  noElements: number
}


const Contacts = () => {
  const [open, setOpen] = useState(false);
  const [openFeed, setOpenFeed] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpenFeed = () => setOpenFeed(true);
  const handleClose = () => setOpen(false);
  const handleCloseFeed = () => setOpenFeed(false);
  const dispatch = useDispatch<AppDispatch>();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MainInputStyle = { marginBottom: 0, width: '100%', backgroundColor: 'white' };

  const [reload, setReload] = useState<boolean>(false);
  const [outerData, setOuterData] = useState<ContactsModel>({} as ContactsModel);
  const [ContactsState, setContactsState] = useState<ContactsModel[]>([]);
  const [feedbackURL, setFeedbackURL] = useState<InvitationResponse>({ INVITATION_URL: "" });

  const [openActions, setOpenActions] = useState(false);
  const handleActionModelClose = () => setOpenActions(false);
  const handleActionModelOpen = () => setOpenActions(true);
  const [searchFactors, setSearchFactors] = useState<number[]>([0]);
  const [searchBarCount, setsearchBarCount] = useState<SearchType[]>([{ ID: 1, number: 1, value: "", Modifier: 0 }]);

  const [currentContact, setCurrentContact] = useState<ContactsModel>();

  const UsersData = useSelector(ContactState);
  const ContactsRef = useRef<ContactsModel[]>([]);
  const ContactsHolder = useRef<ContactsModel[]>([]);

  const [pagination, setPagination] = useState<TPagination>({ page: 1, start: 0, end: 10, noElements: 10 });



  const getPageAndCount = (numberOfElements: number, numberOfPage: number, start: number, end: number, increase: boolean): number[] | undefined => {


    let MaxNumberOfPages = Math.ceil(ContactsState.length / numberOfElements);

    let pageNumber: number = 0;

    if (increase === true) {
      pageNumber = numberOfPage + 1
    } else {
      if (numberOfPage === 0) {
        dispatch(AddAction({ message: "اول صفحه", status: 'warning' }))
        return;
      }
      if (numberOfPage > 0) {
        pageNumber = numberOfPage - 1
      }
      else {
        pageNumber = 0
      }
    }

    let countStart: number = (pageNumber * numberOfElements) - numberOfElements
    if (pageNumber === 0) {
      countStart = countStart + numberOfElements;
    }
    let countEnd: number = countStart + numberOfElements
    if (pageNumber === MaxNumberOfPages) {
      countEnd = ContactState.length
    }

    return [countStart, countEnd]
  }


  const reCalcModifiers = (arr: SearchType[], reset?: boolean) => {
    if (reset !== undefined && reset === true) {
      ContactsHolder.current = ContactsRef.current
    }
    arr.map((factor, idx) => {

      switch (factor.Modifier) {
        case 0:
          if (ContactsRef.current.length > 0) {
            let newArr: ContactsModel[] = []
            if (ContactsHolder.current.length > 0) {
              newArr = ContactsHolder.current.filter(con => con.NAME.includes(factor.value))
            } else {
              newArr = ContactsRef.current.filter(con => con.NAME.includes(factor.value))
            }
            if (newArr.length > 0) {
              ContactsHolder.current = newArr;
            }
            setContactsState(newArr);
          }
          break;
        case 1:
          if (ContactsRef.current.length > 0) {
            let newArr: ContactsModel[] = []
            if (ContactsHolder.current.length > 0) {
              newArr = ContactsHolder.current.filter(con => con.MOBILE_NO.includes(factor.value))
            } else {
              newArr = ContactsRef.current.filter(con => con.MOBILE_NO.includes(factor.value))
            }
            if (newArr.length > 0) {
              ContactsHolder.current = newArr;
            }
            setContactsState(newArr);
          }
          break;
        case 2:
          if (ContactsRef.current.length > 0) {
            let newArr: ContactsModel[] = []
            if (ContactsHolder.current.length > 0) {
              newArr = ContactsHolder.current.filter(con => con.MOBILE_NO_2 ?? "".includes(factor.value))
            } else {
              newArr = ContactsRef.current.filter(con => con.MOBILE_NO_2 ?? "".includes(factor.value))
            }
            if (newArr.length > 0) {
              ContactsHolder.current = newArr;
            }
            setContactsState(newArr);
          }
          break;
        case 3:
          if (ContactsRef.current.length > 0) {
            let newArr: ContactsModel[] = []
            if (factor.value === "") {
              setContactsState(ContactsRef.current);
              return;
            }

            if (ContactsHolder.current.length > 0) {
              newArr = ContactsHolder.current.filter(con => con.NOTES!.includes(factor.value))
            } else {
              newArr = ContactsRef.current.filter(con => con.NOTES!.includes(factor.value))
            }

            if (newArr.length > 0) {
              ContactsHolder.current = newArr;
            }
            setContactsState(newArr);
          }
          break;
      }
    })
  }


  const changeSerchBarValue = (item: SearchType, val?: any, modifier?: number) => {
    let pickedElement = searchBarCount.filter(ele => ele.ID === item.ID)[0];
    let removedElementArray = searchBarCount.filter(ele => ele.ID !== item.ID);

    if (val !== undefined) {
      pickedElement.value = val;
    }

    if (modifier) {
      pickedElement.Modifier = modifier;
    }

    removedElementArray.push(pickedElement);
    let orderedArr = removedElementArray.sort((a, b) => a.ID - b.ID);

    setsearchBarCount(orderedArr);
  }



  useEffect(() => {
    let dataHolderForDD: DropDownType[] = [];
    let JobdataHolder: DropDownType[] = [];
    let UserTableData: IUserTableData[] = [];

    dispatch(GetAllContactsThunk()).then(res => {
      ContactsRef.current = res.payload as ContactsModel[]
      ContactsHolder.current = res.payload as ContactsModel[]
    });


  }, [reload])

  useEffect(() => {
    setContactsState(UsersData.AllContacts);


  }, [UsersData.AllContacts])



  const RateUserFN = (id: number) => {
    var reqBodySerialized = JSON.stringify({ CONTACT_ID: id });


    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        'X-Host': window.location.hostname,
      },
      withCredentials: true,
      credentials: 'include',
    }



    axios.post('/api/feedback/getFeedbackURL', reqBodySerialized, axiosConfig).then(res => {
      setFeedbackURL(res.data)
      setOpenFeed(true)
    }
    ).catch(err => {
      let ERR = err as AxiosError


      let ErrMsg = err.response.data.Error as string || err.response.data as string || ERR.message + " At " + ERR.config?.url as string;
      dispatch(AddAction({ message: ErrMsg, status: "error" }))
    })
  }

  const handleExportClick = () => {
    let NewContacts: Partial<ContactsModel>[] = []
    ContactsState.map((cont, idx) => {
      NewContacts.push({ NAME: cont.NAME, MOBILE_NO: cont.MOBILE_NO, MOBILE_NO_2: cont.MOBILE_NO_2, NOTES: cont.NOTES })
    })
    const jsonData = NewContacts
    exportToExcel(jsonData, 'بيانات العملاء');
  };



  const exportToExcel = (jsonData: any, fileName: string) => {
    debugger
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wb = XLSX.utils.book_new();
    // const ws = XLSX.utils.aoa_to_sheet([
    //   ['Header 1', 'Header 2', 'Header 3'],
    //   ['Data 1', 'Data 2', 'Data 3'],
    // ]);
    // // Set the font color of the first cell (A1) to red
    // ws.A1.s = { font: { color: { rgb: 'FF0000' } } };
    // // Set the width of the first column (A)
    // ws['!cols'] = [{ width: 15 }, {}, {}];

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {

    //
  }, [searchFactors, searchBarCount])

  return (
    // <div className='w-full p-4'>
    //   <h1 className="text-3xl">جهات الإتصال</h1>
    //   <div className="flex flex-wrap gap-20 items-center">
    //     <div className='flex gap-4 items-center'>
    //       <div className='i-material-symbols-add-box-outline-rounded text-4xl text-gray-500 hover:cursor-pointer my-3' onClick={() => {
    //         setOuterData({} as ContactsModel)
    //         handleOpen()
    //       }}>
    //       </div>
    //       <h1 className='text-xl font-bold'>إضافه جهه إتصال</h1>

    //     </div>
    //     <h3 className="my-2 font-bold text-xl">يوجد اجمالي {ContactsRef.current.length} اسماء</h3>
    //     <div className="flex flex-wrap grow justify-between">
    //       {searchBarCount.length > 0 && searchBarCount.map((search, idx) => {
    //         return <div key={search.ID} className="w-full flex flex-wrap justify-between">
    //           <div className="flex flex-wrap items-end gap-2 relative">
    //             <label className='text-base'>محدد البحث</label>
    //             <DropTextField initalValue={0} data={[
    //               { id: 0, desc: "اسم" },
    //               { id: 1, desc: "رقم الموبايل" },
    //               { id: 2, desc: "2 رقم الموبايل" },
    //               { id: 3, desc: "ملاحظات" },
    //             ]} onchange={e => {
    //               // setSearchFactors(prev => [...prev, e.target.value])
    //               changeSerchBarValue(search, null, e.target.value)
    //             }} />
    //             <div>
    //               <CustomTextFieldFullHeight height={25} type={''} onChange={(e: any) => {
    //                 // searchFactors.map((factor, idx) => {
    //                 //   switch (factor) {
    //                 //     case 0:
    //                 //       if (ContactsRef.current.length > 0) {
    //                 //         let newArr: ContactsModel[] = []
    //                 //         if (ContactsHolder.current.length > 0) {
    //                 //           newArr = ContactsHolder.current.filter(con => con.NAME.includes(e.target.value))
    //                 //         } else {
    //                 //           newArr = ContactsRef.current.filter(con => con.NAME.includes(e.target.value))
    //                 //         }
    //                 //         ContactsHolder.current = newArr;
    //                 //         setContactsState(newArr);
    //                 //       }
    //                 //       break;
    //                 //     case 1:
    //                 //       if (ContactsRef.current.length > 0) {
    //                 //         let newArr: ContactsModel[] = []
    //                 //         if (ContactsHolder.current.length > 0) {
    //                 //           newArr = ContactsHolder.current.filter(con => con.MOBILE_NO.includes(e.target.value))
    //                 //         } else {
    //                 //           newArr = ContactsRef.current.filter(con => con.MOBILE_NO.includes(e.target.value))
    //                 //         }
    //                 //         ContactsHolder.current = newArr;
    //                 //         setContactsState(newArr);
    //                 //       }
    //                 //       break;
    //                 //     case 2:
    //                 //       if (ContactsRef.current.length > 0) {
    //                 //         let newArr: ContactsModel[] = []
    //                 //         if (ContactsHolder.current.length > 0) {
    //                 //           newArr = ContactsHolder.current.filter(con => con.MOBILE_NO_2 ?? "".includes(e.target.value))
    //                 //         } else {
    //                 //           newArr = ContactsRef.current.filter(con => con.MOBILE_NO_2 ?? "".includes(e.target.value))
    //                 //         }
    //                 //         ContactsHolder.current = newArr;
    //                 //         setContactsState(newArr);
    //                 //       }
    //                 //       break;
    //                 //     case 3:
    //                 //       if (ContactsRef.current.length > 0) {
    //                 //         let newArr: ContactsModel[] = []
    //                 //         if (e.target.value === "") {
    //                 //           setContactsState(ContactsRef.current);
    //                 //           return;
    //                 //         }

    //                 //         if (ContactsHolder.current.length > 0) {
    //                 //           newArr = ContactsHolder.current.filter(con => con.NOTES!.includes(e.target.value))
    //                 //         } else {
    //                 //           newArr = ContactsRef.current.filter(con => con.NOTES!.includes(e.target.value))
    //                 //         }

    //                 //         if (newArr.length > 0) {
    //                 //           ContactsHolder.current = newArr;
    //                 //         }
    //                 //         setContactsState(newArr);
    //                 //       }
    //                 //       break;
    //                 //   }
    //                 // })
    //                 changeSerchBarValue(search, e.target.value);
    //                 reCalcModifiers(searchBarCount)
    //               }} style={MainInputStyle}
    //                 colors={colors} theme={theme} />
    //             </div>
    //             <div className="absolute top-0 -left-5 hover:cursor-pointer" onClick={() => {
    //               if (searchBarCount.length > 1) {
    //                 let newSearchArrs = searchBarCount.filter(item => item.ID !== search.ID);

    //                 reCalcModifiers(newSearchArrs, true);
    //                 setsearchBarCount(newSearchArrs);
    //               }
    //               else {
    //                 setContactsState(ContactsRef.current)
    //                 // dispatch(AddAction({ message: "لايمكن حذف اخر محدد", status: "warning" }))
    //               }
    //             }}>
    //               <div className='i-material-symbols-close-rounded text-xl text-red-500'>
    //               </div>
    //             </div>
    //           </div>
    //           {idx + 1 === searchBarCount.length && (
    //             <div className='flex gap-4 items-center'>
    //               <div className='i-material-symbols-add-box-outline-rounded text-4xl text-gray-500 hover:cursor-pointer' onClick={() => {
    //                 let NewId = MaxNumberOfArr(searchBarCount);
    //                 setsearchBarCount(prev => [...prev, { ID: NewId, number: NewId, Modifier: 0, value: "" }])
    //               }}>
    //               </div>
    //               <h1 className='text-xl font-bold'>إضافه محدد اخر</h1>

    //             </div>
    //           )}

    //         </div>
    //       })}
    //     </div>
    //     <div className="w-12 h-12 hover:cursor-pointer" onClick={handleExportClick}>
    //       <LogoExcel />
    //     </div>
    //   </div>
    //   <div className="flex flex-col overflow-auto h-[70vh] shadow-lg">
    //     {ContactsState.length > 0 && ContactsState.slice(pagination.start, pagination.end).sort((a, b) => a.NAME.localeCompare(b.NAME)).map((Con, idx) =>
    //       <div>
    //         {/* <TableContacts modelFN={(contact) => { handleOpen(); setOuterData(contact); }} Contacts={ContactsState} deleteFN={(id) => {
    //         // dispatch(DeleteContacThunk({ CONTACT_ID: id })).then(() => {
    //         //   window.location.reload();
    //         // }
    //         // );

    //         let selectedContact = ContactsState.filter(contact => contact.CONTACT_ID === id)[0];



    //         if (selectedContact) {
    //           setCurrentContact(selectedContact)
    //           handleActionModelOpen()
    //         }



    //       }} RateFN={(id) => {
    //         RateUserFN(id);
    //       }} /> */}
    //         <div className="CardH ">
    //           <div className='flex flex-wrap gap-3'>
    //             <div className='flex gap-2 w-[4em]'>
    //               <p className='truncate'><span className="font-bold text-md border-l border-gray-500 p-1"> {idx + 1}</span></p>
    //             </div>
    //             <div className='flex gap-2 w-[15em]'>
    //               <p className="text-sm">الإسم </p>
    //               <p className='truncate'><span className="font-bold text-md"> {Con.NAME}</span></p>
    //             </div>
    //             <div className='flex gap-2 w-[15em]'>
    //               <p className="text-sm">رقم الموبايل</p>
    //               <p dir="ltr" className='truncate' ><span className="font-bold text-md">{Con.MOBILE_NO}</span></p>
    //             </div>
    //             <div className='flex gap-2  w-[15em]'>
    //               <p className="text-sm">رقم الموبايل 2</p>
    //               <p dir='ltr' className='truncate' ><span className="font-bold text-md">{Con.MOBILE_NO_2}</span></p>
    //             </div>
    //             <div className='flex gap-2 w-[15em]'>
    //               <p className="text-sm">ملاحظات</p>
    //               <p className=''><span className="font-bold text-md"> {Con.NOTES}</span></p>
    //             </div>
    //             <div className='flex gap-2'>
    //               <p className="text-sm">تاريخ الإضافه</p>
    //               <p className='truncate'><span className="font-bold text-md">  {DaysNames[new Date(Con.STAMP_DATE).getDay()]}</span> - <span className='font-bold'> {getYearMonthDayFromDate(Con.STAMP_DATE)} </span> - <span className='font-bold'>{getDateTimeOnly(Con.STAMP_DATE)}</span></p>
    //             </div>
    //           </div>
    //           <div className=" flex items-center gap-4 hover:cursor-pointer  p-1">
    //             <div className='flex flex-col items-center hover:text-[#e7c498]'>
    //               <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1 hover:cursor-pointer' onClick={() => {
    //                 //modelFN(row);
    //                 handleOpen();
    //                 setOuterData(Con);
    //               }}>
    //                 <EditLogo />
    //               </div>
    //               <p className='text-sm font-bold'>تعديل</p>
    //             </div>
    //             <div className='flex flex-col items-center hover:text-[#e7c498]'>
    //               <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1 hover:cursor-pointer' onClick={() => {
    //                 if (ContactsState !== undefined) {
    //                   let selectedContact = ContactsState.filter(contact => contact.CONTACT_ID === Con.CONTACT_ID)[0];

    //                   if (selectedContact) {
    //                     setCurrentContact(selectedContact)
    //                     handleActionModelOpen()
    //                   }
    //                 }
    //               }}>
    //                 <UserActionLogo />
    //               </div>
    //               <p className='text-xs font-bold'>الإجرائات</p>
    //             </div>
    //             <div className='flex flex-col items-center hover:text-[#e7c498] hover:cursor-pointer'>
    //               <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1 hover:cursor-pointer' onClick={() => {
    //                 //modelFN(row);

    //                 if (Con.CONTACT_ID) {
    //                   RateUserFN(Con.CONTACT_ID);
    //                 }
    //               }}>
    //                 <RateIcon />
    //               </div>
    //               <p className='text-xs font-bold'>تقيم</p>
    //             </div>

    //           </div>

    //         </div>
    //       </div>
    //     )}
    //   </div>
    //   <div className="flex justify-center items-center gap-8">
    //     <div className="flex gap-2 justify-center items-center" onClick={() => {
    //       let data = getPageAndCount(pagination.noElements, pagination.page, pagination.start, pagination.end, false);
    //       if (data !== undefined) {
    //         let [countStart, countEnd] = data
    //         setPagination(prev => ({ noElements: prev.noElements, page: prev.page - 1, start: countStart, end: countEnd }));
    //       }
    //     }}>
    //       <div className="i-material-symbols-chevron-right text-5xl text-green-400 hover:cursor-pointer" >
    //       </div>
    //       <h4 className="font-bold text-xl">السابق</h4>
    //     </div>
    //     <div className="flex gap-2 justify-center items-center" onClick={() => {
    //       let data = getPageAndCount(pagination.noElements, pagination.page, pagination.start, pagination.end, true);
    //       if (data !== undefined) {
    //         let [countStart, countEnd] = data
    //         setPagination(prev => ({ noElements: prev.noElements, page: prev.page + 1, start: countStart, end: countEnd }));
    //       }
    //     }}>
    //       <h4 className="font-bold text-xl">التالي</h4>
    //       <div className="i-material-symbols-chevron-left text-5xl text-green-400 hover:cursor-pointer" >
    //       </div>
    //     </div>
    //   </div>
    //   <div>
    //     <Modal
    //       open={open}
    //       onClose={handleClose}
    //       aria-labelledby="modal-modal-title"
    //       aria-describedby="modal-modal-description"
    //     >
    //       <ModalBody reload={() => {
    //         //setReload((old) => (!old));
    //         handleClose();
    //       }
    //       } outerData={outerData} />
    //     </Modal>
    //   </div>
    //   <div>
    //     <Modal
    //       open={openFeed}
    //       onClose={handleClose}
    //       aria-labelledby="modal-modal-title"
    //       aria-describedby="modal-modal-description"
    //     >
    //       <ModalFeedBack genURL={feedbackURL!.INVITATION_URL} closeFeed={() => { setOpenFeed(false) }} />
    //     </Modal>
    //   </div>
    //   <div>
    //     <Modal
    //       open={openActions}
    //       onClose={handleActionModelClose}
    //       aria-labelledby="modal-modal-title"
    //       aria-describedby="modal-modal-description"
    //     >
    //       <ModalAction closeFeed={() => handleActionModelClose()} contact_Current={currentContact!} ActionData={[]} />
    //     </Modal>
    //   </div>

    // </div>
    <div>
      <div className='flex gap-4 items-center'>
        <div className='i-material-symbols-add-box-outline-rounded text-4xl text-gray-500 hover:cursor-pointer my-3' onClick={() => {
          setOuterData({} as ContactsModel)
          handleOpen()
        }}>
        </div>
        <h2 className='text-xl font-bold'>إضافه جهه إتصال</h2>
      </div>
      <DevExtremTable ID="CONTACT_ID" tableData={[
        { Caption: "إسم المستخدم", Type: 'string', Value: 'STAMP_USER' },
        { Caption: "إسم العميل", Type: 'string', Value: 'NAME' },
        { Caption: "رقم التليفون", Type: 'string', Value: 'MOBILE_NO' },
        { Caption: "رقم التليفون الثاني", Type: 'string', Value: 'MOBILE_NO_2' },
        { Caption: "ملاحظات", Type: 'string', Value: 'NOTES' },
        { Caption: "مكرر", Type: 'boolean', Value: 'IS_DUPLICATED' },
        { Caption: "تاريخ الإضافه", Type: 'string', Value: 'STAMP_DATE' },
      ]}
        dataSource={ContactsState}
        Actions={(data) => {
          let currentContact = data.data as ContactsModel;
          return (
            <div className=" flex items-center gap-4 hover:cursor-pointer  p-1">
              <div className='flex flex-col items-center hover:text-[#e7c498]'>
                <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1 hover:cursor-pointer' onClick={() => {
                  //modelFN(row);
                  handleOpen();
                  setOuterData(currentContact);

                }}>
                  <EditLogo />
                </div>
                <p className='text-sm font-bold'>تعديل</p>
              </div>
              <div className='flex flex-col items-center hover:text-[#e7c498]'>
                <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1 hover:cursor-pointer' onClick={() => {
                  setCurrentContact(currentContact)
                  handleActionModelOpen()
                }}>
                  <UserActionLogo />
                </div>
                <p className='text-xs font-bold'>الإجرائات</p>
              </div>
              <div className='flex flex-col items-center hover:text-[#e7c498] hover:cursor-pointer'>
                <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1 hover:cursor-pointer' onClick={() => {
                  //modelFN(row);

                  if (currentContact.CONTACT_ID) {
                    RateUserFN(currentContact.CONTACT_ID);
                  }
                }}>
                  <RateIcon />
                </div>
                <p className='text-xs font-bold'>تقيم</p>
              </div>

            </div>
          )
        }}
      />
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalBody reload={() => {
            //setReload((old) => (!old));
            handleClose();
          }
          } outerData={outerData} />
        </Modal>
      </div>
      <div>
        <Modal
          open={openFeed}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalFeedBack genURL={feedbackURL!.INVITATION_URL} closeFeed={() => { setOpenFeed(false) }} />
        </Modal>
      </div>
      <div>
        <Modal
          open={openActions}
          onClose={handleActionModelClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalAction closeFeed={() => handleActionModelClose()} contact_Current={currentContact!} ActionData={[]} />
        </Modal>
      </div>
    </div >
  )
}

export default Contacts


const ModalBody = ({ reload, outerData }: { outerData?: ContactsModel, reload: () => void }) => {


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch<AppDispatch>();
  const [branchesDropDown, setBranchesDropDown] = useState<DropDownData[]>([]);
  const [Branches, setBranches] = useState<STATION[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<number>(-1);

  const MainInputStyle = { marginBottom: 20, width: '100%' };
  const navigate = useNavigate();



  const { register, setError, control, handleSubmit, reset, watch, formState: { errors } } = useForm<ContactsModel>({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: { CONTACT_ID: outerData?.CONTACT_ID ?? -1, NAME: outerData?.NAME ?? "", MOBILE_NO: outerData?.MOBILE_NO ?? "", MOBILE_NO_2: outerData?.MOBILE_NO_2 ?? "", NOTES: outerData?.NOTES ?? "" }
  });

  const { ref: NameRef, ...NameProps } = register("NAME", {
    required: "برجاء ادخال إسم العميل",
  });

  const { ref: MobileRef, ...MobileProps } = register("MOBILE_NO", {
    required: "ادخل رقم الموبايل",
  });
  const { ref: MobileRef2, ...MobileProps2 } = register("MOBILE_NO_2");
  const { ref: NotesRef, ...NotesProps } = register("NOTES");

  const onSubmit: SubmitHandler<ContactsModel> = async (data: ContactsModel) => {

    if (selectedBranch === -1) {
      dispatch(AddAction({ message: "برجاء اختيار فرع الشركه", status: "error" }));
      return;
    }

    data.STATION_ID = selectedBranch;


    dispatch(SaveContactThunk(data)).then(res => {
      let resp = res.payload as ResponseStatus

      if (resp.Status === 'Success') {
        window.location.reload();
      }
    }).catch(err => {
      let ERR = err as AxiosError


      let ErrMsg = err.response.data.Error as string || err.response.data as string || ERR.message + " At " + ERR.config?.url as string;

      dispatch(AddAction({ message: ErrMsg, status: "error" }))
    });
  };


  useEffect(() => {
    GET_ALL_STATIONS().then(res => {
      setBranches(res);
      setBranchesDropDown(res.map((branch, idx) => ({ id: branch.STATION_ID, desc: branch.STATION_NAME })))
    }).catch(err => {
      GetErrorAxios(err, dispatch)
    })

  }, [])


  return <div className="w-[20%] h-[80vh] absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-1/2">
    <div className='h-full w-full flex justify-center items-center'>
      <div className='bg-[#EDF3F4] rounded-3xl w-full h-full flex flex-col items-center'>
        <div className='h-1/3 p-2 mb-10'>
          <THETEAM />
        </div>
        <form className='w-4/5 h-2/3 flex flex-col  justify-start items-center' onSubmit={handleSubmit(onSubmit)}>
          <div className='w-full flex flex-row gap-4'>
            <CustomTextField inputRef={NameRef} {...NameProps} type={'text'} InputProps={{
            }} style={MainInputStyle} error={!!errors.NAME}
              helperText={errors?.NAME?.message}
              placeholder='إسم العميل' colors={colors} theme={theme} />
          </div>
          <div className='w-full flex flex-row gap-4'>
            <CustomTextField placeholder='رقم الموبايل الرئيسي' inputRef={MobileRef} {...MobileProps} type={'text'} InputProps={{

            }} style={MainInputStyle} error={!!errors.MOBILE_NO}
              helperText={errors?.MOBILE_NO?.message}
              colors={colors} theme={theme} />
          </div>
          <div className='w-full flex flex-row gap-4'>
            <CustomTextField placeholder='رقم الموبايل الفرعي' inputRef={MobileRef2} {...MobileProps2} type={'text'} InputProps={{

            }} style={MainInputStyle}
              colors={colors} theme={theme} />
          </div>
          <div className='w-full flex flex-row gap-4'>
            <CustomTextField placeholder='ملاحظات' inputRef={NotesRef} {...NotesProps} type={'text'} InputProps={{

            }} style={MainInputStyle}
              colors={colors} theme={theme} />
          </div>
          <div className='w-full flex flex-row gap-4'>
            <label className='text-base'>فرع الشركه</label>
            <DropTextField initalValue={(outerData?.MOBILE_NO !== undefined && outerData.MOBILE_NO !== "") ? outerData.STATION_ID : -1} data={[
              { id: -1, desc: "فرع الشركه" }, ...branchesDropDown
            ]} onchange={e => {
              let val = e.target.value
              setSelectedBranch(parseInt(val))
            }} />
          </div>
          <div className='w-full grow flex flex-col justify-around'>
            <div className='w-2/3 self-center'>
              <EgButton Action={'submit'} HandleClick={() => {
                //dispatch(fetchTodos({ password: 'ahmed', userName: 'ahmed' }));
              }}>
                <Stack direction={'row'} justifyContent='center'>
                  {outerData?.MOBILE_NO && (
                    <Typography sx={{ marginLeft: '10px' }} variant="h5">تعديل</Typography>
                  )}
                  {!outerData?.MOBILE_NO && (
                    <Typography sx={{ marginLeft: '10px' }} variant="h5">إضافه</Typography>
                  )}

                </Stack>
              </EgButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
}


const ModalFeedBack = ({ genURL, closeFeed }: { genURL: string, closeFeed: () => void }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }



  return <div className="w-[30%] h-[50vh] absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-1/2">
    <div className='h-full w-full flex justify-center items-center'>
      <div className='bg-[#EDF3F4] rounded-3xl w-full h-full flex flex-col p-10 justify-between items-center'>
        <div className='h-1/3 p-2 mb-10'>
          <THETEAM />
        </div>

        <div className='self-center flex flex-col w-4/5 justify-start items-start'>
          <h5 className='font-bold text-2xl'>لينك التقيم</h5>
          <div className='flex flex-row justify-between w-full'>
            <input className='w-4/5 text-xl p-2 truncate' dir={"ltr"} disabled value={genURL} />
            <button
              className='w-1/5 text-black'
              onClick={() => {
                copyTextToClipboard(genURL)
                setIsCopied(true);
              }
              }>
              <div className="w-[3em] h-[3em]" >
                <CopyIcon />
              </div>
            </button>
          </div>
          <div className="w-full">
            <EgButton HandleClick={() => {
              closeFeed()
            }}>
              <h1>إغلاق</h1>
            </EgButton>
          </div>
        </div>
      </div>
    </div>
  </div>
}

