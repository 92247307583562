import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ButtonAnimated, { animationType } from '../../../../components/ButtonAnimated';
import MulitDD from '../../../../components/MultiDD';
import { CustomTextFieldFullHeight } from '../../../../CustomComps/Customs';
import { GetErrorAxios, getYearMonthDayFromDate } from '../../../../Globals';
import { AppDispatch } from '../../../../redux/store';
import { tokens } from '../../../../theme';
import { DropDownData, DropTextField } from '../../Actions/ActionsTypesScreen/AppointMents';
import { GetAllFinishingRequest, GetUsersHasFinishingRequests, SaveFinishingFinanceTransaction } from '../../../../Service/Apis';
import { USER_Detail } from '../../../../Service/models/UserModel/UserModel';
import { FINISHING_PAYMENT_PLAN, FINISHING_REQUEST, FINISHING_TRANSACTIONS } from '../../../../Service/models/Finishing';
import GInput from '../../../../components/GInput';
import { TRANSACTION_CATG } from '../../../../Service/models/TransActions_model';


const FinishDeposit = () => {

    //global data
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const MainInputStyle = { marginBottom: 2, width: '100%', backgroundColor: 'white' };
    const dispatch = useDispatch<AppDispatch>()


    //change & total number
    const [changeData, setChangeData] = useState<{ EGP: string, FiveEGP: string, TenEgp: string, twentyEGP: string, fiftyEGP: string, hundredEGP: string, TwoHundredEGP: string }>({ EGP: "0", FiveEGP: "0", TenEgp: "0", twentyEGP: "0", fiftyEGP: "0", hundredEGP: "0", TwoHundredEGP: "0" })
    const [total, setTotal] = useState<string>("")
    const [Users, setUsers] = useState<USER_Detail[]>([])
    const [selectedUserId, setSelectedUserId] = useState<number>(0)
    // usersDropDownData 
    const [usersDropDownData, setUsersDropDownData] = useState<DropDownData[]>([])

    const [FinishState, setFinishState] = useState<FINISHING_REQUEST[]>([]);
    const [selectedFinishId, setSelectedFinishId] = useState<number>(-1)
    const [selectedFinish, setSelectedFinish] = useState<FINISHING_REQUEST>({} as FINISHING_REQUEST)
    const [finishDropDownData, setFinishDropDownData] = useState<DropDownData[]>([])

    const [animateButton, setAnimateButton] = useState<animationType>('IDLE')

    //functions
    const [Req, setReq] = useState<FINISHING_TRANSACTIONS>({ TRANSACTION_CATG: TRANSACTION_CATG.DEBIT_CATG } as FINISHING_TRANSACTIONS)


    const setRequestData = (value: any, key: keyof FINISHING_TRANSACTIONS) => {
        setReq(prev => ({
            ...prev, [key]: value
        }))
    }

    const changeDataHandler = (value: string, key: keyof { EGP: string, FiveEGP: string, TenEgp: string, twentyEGP: string, fiftyEGP: string, hundredEGP: string, TwoHundredEGP: string }) => {
        setChangeData(prev => ({
            ...prev, [key]: value
        }))
    }

    // calculate the sum of all changes in screens
    function CalculateTotal(Amounts: { EGP: string, FiveEGP: string, TenEgp: string, twentyEGP: string, fiftyEGP: string, hundredEGP: string, TwoHundredEGP: string }) {
        if (Amounts.TwoHundredEGP === "") Amounts.TwoHundredEGP = "0"
        if (Amounts.hundredEGP === "") Amounts.hundredEGP = "0"
        if (Amounts.fiftyEGP === "") Amounts.fiftyEGP = "0"
        if (Amounts.twentyEGP === "") Amounts.twentyEGP = "0"
        if (Amounts.TenEgp === "") Amounts.TenEgp = "0"
        if (Amounts.FiveEGP === "") Amounts.FiveEGP = "0"
        if (Amounts.EGP === "") Amounts.EGP = "0"



        let EGPAmount = parseInt(Amounts.EGP) ?? 0
        let EGPFive_Amount = parseInt(Amounts.FiveEGP) * 5 ?? 0
        let EGPTen_Amount = parseInt(Amounts.TenEgp) * 10 ?? 0
        let EGPTwenty_Amount = parseInt(Amounts.twentyEGP) * 20 ?? 0
        let EGPFifty_Amount = parseInt(Amounts.fiftyEGP) * 50 ?? 0
        let EGPHundred_Amount = parseInt(Amounts.hundredEGP) * 100 ?? 0
        let EGPTwoHundred_Amount = parseInt(Amounts.TwoHundredEGP) * 200 ?? 0

        return EGPAmount + EGPFive_Amount + EGPTen_Amount + EGPTwenty_Amount + EGPFifty_Amount + EGPHundred_Amount + EGPTwoHundred_Amount
    }






    // useEffect Logic
    useEffect(() => {
        GetUsersHasFinishingRequests({ WithAdmin: true, WithNewUsers: true }).then(res => {
            let resData = res as USER_Detail[]
            setUsers(resData)
            setUsersDropDownData(resData.map(user => ({ id: user.ID, desc: user.FULL_NAME })))
        }).catch(err => {
            GetErrorAxios(err, dispatch)
        })

        GetAllFinishingRequest()
            .then((res) => {
                setFinishState(res);
                setTimeout(() => {
                    setFinishDropDownData(res.map(fin => ({ id: fin.ID!, desc: fin.UNIT_ADDRESS ?? "", filterById: fin.USER_ID })))
                }, 1000);
            })
            .catch((err) => {
                GetErrorAxios(err, dispatch)
            });
    }, [])



    useEffect(() => {
        let totalSum = CalculateTotal(changeData)
        setTotal(totalSum.toString())
    }, [changeData])


    const DepositMoney = () => {
        setAnimateButton('LOADING')
        SaveFinishingFinanceTransaction({ ...Req, AMOUNT: parseFloat(total) }).then(res => {
            setAnimateButton('SUCCESS')
            setTimeout(() => {
                window.location.reload()
            }, 1000);
        }).catch(err => {
            GetErrorAxios(err, dispatch)
            setAnimateButton('ERROR')
        })
    }


    // Main Function Request 
    // const DepositMoney = (ignoreEquality: boolean = false) => {
    //     // No change Added
    //     if (total === undefined || total === "" || total === "0") {
    //         dispatch(AddAction({ message: "برجاء ادخال قيم للإيداع", status: 'error' }))
    //         return;
    //     }







    //     let totalConversion = total
    //     if (total === "") totalConversion = "0"

    //     // let TransDetails: TRANSACTION_DETALS[] = []
    //     // if (selectedBoxId !== Boxes.CommissionBox && moneySource !== MONEY_SOURCE.DEAL_SOURCE) {
    //     //     TransDetails.push({
    //     //         AMOUNT: parseFloat(totalConversion),
    //     //         DESTINATION_ID: selectedBoxId,
    //     //         BENEFICIARY_USER_ID: selectedUserId
    //     //     })
    //     // } else {
    //     //     TransDetails = selectedDeals
    //     // }

    //     // let withdrawRequestObject: TRANSACTIONS = {
    //     //     AMOUNT: parseFloat(totalConversion),
    //     //     COUNT_1: parseInt(changeData.EGP),
    //     //     COUNT_10: parseInt(changeData.TenEgp),
    //     //     COUNT_5: parseInt(changeData.FiveEGP),
    //     //     COUNT_20: parseInt(changeData.twentyEGP),
    //     //     COUNT_50: parseInt(changeData.fiftyEGP),
    //     //     COUNT_100: parseInt(changeData.hundredEGP),
    //     //     COUNT_200: parseInt(changeData.TwoHundredEGP),
    //     //     SOURCE_TYPE: moneySource,
    //     //     TRANSACTION_CATG: TRANSACTION_CATG.DEBIT_CATG,
    //     //     NOTES: notes,
    //     //     TRANSACTION_DETALS: TransDetails,
    //     //     FORCE_TRANSACTION: forceWithdraw,
    //     //     TRANSACTION_TYPE_ID: selectedTransReason,
    //     //     EFFECT_DATE: depDate
    //     // }


    //     // check if entered amount not equal selected deal amount 
    //     // if (ignoreEquality === false) {
    //     //     if (withdrawRequestObject.AMOUNT ?? 0 !== selectedDeals[0].AMOUNT) {
    //     //         showEquality()
    //     //         return;
    //     //     }
    //     // }
    //     // if no deals selected and box type is commission
    //     // if no change is selected 





    // }

    const FakeFinishRequests = (): FinishReceitType[] => {
        return [
            { id: 1, Name: 'محمد عبد الله', Amount: 1000, paid: 500, ApplyDate: new Date() },
            { id: 2, Name: 'محمد عبد الله', Amount: 1000, paid: 500, ApplyDate: new Date() },
            { id: 3, Name: 'محمد عبد الله', Amount: 1000, paid: 500, ApplyDate: new Date() },
        ]
    }

    return (
        // grid container
        <div className='grid grid-cols-2 grid-rows-1 gap-3 relative'>
            {/* right top side */}
            <div className='bg-white rounded shadow col-start-1 col-end-2 row-start-1 row-end-2'>
                <div className='p-4'>
                    <div className='w-1/2'>
                        <p className='mb-2'>اختار مستخدم</p>
                        {usersDropDownData.length > 0 ?
                            <MulitDD SingleSelection OuterDropDownData={[
                                ...usersDropDownData
                            ]} InitialSelectedIds={[selectedUserId]} SelectedIds={ids => {
                                setSelectedUserId(ids[0])

                            }} />
                            : <>
                                <div className='shimmer'>

                                </div>
                            </>}
                    </div>
                    <div className='my-10'>
                        <p className='mb-2'>طلبات تشطيب</p>
                        {finishDropDownData.length === 0 ? <div className='w-full h-10 rounded-lg overflow-hidden'>
                            <div className='shimmer'>

                            </div>
                        </div> :

                            <DropTextField data={[
                                { id: -1, desc: "اختر مشروع" },
                                ...finishDropDownData.filter(fin => fin.filterById === selectedUserId)

                            ]} initalValue={selectedFinishId} onchange={e => {
                                let value = e.target.value as number
                                console.log(value)
                                setSelectedFinishId(value)
                                setRequestData(value, 'FINISHING_REQUEST_ID')
                                let selectedFin = FinishState.find(fin => fin.ID === value)
                                if (selectedFin) {

                                    setSelectedFinish(selectedFin)
                                }
                            }} />


                        }

                    </div>
                    <FinishPaymenReceit data={selectedFinish.FINISHING_PAYMENT_PLAN ?? []} />
                </div>
            </div>
            {/* left top side*/}
            <div className='bg-white rounded shadow col-start-2 col-end-3 row-start-1 row-end-2' >
                {/* container */}
                <div className='p-3 flex flex-col gap-4'>
                    {/* صرف إجباري */}
                    {/* <div className='flex gap-3 items-center '>
                        <input type="checkbox" id="uniqueLoc" name="ProjType" className='w-[1em] h-[1em]' onChange={(e: any) => {
                            let isChecked = e.target.checked
                            setRequestData(isChecked, 'FORCE_TRANSACTION')
                        }} />
                        <label htmlFor='uniqueLoc' className='text-xl'>صرف إجباري</label>
                    </div> */}
                    {/* main container */}
                    <div className='w-full h-full p-3'>
                        <h3 className='mb-5 font-bold'>الفئات</h3>
                        {/* فئات الفلوس */}
                        <div className='flex justify-between flex-wrap w-full mb-8'>
                            {/* الجنيه */}
                            <div className='max-w-[75px]'>
                                <h6 className='mb-2'>الجنيه</h6>
                                <GInput isNumber inputChangedVal={(val) => {
                                    if (val !== '') {
                                        setRequestData(parseInt(val.toString()), 'COUNT_1')
                                        changeDataHandler(val as string, 'EGP')
                                    }
                                }} />
                            </div>
                            {/* 5 جنيه */}
                            <div className='max-w-[75px]'>
                                <h6 className='mb-2'>5 جنيه</h6>
                                <GInput isNumber inputChangedVal={(val) => {
                                    if (val !== '') {
                                        setRequestData(parseInt(val.toString()), 'COUNT_5')
                                        changeDataHandler(val as string, 'FiveEGP')
                                    }
                                }} />
                            </div>
                            {/* 10 جنيه */}
                            <div className='max-w-[75px]'>
                                <h6 className='mb-2'>10 جنيه</h6>
                                <GInput isNumber inputChangedVal={(val) => {
                                    if (val !== '') {
                                        setRequestData(parseInt(val.toString()), 'COUNT_10')
                                        changeDataHandler(val as string, 'TenEgp')
                                    }
                                }} />
                            </div>
                            {/* 20 جنيه */}
                            <div className='max-w-[75px]'>
                                <h6 className='mb-2'>20 جنيه</h6>
                                <GInput isNumber inputChangedVal={(val) => {
                                    if (val !== '') {
                                        setRequestData(parseInt(val.toString()), 'COUNT_20')
                                        changeDataHandler(val as string, 'twentyEGP')
                                    }
                                }} />
                            </div>
                            {/* 50 جنيه */}
                            <div className='max-w-[75px]'>
                                <h6 className='mb-2'>50 جنيه</h6>
                                <GInput isNumber inputChangedVal={(val) => {
                                    if (val !== '') {
                                        setRequestData(parseInt(val.toString()), 'COUNT_50')
                                        changeDataHandler(val as string, 'fiftyEGP')
                                    }
                                }} />
                            </div>
                            {/* 100 جنيه */}
                            <div className='max-w-[75px]'>
                                <h6 className='mb-2'>100 جنيه</h6>
                                <GInput isNumber inputChangedVal={(val) => {
                                    if (val !== '') {
                                        setRequestData(parseInt(val.toString()), 'COUNT_100')
                                        changeDataHandler(val as string, 'hundredEGP')
                                    }
                                }} />
                            </div>
                            {/* 200 جنيه */}
                            <div className='max-w-[75px]'>
                                <h6 className='mb-2'>200 جنيه</h6>
                                <GInput isNumber inputChangedVal={(val) => {
                                    if (val !== '') {
                                        setRequestData(parseInt(val.toString()), 'COUNT_200')
                                        changeDataHandler(val as string, 'TwoHundredEGP')
                                    }
                                }} />
                            </div>
                        </div>
                        {/* الإجمالي */}
                        <div className='w-full'>
                            <h6 className='mb-2'>الإجمالي</h6>
                            <CustomTextFieldFullHeight disabled value={total} height={25} id='PROJECT_NAME' type={''} onChange={(e: any) => {
                                let val = e.target.value as string;

                            }} style={MainInputStyle}
                                colors={colors} theme={theme} />
                        </div>
                    </div>
                    <div className='self-end' >
                        <ButtonAnimated animate={animateButton} onClick={DepositMoney}>
                            <p className='font-bold'>إيــداع</p>
                        </ButtonAnimated>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default FinishDeposit

type FinishReceitType = {
    id: number,
    Name: string,
    Amount: number,
    paid: number,
    ApplyDate: Date,
}
type MainFinishReceitType = {
    data: FINISHING_PAYMENT_PLAN[];
}

const FinishPaymenReceit = (Props: MainFinishReceitType) => {
    const { data } = Props


    // useEffect(() => {
    // }, [])


    return <div className="mt-5">
        <table className="min-w-full overflow-hidden rounded-lg shadow-md border-none">
            <thead className="bg-slate-100 rounded overflow-hidden border-0">
                <tr className="border-0 shadow">
                    <th className=" font-semibold border-none">#</th>
                    <th className=" font-semibold border-y-0  border-x-2 border-gray-200">قيمه الدفعه</th>
                    <th className=" font-semibold border-y-0  border-x-2 border-gray-200">تاريخ الإستحقاق</th>
                    <th className=" font-semibold border-y-0  border-x-2 border-gray-200">المدفوع</th>
                </tr>
            </thead>
            {data.length > 0 ?
                <>
                    <tbody>
                        {data.map((bill, idx) => {
                            return <>
                                <tr className="border-t border-gray-200">
                                    <td className=" border-x-2 border-gray-200">{idx + 1}</td>
                                    <td className=" border-x-2 border-gray-200"><span className="bg-blue-100 text-blue-800 px-2 py-1 rounded"> {bill.INSALLMENT_AMT} جنيه </span></td>
                                    <td className=" border-x-2 border-gray-200">{getYearMonthDayFromDate(bill.DUE_DATE!, true)}</td>
                                    <td className=" border-x-2 border-gray-200">{bill.PAID_AMOUNT ?? 0} جنيه
                                    </td>
                                </tr>
                            </>
                        })}

                    </tbody>
                </> : null}

        </table>
    </div >
}
