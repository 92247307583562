import { MenuItem, Modal, Select, SelectChangeEvent, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DropDownType } from '../../../../components/shared/EGDropDown/EGDropDOwn'
import EGTable from '../../../../components/shared/EGTable/EGTable'
import { CustomTextField } from '../../../../CustomComps/Customs'
import { GetErrorAxios } from '../../../../Globals'
import { AddAction } from '../../../../redux/slices/ErrorsSlice'
import { GetAllJobsThunk, JobStore } from '../../../../redux/slices/JobsSlice'
import { GetAllPermissionThunk, PermissionsState } from '../../../../redux/slices/PermissionsSlice'
import { GetAllUsersThunk, SaveUserThunk, UsersState } from '../../../../redux/slices/UserSlice'
import { AppDispatch } from '../../../../redux/store'
import { GET_ALL_STATIONS, SaveUserAPI } from '../../../../Service/Apis'
import { JobsModel } from '../../../../Service/models/Jobs/Jobs'
import { PermissionModel } from '../../../../Service/models/Permission/Permission'
import { USER_STATIONS, UserModel } from '../../../../Service/models/UserModel/UserModel'
import { tokens } from '../../../../theme'
import { DropDownData, DropTextField } from '../../../DashBoard/Actions/ActionsTypesScreen/AppointMents'
import { EgButton } from '../../../Shared/Shared'
import { STATION } from '../../../../Service/models/STATION'


type IUserPermissions = {
  permission: PermissionModel,
  status: boolean;
}

export type IUserTableData = {
  Id: number,
  Name: string,
  Pic: string,
  Email: string,
  Job: string,
  JobId: number
  Active: string,
  subRows?: IUserTableData[]
}


const Users = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [selectedUser, setSelectedUser] = useState<UserModel>({} as UserModel);
  const [selectedUserJobs, setSelectedUserJobs] = useState<JobsModel[]>();
  const [NewselectedJob, setNewSelectedJob] = useState<JobsModel>();
  const UsersData = useSelector(UsersState);
  const [DropDownData, setDropDOwnData] = useState<DropDownType[]>([]);
  const [JobsDropDownData, seJobstDropDOwnData] = useState<DropDownType[]>([]);
  const Permissions = useSelector(PermissionsState);
  const [PermissionMap, setPermissionMap] = useState<IUserPermissions[]>();
  const [UserTableData, setUserTableData] = useState<IUserTableData[]>();
  const jobs = useSelector(JobStore);
  const [reload, setReload] = useState<boolean>(false);
  const [outerData, setOuterData] = useState<IUserTableData>({} as IUserTableData);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    let dataHolderForDD: DropDownType[] = [];
    let JobdataHolder: DropDownType[] = [];
    let UserTableData: IUserTableData[] = [];

    dispatch(GetAllUsersThunk(null)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {


        let data = res.payload as UserModel[];

        data.filter(user => user.SUPER_ADMIN !== true).map(user => {

          if (user.JOBS && user.PROFILE_PIC_URL) {

            let userJob = Object.entries(user.JOBS)[0][1] as JobsModel
            UserTableData.push({ Id: user.ID, Active: user.IS_ACTIVE ? "نشط" : 'غير نشط', JobId: userJob.ID, Email: user.EMAIL, Name: user.FULL_NAME, Pic: user.PROFILE_PIC_URL, Job: userJob.JOB_DESCRIPTION })
          }

          dataHolderForDD.push({ id: user.ID, text: user.EMAIL, image: user.PROFILE_PIC_URL })
        })


        setUserTableData(UserTableData);
        setDropDOwnData(dataHolderForDD);
      }
      if (res.meta.requestStatus === 'rejected') {

        dispatch(AddAction({ message: res.payload as string, status: "error" }))
        return;
      }

    })


    dispatch(GetAllPermissionThunk()).then(res => {

      if (res.meta.requestStatus === 'fulfilled') {


        let data = res.payload as PermissionModel[];


      }
      if (res.meta.requestStatus === 'rejected') {
        dispatch(AddAction({ message: res.payload as string, status: "error" }))
        return;
      }
    }).catch(err => {
      dispatch(AddAction({ message: err.payload as string, status: "error" }))
    })

    dispatch(GetAllJobsThunk()).then(res => {

      if (res.meta.requestStatus === 'fulfilled') {

        let data = res.payload as JobsModel[];
        data.map(permision => {
          JobdataHolder.push({ id: permision.ID, text: permision.JOB_DESCRIPTION })
        })

        seJobstDropDOwnData(JobdataHolder);

      }
      if (res.meta.requestStatus === 'rejected') {
        dispatch(AddAction({ message: res.payload as string, status: "error" }))
        return;
      }
    }).catch(err => {
      dispatch(AddAction({ message: err.payload as string, status: "error" }))
    })


  }, [])


  useEffect(() => {
    if (selectedUser?.JOBS) {



      let JobList: JobsModel[] = []
      let UserPermissionArr: IUserPermissions[] = [];

      Object.entries(selectedUser?.JOBS).map(job => {
        let jobOBJ = job[1] as JobsModel
        JobList.push(jobOBJ);
      })

      Permissions.AllPermissions.map(permission => {
        UserPermissionArr.push({ status: false, permission: permission })
        if (selectedUser.PERMISSIONS) {
          Object.entries(selectedUser?.PERMISSIONS).map(permUser => {
            let permUserName = permUser[0] as string
            let permUserValue = permUser[1] as boolean

            permission.PERMISSION.map(generalPerm => {
              if (generalPerm.NAME === permUserName) {
                let filterArr = UserPermissionArr.filter(arr => arr.permission.PERMISSION.filter(perm => perm.NAME === generalPerm.NAME)[0])[0];
                filterArr.status = permUserValue;
              }
            });

          })
        }

      })


      setPermissionMap(UserPermissionArr);
      setSelectedUserJobs(JobList);
    }
  }, [selectedUser])




  return (
    <div className='w-full p-4'>
      <>
        <div>
          {UserTableData && (
            <EGTable Users={UserTableData} openModal={(User) => {
              setSelectedUser(UsersData.status.filter(user => user.ID === User.Id)[0]);
              setOuterData(User);
              handleOpen();
            }


            } DeleteUser={(User) => {
              setOuterData(User);

              let user = UsersData.status.filter(user => user.ID === User.Id)[0]
              let modifiedUserObjREQ: UserModel = { ...user, IS_ACTIVE: false };

              dispatch(SaveUserThunk(modifiedUserObjREQ)).then(res => {
                window.location.reload()
              })
            }} />
          )
          }
        </div>
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <UserModal reload={() => {
              //setReload((old) => (!old));

              handleClose();
            }
            } outerData={outerData} User={selectedUser} allUsers={UsersData.status} />
          </Modal>
        </div>

        {/* <div className='w-4/5 flex justify-between mt-5'>
          <div className='w-1/6 h-12'>
            <EgButton HandleClick={() => {


              if (!NewselectedJob) {
                dispatch(showFeedBack({ show: true, message: "اختر الوظيفه الجديده", status: 'error' }))
                return
              }

              if (!selectedUser) {
                dispatch(showFeedBack({ show: true, message: "اختر مستخدم اولا", status: 'error' }))
                return
              }

              if (selectedUser && NewselectedJob) {
                let Jobobj = new Map<number, JobsModel>();
                let UserObjectToBesSaved: UserModel = {} as UserModel


                Jobobj.set(NewselectedJob.ID, NewselectedJob);

                var JobObject = {} as any;
                JobObject[NewselectedJob.ID.toString()] = NewselectedJob;

                // let UserObjectToBesSaved = {...selectedUser, jobs : JobObject as Map<string,JobsModel[]>}
                UserObjectToBesSaved = { ...selectedUser, JOBS: JobObject }

                
                

                dispatch(SaveUserThunk(UserObjectToBesSaved))
              }
            }}>
              <Stack width='100%' direction={'row'} justifyContent='center'>
                <Typography sx={{ marginLeft: '10px' }} variant="h3">حفظ</Typography>
              </Stack>
            </EgButton>
          </div>
        </div> */}
      </>
    </div>
  )
}

type UserFilters = {
  ID: number,
  name: string,
  checked: boolean
}

const UserModal = ({ reload, outerData, User, allUsers }: { allUsers: UserModel[], User: UserModel, outerData: IUserTableData, reload: () => void }) => {


  const [jobId, setJobId] = useState<number>(outerData.JobId);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch<AppDispatch>();
  const [JobsDropDownData, seJobstDropDOwnData] = useState<DropDownType[]>();
  const [UsersDropDown, setUsersDropDown] = useState<DropDownData[]>();
  const [usersFilter, setUsersFilter] = useState<UserFilters[]>([]);
  const [selectedDirectManager, SetselectedDirectManager] = useState<number>();
  const [stations, setStations] = useState<{ ID: number, NAME: string, checked: boolean }[]>([]);

  const [isUserActive, setIsUserActive] = useState<boolean>(User.IS_ACTIVE ?? false);

  const MainInputStyle = { marginBottom: 20, width: '100%', fontWeight: 'bold', color: 'black' };
  const navigate = useNavigate();

  const { register, setError, control, handleSubmit, reset, watch, formState: { errors } } = useForm<IUserTableData>({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: { Id: outerData?.Id ?? -1, Email: outerData?.Email ?? "", Job: outerData?.Job, Name: outerData?.Name, Pic: outerData?.Pic, subRows: outerData?.subRows }
  });


  

  const changeUsersFilter = (item: UserFilters, val?: any) => {
    let pickedElement = usersFilter.filter(ele => ele.ID === item.ID)[0];
    let removedElementArray = usersFilter.filter(ele => ele.ID !== item.ID);
    if (val !== undefined) {
      pickedElement.checked = val;
    }


    removedElementArray.push(pickedElement);
    let orderedArr = removedElementArray.sort((a, b) => a.ID - b.ID);

    setUsersFilter(orderedArr);
  }

  const ReFilterUsers = () => {

    let newArrHolder: DropDownData[] = []

    usersFilter.map((filter, idx) => {
      if (filter.checked) {
        let newArr = allUsers.filter(user => {

          if (user.JOBS) {
            let UserJob = Object.entries(user.JOBS)[0][1] as JobsModel
            let userJobId = UserJob.ID;

            return filter.ID === userJobId
          }
        }).map((map, idx) => {
          newArrHolder.push({ id: map.ID, desc: map.FULL_NAME })
        })


        if (newArr.length > 0) {
          setUsersDropDown(newArrHolder);
        }
      }
    })

    if (newArrHolder.length === 0) {
      let allUsersHolder: DropDownData[] = []
      allUsers.map((user, idx) => {
        allUsersHolder.push({ id: user.ID, desc: user.FULL_NAME })
      });
      setUsersDropDown(allUsersHolder)
    }
  }


  const getAllStations = async () => {
    GET_ALL_STATIONS().then(res => {
      setStations(res.map((station, idx) => ({ ID: station.STATION_ID, NAME: station.STATION_NAME, checked: User.USER_STATIONS?.filter(st => st.STATION_ID === station.STATION_ID)[0] ? true : false })))
    }).catch(err => {
      GetErrorAxios(err, dispatch)
    })
  }


  useEffect(() => {
    let JobdataHolder: DropDownType[] = [];
    let UsersDropDown: DropDownData[] = [];
    dispatch(GetAllJobsThunk()).then(res => {

      if (res.meta.requestStatus === 'fulfilled') {

        let data = res.payload as JobsModel[];

        data.map(job => {
          JobdataHolder.push({ id: job.ID, text: job.JOB_DESCRIPTION })
          setUsersFilter(prev => [...prev, { ID: job.ID, checked: false, name: job.JOB_DESCRIPTION }])
        })

        seJobstDropDOwnData(JobdataHolder);

      }
      if (res.meta.requestStatus === 'rejected') {
        dispatch(AddAction({ message: res.payload as string, status: "error" }))
        return;
      }
    }).catch(err => {
      dispatch(AddAction({ message: err.payload as string, status: "error" }))
    })

    if (allUsers.length > 0) {
      allUsers.map((user, idx) => {
        UsersDropDown.push({ id: user.ID, desc: user.FULL_NAME })
      })
    }
    setUsersDropDown(UsersDropDown)


    getAllStations()


  }, [])





  const onSubmit: SubmitHandler<IUserTableData> = async (data: IUserTableData) => {

    if (selectedDirectManager === User.ID) {
      dispatch(AddAction({ message: "لا يمكن تعين نفسك كـمدير", status: "error" }))
      return;
    }
    var JobObject = {} as any;
    JobObject[jobId] = { ID: data.Id, JOB_DESCRIPTION: '', JOB_DESCRIPTION_EN: '', COMPANY_ID: 0 };
    let modifiedUserObjREQ: UserModel = {} as UserModel
    let userStations: USER_STATIONS[] = stations.filter(station => station.checked === true).map(st => ({ STATION_ID: st.ID, USER_ID: User.ID, STATION_NAME: st.NAME }));
    if (selectedDirectManager !== undefined && selectedDirectManager > 0) {
      modifiedUserObjREQ = { ...User, ID: data.Id, EMAIL: data.Email, JOBS: JobObject, PARENT_USER_ID: selectedDirectManager, USER_STATIONS: userStations };
    } else {
      modifiedUserObjREQ = { ...User, ID: data.Id, EMAIL: data.Email, JOBS: JobObject, USER_STATIONS: userStations };
    }

    if (isUserActive !== undefined) {
      modifiedUserObjREQ = { ...modifiedUserObjREQ, IS_ACTIVE: isUserActive };
    }

    // dispatch(SaveUserThunk(modifiedUserObjREQ)).then(res => {
    //   reload()
    //   ///window.location.reload()
    // })

    SaveUserAPI(modifiedUserObjREQ).then(res => {
      window.location.reload()
      reload()
    }).catch(err => {
      GetErrorAxios(err, dispatch)
    })
  };


  return <div className=" w-[90%] lg:w-[50%] h-[95vh] absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-1/2">
    <div className='h-full w-full flex justify-center items-center'>
      <div className='bg-[#EDF3F4] rounded-3xl w-full h-full flex flex-col items-center'>
        <div className='h-1/5 p-2 mb-5'>
          <div className='w-[10em] h-[10em] rounded-[50%] bg-yellow-600 overflow-hidden'>
            <img src={outerData?.Pic} alt={outerData?.Email} />
          </div>
        </div>
        <form className='w-4/5 h-2/3 flex flex-col  justify-start items-center overflow-scroll p-4' onSubmit={handleSubmit(onSubmit)}>
          <div className='w-full flex flex-row gap-4' dir='ltr'>
            <CustomTextField placeholder='الإسم' type={'text'} InputProps={{
            }} style={MainInputStyle} value={outerData?.Name}
              colors={colors} theme={theme} label='الإسم' />
          </div>
          <div className='w-full flex flex-row gap-4 ' dir='ltr'>
            <CustomTextField type={'text'} InputProps={{
            }} style={MainInputStyle} value={outerData?.Email}
              placeholder='البريد الإلكتروني' colors={colors} theme={theme} label="البريد الإلكتروني" />
          </div>
          {JobsDropDownData && (
            <div className='w-full flex flex-row gap-4'>
              <Select
                className='w-full'
                value={jobId}
                onChange={(e: SelectChangeEvent<number>) => {
                  setJobId(e.target.value as number)
                }
                }
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value={-1}>{"اختار رتبه"}</MenuItem>
                {JobsDropDownData.map(rank => (
                  <MenuItem value={rank.id}>{rank.text}</MenuItem>
                ))}

              </Select>
            </div>
          )}

          <div className='self-start mt-5 flex gap-3'>
            <input type="checkbox" checked={isUserActive} id={"IsUserActive"} name="explain" className='w-[1.5em] h-[1.5em] mx-1' onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

              let val = e.target.checked;

              setIsUserActive(val)

            }} />
            <label htmlFor={"IsUserActive"} className='text-xl font-bold'>المستخدم نشط</label>

          </div>

          <div className='w-full mt-2'>
            <p className='font-bold text-xl my-5'>المدير المباشر</p>
            <div>
              <div className='flex flex-wrap gap-2 mb-4'>
                {usersFilter.length > 0 && usersFilter.map((job, idx) => {

                  return <div key={job.ID}>
                    <input type="checkbox" checked={job.checked} id={job.ID.toString()} name="explain" className='w-[1.5em] h-[1.5em] mx-1' onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

                      let val = e.target.checked;
                      changeUsersFilter(job, val)
                      ReFilterUsers()
                    }} />
                    <label htmlFor={job.ID.toString()} className='text-base'>{job.name}</label>
                  </div>
                })}
              </div>
            </div>
            <DropTextField classes='w-full' initalValue={User.PARENT_USER_ID} data={[
              ...UsersDropDown ?? [{ id: 0, desc: "لا يوجد مستخدمين" }]
            ]} onchange={e => {
              let Value = e.target.value
              SetselectedDirectManager(Value);
            }} />
          </div>
          <p className='font-bold text-xl my-5'>فرع الشركه</p>
          <div className='flex gap-2'>
            {stations.length > 0 && stations.map((station, idx) => {
              return <div key={station.ID}>
                <input type="checkbox" checked={station.checked} id={station.ID.toString()} name="station" className='w-[1.5em] h-[1.5em] mx-1' onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

                  let val = e.target.checked;

                  let selectedStation = stations.filter(st => st.ID === station.ID)[0];
                  selectedStation.checked = val;

                  let restOfStations = stations.filter(st => st.ID !== station.ID);

                  let addArr = [selectedStation, ...restOfStations];

                  let sortArrbyId = addArr.sort((a, b) => a.ID - b.ID);

                  setStations(sortArrbyId);

                  // changeUsersFilter(job, val)
                  // ReFilterUsers()
                }} />
                <label htmlFor={station.NAME} className='text-base'>{station.NAME}</label>
              </div>
            })}
          </div>
          <div className='w-full grow flex flex-col justify-around'>
            <div className='w-2/3 self-center my-5'>
              <EgButton Action={'submit'} HandleClick={() => {
                //dispatch(fetchTodos({ password: 'ahmed', userName: 'ahmed' }));
              }}>
                <Stack direction={'row'} justifyContent='center'>
                  <Typography sx={{ marginLeft: '10px' }} variant="h5">تعديل</Typography>
                </Stack>
              </EgButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
}

export default Users