import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetErrorAxios, HavePermission, PERMISSIONS } from "../../../Globals";
import { GetAllUserSalaries } from "../../../Service/Apis";
import { UserSalaries } from "../../../Service/models/Salary_Model";
import { CashBoxBalance } from "../../../Service/models/TransActions_model";
import { DetailsIcons } from "../../../components/Icons/Icons";
import DevExtremTable from "../../../components/shared/EGDExtremeTable";
import { setLoadingFalse, setLoadingTrue } from "../../../redux/slices/LoadingSlice";
import { AppDispatch } from "../../../redux/store";
import './style.css';
import NewSalary from "./NewSalary";
import { UsersState } from "../../../redux/slices/UserSlice";
import UpdateSalary from "./UpdateSalary";

const Salary = () => {

    //states
    const [AllSalary, setAllSalarys] = useState<UserSalaries[]>([]);
    const dispatch = useDispatch<AppDispatch>();
    const [selectedSalary, setSelectedSalary] = useState<UserSalaries>({} as UserSalaries)
    const [Comp, setComp] = useState<JSX.Element>(() => <h1>Test</h1>);
    const [balance, setBalance] = useState<CashBoxBalance>({} as CashBoxBalance);
    const UsersData = useSelector(UsersState);


    // Refs 
    const NewSalaryRef = useRef<HTMLDivElement>(null)
    const UpdateSalaryRef = useRef<HTMLDivElement>(null)


    // new Salary Screen
    const hideNewSalaryScreen = () => {
        NewSalaryRef.current?.classList.add('hideSalary');
        NewSalaryRef.current?.classList.remove('showSalary');
    };

    const showNewSalaryScreen = () => {
        NewSalaryRef.current?.classList.add('showSalary');
        NewSalaryRef.current?.classList.remove('hideSalary');
    };

    // Update Salary Screen
    const hideUpdateSalaryScreen = () => {
        UpdateSalaryRef.current?.classList.add('hideSalary');
        UpdateSalaryRef.current?.classList.remove('showSalary');
    };

    const showUpdateSalaryScreen = () => {
        UpdateSalaryRef.current?.classList.add('showSalary');
        UpdateSalaryRef.current?.classList.remove('hideSalary');
    };



    // useEffect
    useEffect(() => {
        hideNewSalaryScreen()
        hideUpdateSalaryScreen()

        dispatch(setLoadingTrue())
        GetAllUserSalaries().then(res => {
            setAllSalarys(res);
            dispatch(setLoadingFalse())
        }).catch(err => {
            GetErrorAxios(err, dispatch)
        })
    }, []);

    return (
        <>
            <div>
                {UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.SAVE_SALARY, UsersData.currentUser.PERMISSIONS!) && (
                    <div className='flex gap-1 items-center' onClick={() => {
                        showNewSalaryScreen()
                    }}>
                        <div className='i-material-symbols-add-box-outline-rounded text-2xl text-gray-500 hover:cursor-pointer my-3'>
                        </div>
                        <h4 className='text-xl font-bold'>إضافه مرتب</h4>
                    </div>
                )}
                <DevExtremTable
                    ID="USER_ID"
                    tableData={[
                        { Caption: "اسم المستخدم", Type: 'string', Value: 'USER_NAME' },
                        { Caption: "التاريخ", Type: 'string', Value: 'DATE' },
                        { Caption: "المرتب", Type: 'string', Value: 'AMOUNT' },
                    ]}
                    dataSource={AllSalary}
                    Actions={(data) => {
                        let currentSalary = data.data as UserSalaries;
                        return (
                            <div className="flex items-center gap-4 hover:cursor-pointer p-1">
                                <div className='flex flex-col items-center hover:text-[#e7c498]' onClick={() => {
                                    setSelectedSalary(currentSalary)
                                    showUpdateSalaryScreen()
                                }}>
                                    <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1 hover:cursor-pointer'>
                                        <DetailsIcons />
                                    </div>
                                    <p className='text-sm font-bold'>تعديل</p>
                                </div>
                            </div>
                        );
                    }}
                />
                <div ref={NewSalaryRef} className='z-[100] w-full h-[85vh] p-10 absolute top-0 flex justify-center items-center transition-all ease-in-out duration-[1000ms]'>
                    <div className="w-full h-full relative bg-slate-50 shadow-md rounded-xl p-5">
                        <NewSalary />
                        <div className='absolute top-2 left-2 i-material-symbols-close-rounded text-3xl text-red-500 hover:cursor-pointer' onClick={() => {
                            hideNewSalaryScreen()
                        }}>
                        </div>
                    </div>
                </div>
                <div ref={UpdateSalaryRef} className='z-[100] w-full h-[85vh] p-10 absolute top-0 flex justify-center items-center transition-all ease-in-out duration-[1000ms]'>
                    <div className="w-full h-full relative bg-slate-50 shadow-md rounded-xl p-5">
                        <UpdateSalary salary={selectedSalary} />
                        <div className='absolute top-2 left-2 i-material-symbols-close-rounded text-3xl text-red-500 hover:cursor-pointer' onClick={() => {
                            hideUpdateSalaryScreen()
                        }}>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Salary;
