import { Box, useTheme, Typography, IconButton, Button, makeStyles } from '@mui/material'
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect } from 'react'
import { ColorModeContext, tokens } from '../../theme';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { Logo, MenuIcon, THETEAM } from '../../components/Icons/Icons';
import TopBarLong from './components/TopBarLong';
import { getNotificationElement, menuItemJS } from '../../JS/functions';
import { Link } from 'react-router-dom';


function TopBar({ setOpenVal, openVal }: { setOpenVal: React.Dispatch<React.SetStateAction<boolean>>, openVal: boolean }) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);




    const MenuItemCallback = useCallback(() => menuItemJS('#e7c498', '#000'), []);

    useEffect(() => {
        MenuItemCallback();
    }, []);


    return (
        <Box sx={{ boxShadow: 3, zIndex: 1000 }} display='flex' height='80px' bgcolor={colors.primary[400]}>
            <Box paddingX={2} flex={1} display='flex' justifyContent='space-between' alignItems='center' flexDirection='row'>
                <Box display='flex' flexDirection='row' alignItems='center'>
                    <Link to="/">
                        <Box width='65px' height='65px'>
                            <THETEAM />
                        </Box>
                    </Link>
                </Box>

                <div id='MenuContainer' className='w-10 h-10 secondaryBG p-2 rounded-md hover:cursor-pointer hover:primaryBG' onClick={(e) => setOpenVal(!openVal)}>
                    {/* <IconButton
                        size="large"
                        sx={{
                            borderRadius: 2,
                            backgroundColor: `#e7c498`,
                            color: "#000",
                            ":hover": { backgroundColor: '#000', color: "##e7c498" },
                            "& .MuiTouchRipple-root .MuiTouchRipple-child": {
                                borderRadius: "4px"
                            },

                        }}
                        
                    >
                        <Box width='15px' height='15px' color={'#e7c498'} >
                          
                        </Box>

                    </IconButton> */}
                    <MenuIcon color={'#000'} />
                </div>
            </Box>
            <Box flex={6} >
                <TopBarLong />
            </Box>
        </Box>
    )
}

export default TopBar