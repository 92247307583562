import { MenuItem, TextField, useTheme } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomTextFieldFullHeight } from "../../../../CustomComps/Customs";
import { ActionTypes, CallActionTypes, DaysNames, EmptyObjectData, getDateTimeOnly, GetErrorAxios, getErrorString, getYearMonthDayFromDate } from "../../../../Globals";
import { GetAllCitiesThunk, citiesState } from "../../../../redux/slices/CitySlice";
import { AddAction } from "../../../../redux/slices/ErrorsSlice";
import { AppDispatch } from "../../../../redux/store";
import { axiosConfig, getAllCalender, get_confirmed_appointment_details } from "../../../../Service/Apis";
import { USER_CALENDER } from "../../../../Service/models/CalenderModel/CalenderModel";
import { ContactsModel } from "../../../../Service/models/ContactsModel/ContactsMOdel";
import { ProjectModel } from "../../../../Service/models/projects/ProjectModel";
import { ACTIONS, APPOINTMENT, REQUEST } from "../../../../Service/models/UserModel/Actions/ActionsModel";
import { USER_RESERVATION } from "../../../../Service/models/UserReservation";
import { tokens } from "../../../../theme";
import { EgButton } from "../../../Shared/Shared";
import { GetProjectsAsync, ProjectsState } from "../../../../redux/slices/Projects";




export type DropDownData = {
  id: number,
  desc: string,
  image?: string,
  filterById?: string | number
}



const AppointMents = ({ selectedContact, projects }: { selectedContact: ContactsModel, projects: ProjectModel[] }) => {


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MainInputStyle = { marginBottom: 20, width: '100%', backgroundColor: 'white' };

  const cities = useSelector(citiesState);
  const projectsFromRedux = useSelector(ProjectsState);

  const [DropDownType, setDropDownType] = useState<DropDownData[]>([]);
  const [CitiesDropDownType, setCitiesDropDown] = useState<DropDownData[]>([]);
  const [DropDownType2, setDropDownType2] = useState<DropDownData[]>([]);
  const [CitiesDropDownType2, setCitiesDropDown2] = useState<DropDownData[]>([]);
  const [AvailableReservs, setAvailableReservs] = useState<USER_CALENDER[]>([]);

  const [actions, setActions] = useState<ACTIONS>({ ACTION_TYPE: ActionTypes.Appointment, CONTACT_ID: selectedContact.CONTACT_ID!, STAMP_DATE: new Date() });
  const [ActionTypeOBJ, setActionTypeOBJ] = useState<APPOINTMENT>({ IS_SUTIABLE: false } as APPOINTMENT);
  const AppointRef = useRef<APPOINTMENT>();
  const [reservation, set_reservation] = useState<USER_RESERVATION>({} as USER_RESERVATION);
  const [SubAction, SetSupAction] = useState<REQUEST>({} as REQUEST);
  const [resvREQ, setResvREQ] = useState<USER_RESERVATION>({} as USER_RESERVATION);
  const [loading, setLoading] = useState<{ FLAG: number, ERROR: string }>({ FLAG: 1, ERROR: "" });


  const dispatch = useDispatch<AppDispatch>();

  const get_available_appoitments = () => {
    getAllCalender().then(res => {
      if (reservation.PERSONAL_RESV) {
        setAvailableReservs(res.PERSONAL_CALENDAR)
      } else {
        setAvailableReservs(res.LEADER_CALENDAR)
      }
    })
  }

  const sendDataToServer = () => {

    let ReqOBJ: ACTIONS = actions;
    AppointRef.current = { ...ActionTypeOBJ, REQUEST: SubAction, USER_RESERVATION: resvREQ }

    ReqOBJ.APPOINTMENT = AppointRef.current

    let serializeReq = JSON.stringify(ReqOBJ);
    //
    //

    axios.post(`/api/contacts/saveAction`, serializeReq, axiosConfig).then(
      res => {
        dispatch(AddAction({ message: "تم حجز نتيجه اللقاء", status: "success" }))
      }
    ).catch(err => {
      GetErrorAxios(err, dispatch)
    })
  }


  // useEffect(() => {
  //   //if projects lentgh is 0 then get projects from redux
  //   if (projects.length < ) {
  //     dispatch(GetProjectsAsync())
  //   }
  //   //if cities length is 0 then get cities from redux
  //   if (cities.length < 0) {
  //     dispatch(GetAllCitiesThunk())
  //   }
  // }, [])

  useEffect(() => {
    debugger
    if (projectsFromRedux.status.length < 1) {
      dispatch(GetProjectsAsync())
    } else {
      let projects2 = projectsFromRedux.status
      let project_state_holder: DropDownData[] = []
      projects2.map(proj => {
        project_state_holder.push({
          id: proj.ID, desc: proj.PROJECT_NAME
        })
      })
      setDropDownType(project_state_holder)
      setDropDownType2(project_state_holder)
    }

    if (cities.length < 1) {
      dispatch(GetAllCitiesThunk())
    } else {
      let citiesAfterReload = cities
      let Mapped_Cities_to_DropDownType: DropDownData[] = []

      citiesAfterReload.map(city => {
        Mapped_Cities_to_DropDownType.push({ desc: city.CITY_NAME, id: city.ID })
      });

      setCitiesDropDown(Mapped_Cities_to_DropDownType);
      setCitiesDropDown2(Mapped_Cities_to_DropDownType);
    }



    if (selectedContact.CONTACT_ID) {
      get_confirmed_appointment_details(selectedContact.CONTACT_ID).then(res => {
        set_reservation(res)
        setLoading({ ERROR: "", FLAG: 0 });
      }).catch(err => {
        setLoading({ ERROR: getErrorString(err), FLAG: 1 });
        GetErrorAxios(err, dispatch)
      })
    }


  }, [cities, projectsFromRedux.status])


  function set_all_fields_false() {
    SetSupAction(prev => ({ ...prev, IS_PERSONAL_HOME: false, IS_COMMERCIAL: false, IS_ADMINISTRATIVE: false, IS_MEDICAL: false, IS_HOTEL: false }))
  }


  // useEffect(() => {
  //   setActions(prev => ({ ...prev, APPOINTMENT: { ...ActionTypeOBJ, ACTION_ID: -1, APPOINTMENT_TIME: undefined }, STAMP_DATE: new Date() }))
  // }, [ActionTypeOBJ])


  return (
    <div className="w-full h-full justify-start items-center bg-white p-5 rounded-lg  flex flex-col gap-6 overflow-auto">
      {loading.FLAG === 0 && (
        <div className=" w-full h-full flex flex-col gap-3">
          <div>
            <h3>العنوان</h3>
            <h3 className="font-bold text-xl">{reservation.TITLE}</h3>
          </div>
          <div>
            <h3>اليوم</h3>
            <div className="flex gap-2">
              <h3 className="font-bold text-xl">{DaysNames[new Date(reservation.TIME_FROM).getDay()]}</h3>
              <h3 className="font-bold text-xl">{getYearMonthDayFromDate(new Date(reservation.TIME_FROM))}</h3>
            </div>
          </div>
          <div>
            <h3>موعد الحجز</h3>
            <div className="flex flex-wrap items-center gap-2 w-[30%] bg-white shadow-md rounded-md p-2">
              <div className="i-mdi-calendar-check  text-3xl text-green-400">
              </div>
              <h3>من <span className="font-bold text-lg">{getDateTimeOnly(reservation.TIME_FROM as Date)}</span></h3>
              <h3>الى <span className="font-bold text-lg">{getDateTimeOnly(reservation.TIME_TO as Date)}</span></h3>
            </div>
          </div>
          <div className="flex gap-3">
            <h3>درجه حماس العميل</h3>
            {reservation.CUSTOMER_ENTHUSIASM_LEVEL && (
              <h3 className="font-bold text-xl"><span className={reservation.CUSTOMER_ENTHUSIASM_LEVEL < 5 ? 'text-red-400' : reservation.CUSTOMER_ENTHUSIASM_LEVEL < 8 ? 'text-yellow-400' : 'text-green-400'}>{reservation.CUSTOMER_ENTHUSIASM_LEVEL}</span>/10</h3>
            )}
          </div>
          <div className="flex flex-wrap items-center gap-6">
            <Attendance title="زمن حضور العميل" initialDate={new Date(reservation.TIME_FROM)} data={(clientAttendance) => {



              setActionTypeOBJ((prev) => ({ ...prev, CUSTOMER_ATTENDANCE_TIME: clientAttendance }))

              // if (ActionTypeOBJ.APPOINTMENT_TIME && day) {
              //   
              //   let modifiedDate = new Date(clientAttendance);
              //   modifiedDate.setDate(day?.getDate())
              //   modifiedDate.setMonth(day?.getMonth())
              //   
              //   setActionTypeOBJ((prev) => ({ ...prev, CUSTOMER_ATTENDANCE_TIME: modifiedDate }))
              // }
            }} />
            <Attendance title="زمن حضور الوكيل" initialDate={new Date(reservation.TIME_FROM)} data={(clientAttendance) => {
              setActionTypeOBJ((prev) => ({ ...prev, AGENT_ATTENDANCE_TIME: clientAttendance }))
              // if (ActionTypeOBJ.APPOINTMENT_TIME && day) {
              //   
              //   let modifiedDate = new Date(clientAttendance);
              //   modifiedDate.setDate(day?.getDate())
              //   modifiedDate.setMonth(day?.getMonth())
              //   
              //   setActionTypeOBJ((prev) => ({ ...prev, AGENT_ATTENDANCE_TIME: modifiedDate }))
              // }
            }} />
            <Attendance title="زمن حضور المدير" initialDate={new Date(reservation.TIME_FROM)} data={(clientAttendance) => {
              setActionTypeOBJ((prev) => ({ ...prev, LEADER_ATTENDANCE_TIME: clientAttendance }))
              // if (ActionTypeOBJ.APPOINTMENT_TIME && day) {
              //   
              //   let modifiedDate = new Date(clientAttendance);
              //   modifiedDate.setDate(day?.getDate())
              //   modifiedDate.setMonth(day?.getMonth())
              //   
              //   setActionTypeOBJ((prev) => ({ ...prev, LEADER_ATTENDANCE_TIME: modifiedDate }))
              // }
            }} />
          </div>
          { }
          <Suitable ActionFlag={ActionTypeOBJ.IS_SUTIABLE} Title="هل العميل مناسب" isSuit={(val) => {
            if (!val) {
              setActionTypeOBJ((prev) => ({ ...prev, IS_SUTIABLE: false }))
            }
            setActionTypeOBJ((prev) => ({ ...prev, IS_SUTIABLE: val, NEED_SECOND_MEETING: false, HAS_REQUEST: false }))

            if (val === false) {
              let newSubAction = EmptyObjectData<REQUEST>(SubAction);
              SetSupAction(newSubAction);

              //setActionTypeOBJ((prev) => ({ ...prev, HAS_REQUEST: false, NEED_SECOND_MEETING: false, REQUEST: EmptyObjectData<REQUEST>(SubAction), USER_RESERVATION: {} as USER_RESERVATION }))
            }


          }} />
          <div className="w-full">
            {!ActionTypeOBJ.IS_SUTIABLE && (
              <NotSuitCLient chooseId={e => {
                setActionTypeOBJ((prev) => ({ ...prev, REJECTION_REASON: e }))
              }} />
            )}
            {ActionTypeOBJ.IS_SUTIABLE && (
              // <SuitableClient />
              <>
                <div className="w-full flex flex-col justify-between">
                  <div className="w-full">
                    <div className="flex flex-col gap-6 justify-start flex-wrap">
                      <div className="flex flex-col gap-2">
                        <div>
                          <h3 className="text-xl">ترشيح أول</h3>
                        </div>
                        <div className="flex gap-2 flex-wrap">
                          <div className="flex gap-2">
                            <DropTextField initialText="المدينه" data={[
                              ...CitiesDropDownType
                            ]} onchange={(data) => {
                              //SetSupAction(prev => ({ ...prev, CITY_ID: data.target.value }))
                              let newDropType: DropDownData[] = [];
                              let New_ProjectsArr = projects.filter(proj => proj.CITY_ID === parseInt(data.target.value))
                              New_ProjectsArr.map(proj => {
                                newDropType.push({ id: proj.ID, desc: proj.PROJECT_NAME })
                              })
                              setDropDownType(newDropType)
                            }} />
                          </div>
                          <div className="flex gap-2">
                            <DropTextField initialText="المشروع" data={[
                              ...DropDownType
                            ]} onchange={(data) => {

                              //SetSupAction(prev => ({ ...prev, PROJECT_ID: data.target.value }))
                              setActionTypeOBJ((prev) => ({ ...prev, FIRST_SUGGESTED_PROJECT_ID: data.target.value }))
                            }} />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <div>
                          <h3 className="text-xl">ترشيح ثاني</h3>
                        </div>
                        <div className="flex gap-2 flex-wrap">
                          <div className="flex gap-2">
                            <DropTextField initialText="المدينه" data={[
                              ...CitiesDropDownType2
                            ]} onchange={(data) => {
                              //SetSupAction(prev => ({ ...prev, CITY_ID: data.target.value }))
                              let newDropType: DropDownData[] = [];
                              let New_ProjectsArr = projects.filter(proj => proj.CITY_ID === parseInt(data.target.value))
                              New_ProjectsArr.map(proj => {
                                newDropType.push({ id: proj.ID, desc: proj.PROJECT_NAME })
                              })
                              setDropDownType2(newDropType)
                            }} />
                          </div>
                          <div className="flex gap-2">
                            <DropTextField initialText="المشروع" data={[
                              ...DropDownType2
                            ]} onchange={(data) => {
                              setActionTypeOBJ((prev) => ({ ...prev, SECOND_SUGGESTED_PROJECT_ID: data.target.value }))
                              //SetSupAction(prev => ({ ...prev, PROJECT_ID: data.target.value }))
                            }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-5">
                      <div className='flex gap-3 mr-2 my-4'>
                        <input type="checkbox" id="explain" name="explain" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {
                          setActionTypeOBJ(prev => ({ ...prev, HAS_REQUEST: e.target.checked }))

                          if (e.target.checked === true) {
                            SetSupAction((prev) => ({ ...prev, NEW_PROJECT: true }));
                          }

                          if (e.target.checked === false) {
                            let newSubAction = EmptyObjectData<REQUEST>(SubAction);
                            SetSupAction(newSubAction);

                            //setActionTypeOBJ(prev => ({ ...prev, REQUEST:newSubAction }))

                          }
                        }} />
                        <label htmlFor='explain' className='text-xl font-bold'>طلب خارجي</label>
                      </div>
                      <div className='flex gap-3 mr-2 my-4'>
                        <input type="checkbox" id="explain" name="explain" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {
                          setActionTypeOBJ(prev => ({ ...prev, NEED_SECOND_MEETING: e.target.checked }))
                          if (e.target.checked === true) {
                            get_available_appoitments();
                          }

                          if (e.target.checked === false) {
                            let emptyReserv = EmptyObjectData<USER_RESERVATION>(resvREQ);
                            setResvREQ(emptyReserv);

                            //setActionTypeOBJ(prev => ({ ...prev, USER_RESERVATION: EmptyObjectData(resvREQ) as USER_RESERVATION }))
                          }
                        }} />
                        <label htmlFor='explain' className='text-xl font-bold'>تحديد موعد اخر</label>
                      </div>
                    </div>
                    {ActionTypeOBJ.HAS_REQUEST && (
                      <div className="grid grid-cols-2 w-full h-full">
                        <div className="">
                          <h3 className="text-xl my-3">مشروع جديد</h3>
                          <div className='w-3/4 my-2'>
                            <CustomTextFieldFullHeight height={25} placeholder='إسم المشروع' type={''} onChange={(e: any) => {
                              SetSupAction(prev => ({ ...prev, PROJECT_NAME: e.target.value }))
                            }} style={MainInputStyle}
                              colors={colors} theme={theme} />
                          </div>
                          <div className='w-3/4 my-2'>
                            <CustomTextFieldFullHeight height={25} placeholder='إسم الشركه' type={''} onChange={(e: any) => {
                              SetSupAction(prev => ({ ...prev, COMPANY_NAME: e.target.value }))
                            }} style={MainInputStyle}
                              colors={colors} theme={theme} />
                          </div>
                          <div className='w-3/4 my-2'>
                            <CustomTextFieldFullHeight height={25} placeholder='المنطقه' type={''} onChange={(e: any) => {
                              SetSupAction(prev => ({ ...prev, LOCATION: e.target.value }))
                            }} style={MainInputStyle}
                              colors={colors} theme={theme} />
                          </div>
                        </div>
                        <div className="w-full">
                          <h3 className="text-xl my-3">طلب خارجي</h3>
                          <div className='w-3/4 my-2'>
                            <CustomTextFieldFullHeight height={25} placeholder='ميزانيه المقدم' type={'number'} onChange={(e: any) => {
                              SetSupAction(prev => ({ ...prev, BUDGET: parseFloat(e.target.value) }))
                            }} style={MainInputStyle}
                              colors={colors} theme={theme} />
                          </div>
                          <div className='w-3/4 my-2'>
                            <CustomTextFieldFullHeight height={25} placeholder='الحد الأقصى للأقساط' type={'number'} onChange={(e: any) => {
                              SetSupAction(prev => ({ ...prev, MAX_INSTALLMENT_AMOUNT: parseFloat(e.target.value) }))
                            }} style={MainInputStyle}
                              colors={colors} theme={theme} />
                          </div>
                          <div className='w-full flex flex-row my-5'>
                            <div className="w-1/4">
                              <p className=' text-sm'>نوع الوحده</p>
                            </div>
                            <div className="flex flex-wrap justify-between gap-2">
                              <div className='flex flex-col md:flex-row justify-between'>
                                <div className='flex  items-center gap-2'>
                                  <input type="radio" id="PersRelation" name="UnitType" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {
                                    if (e.target.checked === true) {
                                      set_all_fields_false()
                                      SetSupAction(prev => ({ ...prev, IS_PERSONAL_HOME: e.target.checked }))
                                    }
                                  }} />
                                  <label htmlFor='PersRelation' className='text-xs'>سكني</label>
                                </div>
                              </div>
                              <div className='flex flex-col md:flex-row  justify-between'>
                                <div className='flex  items-center gap-2'>
                                  <input type="radio" id="PersRelation" name="UnitType" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {
                                    if (e.target.checked === true) {
                                      set_all_fields_false()
                                      SetSupAction(prev => ({ ...prev, IS_COMMERCIAL: e.target.checked }))
                                    }
                                  }} />
                                  <label htmlFor='PersRelation' className='text-xs'>تجاري</label>
                                </div>
                              </div>
                              <div className='flex flex-col md:flex-row justify-between'>
                                <div className='flex  items-center gap-2'>
                                  <input type="radio" id="PersRelation" name="UnitType" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {
                                    if (e.target.checked === true) {
                                      set_all_fields_false()
                                      SetSupAction(prev => ({ ...prev, IS_ADMINISTRATIVE: e.target.checked }))
                                    }
                                  }} />
                                  <label htmlFor='PersRelation' className='text-xs'>إداري</label>
                                </div>
                              </div>
                              <div className='flex flex-col md:flex-row  justify-between'>
                                <div className='flex  items-center gap-2'>
                                  <input type="radio" id="PersRelation" name="UnitType" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {
                                    if (e.target.checked === true) {
                                      set_all_fields_false()
                                      SetSupAction(prev => ({ ...prev, IS_MEDICAL: e.target.checked }))
                                    }
                                  }} />
                                  <label htmlFor='PersRelation' className='text-xs'>طبي</label>
                                </div>
                              </div>
                              <div className='flex flex-col md:flex-row  justify-between'>
                                <div className='flex  items-center gap-2'>
                                  <input type="radio" id="PersRelation" name="UnitType" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {
                                    if (e.target.checked === true) {
                                      set_all_fields_false()
                                      SetSupAction(prev => ({ ...prev, IS_HOTEL: e.target.checked }))
                                    }
                                  }} />
                                  <label htmlFor='PersRelation' className='text-xs'>فندقي</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='w-full flex flex-row justify-between gap-4'>
                            <p className='text-sm w-1/3'>الغرض</p>
                            <div className='flex flex-col md:flex-row w-full gap-5'>
                              <div className='flex  items-center gap-2'>
                                <div className='flex gap-3'>
                                  <input type="checkbox" id="explain" name="explain" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {
                                    SetSupAction(prev => ({ ...prev, IS_INVESTMENT: e.target.checked }))
                                  }} />
                                  <label htmlFor='explain' className='text-xs'>إستثمار</label>
                                </div>
                              </div>
                              <div className='flex  items-center gap-2'>
                                <div className='flex gap-3'>
                                  <input type="checkbox" id="explain" name="explain" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {

                                  }} />
                                  <label htmlFor='explain' className='text-xs'>إستخدام</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="flex gap-2">
                              <DropTextField initialText="المنطقه" data={[
                                ...CitiesDropDownType
                              ]} onchange={e => {
                                SetSupAction(prev => ({ ...prev, CITY_ID: e.target.value }))
                              }} />
                            </div>
                          </div>
                        </div>
                        <textarea id="Notes" name="otherNotes" className="col-span-2 resize-none w-full h-[5em] shadow-[0px] rounded-md border-black border focus:outline-none focus:shadow-none " placeholder='ملاحظات اخرى' onChange={(e: any) => {
                          SetSupAction(prev => ({ ...prev, NOTES: e.target.value }))
                        }} >

                        </textarea>
                        <br />
                      </div>
                    )}
                    <br />
                    {ActionTypeOBJ.NEED_SECOND_MEETING && (
                      <div className="my-5">
                        <h3 className="font-bold text-lg">المواعيد المتاحه</h3>
                        <div className="flex flex-wrap w-full gap-4">
                          {AvailableReservs && AvailableReservs.map((reserv, idx) => {
                            return <div key={idx} className="flex flex-wrap items-center gap-2 w-[30%] bg-white shadow-md rounded-md p-2">
                              <div className="i-mdi-calendar-check  text-3xl text-green-400">
                              </div>
                              <div className="flex gap-2">
                                <h3 className="font-bold text-xl">{DaysNames[new Date(reserv.TIME_FROM).getDay()]}</h3>
                                <h3 className="font-bold text-xl">{getYearMonthDayFromDate(new Date(reserv.TIME_FROM))}</h3>
                              </div>
                              <h3>من <span className="font-bold text-lg">{getDateTimeOnly(reserv.TIME_FROM as Date)}</span></h3>
                              <h3>الى <span className="font-bold text-lg">{getDateTimeOnly(reserv.TIME_TO as Date)}</span></h3>
                            </div>
                          })}
                        </div>
                        <h3 className="font-bold text-lg my-10">حجز موعد جديد</h3>
                        <Attendance title="من" isDate data={(clientAttendance) => {


                          setResvREQ((prev) => ({ ...prev, TIME_FROM: clientAttendance, RESERVATION_TYPE: CallActionTypes.Presentation }))
                        }} />
                        <Attendance title="الى" initialDate={ActionTypeOBJ.USER_RESERVATION?.TIME_FROM as Date} data={(clientAttendance) => {


                          setResvREQ((prev) => ({ ...prev, TIME_TO: clientAttendance }))
                        }} />
                      </div>
                    )}
                  </div>
                </div>
              </>
            )
            }
          </div>
          <div className="min-h-[3rem] w-1/6 self-center">
            <EgButton HandleClick={sendDataToServer}>
              <h3>إرسال نتيجه الموعد</h3>
            </EgButton>
          </div>
        </div>
      )}
      {loading.FLAG === 1 && (
        <>
          <h3>{loading.ERROR}</h3>
        </>
      )}
    </div>
  )
}

export const SuitableActionModal = ({ close }: { close: () => void }) => {
  return <div className="w-[90%] h-[90vh] absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-1/2">
    <div className='h-full w-full flex justify-center items-center'>
      <div className='bg-[#EDF3F4] rounded-3xl w-full h-full flex flex-col p-10 justify-between items-center'>
        <div className='w-full flex flex-col justify-between py-5 items-center'>
          <div className="w-full">
            <SuitableContactsDate />
          </div>
          <div className="flex gap-2">
            <div className='bg-[#EDF3F4] rounded-3xl w-4/6  lg:w-3/6  flex flex-col items-center' onClick={() => {
              close()
            }}>
              إغلاق
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}







export const SuitableContactsDate = () => {
  return <>
    <div>
      <div>
        <h3 className="text-2xl">الترشيح الأول</h3>
        <div className="flex gap-5">
          <DropTextField initialText="مدينه" data={[
            { id: 0, desc: "مدينه 1" },
            { id: 1, desc: "مدينه 2" },
            { id: 2, desc: "مدينه 3" },
            { id: 3, desc: "مدينه 4" },
            { id: 4, desc: "مدينه 5" },
            { id: 5, desc: "مدينه 6" },
            { id: 6, desc: "مدينه 7" },
          ]} />
          <DropTextField initialText="شركه" data={[
            { id: 0, desc: "شركه 1" },
            { id: 1, desc: "شركه 2" },
            { id: 2, desc: "شركه 3" },
            { id: 3, desc: "شركه 4" },
            { id: 4, desc: "شركه 5" },
            { id: 5, desc: "شركه 6" },
            { id: 6, desc: "شركه 7" },
          ]} />
          <DropTextField initialText="موقع" data={[
            { id: 0, desc: "موقع 1" },
            { id: 1, desc: "موقع 2" },
            { id: 2, desc: "موقع 3" },
            { id: 3, desc: "موقع 4" },
            { id: 4, desc: "موقع 5" },
            { id: 5, desc: "موقع 6" },
            { id: 6, desc: "موقع 7" },
          ]} />
        </div>
      </div>
      <div>
        <h3 className="text-2xl">الترشيح الثاني</h3>
        <div className="flex gap-5">
          <DropTextField initialText="مدينه" data={[
            { id: 0, desc: "مدينه 1" },
            { id: 1, desc: "مدينه 2" },
            { id: 2, desc: "مدينه 3" },
            { id: 3, desc: "مدينه 4" },
            { id: 4, desc: "مدينه 5" },
            { id: 5, desc: "مدينه 6" },
            { id: 6, desc: "مدينه 7" },
          ]} />
          <DropTextField initialText="شركه" data={[
            { id: 0, desc: "شركه 1" },
            { id: 1, desc: "شركه 2" },
            { id: 2, desc: "شركه 3" },
            { id: 3, desc: "شركه 4" },
            { id: 4, desc: "شركه 5" },
            { id: 5, desc: "شركه 6" },
            { id: 6, desc: "شركه 7" },
          ]} />
          <DropTextField initialText="موقع" data={[
            { id: 0, desc: "موقع 1" },
            { id: 1, desc: "موقع 2" },
            { id: 2, desc: "موقع 3" },
            { id: 3, desc: "موقع 4" },
            { id: 4, desc: "موقع 5" },
            { id: 5, desc: "موقع 6" },
            { id: 6, desc: "موقع 7" },
          ]} />
        </div>
      </div>
      <div>
        <h3 className="text-2xl">مشروع جديد</h3>

      </div>
    </div>
  </>
}


export const DropTextField = ({ initalValue, initialText, disabled, data, onchange, classes }: { classes?: string, initalValue?: number, initialText?: string, disabled?: boolean, data: DropDownData[], onchange?: (data: any) => void }) => {
  const [val, setVal] = useState<number>(initalValue ?? -1)

  useEffect(() => {
    if (initalValue) {
      setVal(initalValue)
    }
  }, [initalValue])
  return (
    <>
      <TextField select className={classes ? classes : "w-[20em]"} disabled={disabled} value={val} required margin="none" size="small" onChange={e => {
        if (onchange) {
          onchange(e);
          setVal(parseInt(e.target.value))
        }
      }}>
        {initialText && (
          <MenuItem key={-1} value={-1}><h6 className="truncate w-full" >{initialText}</h6></MenuItem>
        )}
        {data.map((item, idx) => (
          <MenuItem key={idx} value={item.id}><h6 className="truncate w-full">{item.desc}</h6></MenuItem>
        ))}
      </TextField >
    </>
  )
}

export const Suitable: React.FC<{ ActionFlag: boolean, Title: string, isSuit: (suit: boolean) => void }> = ({ ActionFlag, Title, isSuit }) => {
  return (<>
    <h3 className='w-1/3 text-xl'>{Title}</h3>
    <div className='flex flex-row w-full gap-5'>
      <div className='flex gap-2'>
        <input type="radio" id="Suitable" checked={ActionFlag ? true : false} name="Suitable" className='w-[2em] h-[2em]' onChange={(e: any) => {
          isSuit(true)
        }} />
        <label htmlFor='Suitable' className='text-xl'>مناسب</label>
      </div>
      <div className='flex gap-2'>
        <input type="radio" id="noSuitable" checked={!ActionFlag ? true : false} name="Suitable" className='w-[2em] h-[2em]' onChange={(e: any) => {
          isSuit(false)
        }} />
        <label htmlFor='noSuitable' className='text-xl'>غير مناسب</label>
      </div>
    </div>
  </>)
}

export function Attendance({ isTime, title, isDate, data, initialDate }: { isTime?: boolean, initialDate?: Date, title: string, isDate?: boolean, data?: (data: Date) => void }) {

  const [time, setTime] = useState<any>("00:00");
  const [date, setDate] = useState<any>();



  const ConvertDateAndTime = () => {
    if (date) {
      const timeDate = new Date(date);

      timeDate.setHours(time.split(":")[0])
      timeDate.setMinutes(time.split(":")[1])

      return new Date(timeDate)
    }

    if (initialDate) {
      const timeDate = new Date(initialDate);

      timeDate.setHours(time.split(":")[0])
      timeDate.setMinutes(time.split(":")[1])

      return new Date(timeDate)
    }
    const timeDate = new Date();

    timeDate.setHours(time.split(":")[0])
    timeDate.setMinutes(time.split(":")[1])

    return new Date(timeDate)
  }

  useEffect(() => {
    if (data) {
      data(ConvertDateAndTime())
    }
  }, [date, time])


  return (<>
    <div className="flex items-center gap-2">
      <h5 className="">{title}</h5>
      <div className="flex gap-5">
        {isDate && (
          <div>
            <h6>التاريخ</h6>
            <input className="appearance-none" type="date" onChange={e => {

              setDate(e.target.value)
            }} />
          </div>
        )}
        {!isTime && (
          <div>
            <h6>الساعه</h6>
            <input className="appearance-none" type="time" onChange={e => {
              setTime(e.target.value)
            }} />
          </div>
        )}

      </div>
    </div>
  </>);
}

export const NotSuitCLient: React.FC<{ chooseId: (val: number) => void }> = ({ chooseId }) => {
  return (
    <>
      <h3 className="text-2xl">الأسباب</h3>
      <TextField select className="w-[13em] truncate" required margin="normal" size="medium" onChange={e => {
        chooseId(parseInt(e.target.value))
      }}>
        <MenuItem key={0} value={0}><h3>سن العميل غير مناسب</h3></MenuItem>
        <MenuItem key={1} value={1}><h3>وقت الشراء غير مناسب</h3></MenuItem>
        <MenuItem key={2} value={2}><h3>ميزانيه العميل</h3></MenuItem>
        <MenuItem key={3} value={3}><h3>ليس لديه نيه شراء</h3></MenuItem>
        <MenuItem key={4} value={4}><h3>أسباب أخرى</h3></MenuItem>
      </TextField >
    </>
  )
}

export const SuitableClient: React.FC = () => {
  return <>
    <h3 className="text-3xl">عميل مناسب جدا جدا و لدرجه اللقطه كمان</h3>
  </>
}

export default AppointMents