import { useEffect, useState } from 'react'
import { ChannelTypes, GetErrorAxios } from '../../../../../Globals'
import { ACTIONS, CUSTOMER_DETAILS } from '../../../../../Service/models/UserModel/Actions/ActionsModel'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../../redux/store'
import { Cities, GovAndCities } from '../../../../Rate/Rate'

const ClientWindow = ({ History }: { History: ACTIONS }) => {
  let Customer: CUSTOMER_DETAILS = History.CUSTOMER_DETAILS!
  
  const dispatch = useDispatch<AppDispatch>()
  const [Govs, setGovs] = useState<GovAndCities[]>();
  const [selectedGov, setSelectedGov] = useState<number>(-1);
  const [cities, setCities] = useState<Cities[]>();
  const [city, setSelectedCity] = useState<number>(-1);

  useEffect(() => {
    axios.get('/api/govs/getAllGovernorates').then(res =>
      setGovs(res.data)
    ).catch(err => {
      GetErrorAxios(err, dispatch)
    })
  }, [])

  const MapContactRealationShip = (ContactRealationShip: ChannelTypes): string => {
    switch (ContactRealationShip) {
      case 0:
        return "قائمه علاقات شخصيه"
      case 1:
        return "سوشيال ميديا"
      case 2:
        return "ترشيحات"
      case 3:
        return "عميل سابق"
      case 4:
        return "أخرى"
      default:
        return "غير معروف"
    }
  }


  const MapJobId = (JobId: number): string => {
    switch (JobId) {
      case 0:
        return "مهندس"
      case 1:
        return "دكتور"
      case 2:
        return "صيدلي"
      case 3:
        return "مدرس"
      case 4:
        return "محامي"
      case 5:
        return "موظف"
      case 6:
        return "رجل اعمال"
      case 7:
        return "تاجر"
      case 8:
        return "اخرى"
      default:
        return "اختر الوظيفة"
    }
  }


  const MapGovId = (GovId: number): string => {
    let SelectedGove = Govs?.find(gov => gov.ID === GovId)
    
    return SelectedGove?.GOVERNORATE_NAME ?? "غير معروف"
  }

  const MapCityId = (CityId: number): string => {
    if (Govs && Govs.length > 0) {
      for (let i = 0; i < Govs!.length; i++) {
        for (let j = 0; j < Govs![i].ALL_CITIES.length; j++) {
          if (Govs![i].ALL_CITIES[j].ID === CityId) {
            return Govs![i].ALL_CITIES[j].CITY_NAME
          }
        }
      }
    }

    return "غير معروف"
  }




  return (
    <div className='h-full'>
      {/* بيانات العميل الأساسيه */}
      <div className='flex flex-col gap-5'>
        <h2 className="text-2xl font-bold">البيانات الأساسيه</h2>
        <div className='flex flex-wrap gap-5'>
          <div className='gap-2 flex flex-col flex-wrap'>
            <h3>الإسم</h3>
            <h3 className="font-bold text-xl">{Customer.SURNAME}</h3>
          </div>
          <div className='gap-2 flex flex-col flex-wrap'>
            <h3>رقم التليفون</h3>
            <h3 className="font-bold text-xl" dir='ltr'>{Customer.MOBILE_NO}</h3>
          </div>
          <div className='gap-2 flex flex-col flex-wrap'>
            <h3>رقم التليفون الثاني</h3>
            <h3 className="font-bold text-xl" dir='ltr'>{Customer.MOBILE_NO_2 ?? "لايوجد"}</h3>
          </div>
          <div className='gap-2 flex flex-col flex-wrap'>
            <h3>النوع</h3>
            <h3 className="font-bold text-xl" dir='ltr'>{Customer.GENDER === 0 ? "ذكر" : "أنثى"}</h3>
          </div>
        </div>
      </div>
      {/* طريقه الوصول للعميل */}
      <div className='flex flex-col gap-5 mt-10'>
        <h2 className="text-2xl font-bold">طريقه الوصول للعميل</h2>
        <div className='flex flex-wrap gap-5'>
          <div className='gap-2 flex flex-col flex-wrap'>
            <h3>عن طريق</h3>
            <h3 className="font-bold text-xl">{MapContactRealationShip(Customer.CONTACT_RELATIONSHIP)}</h3>
          </div>
        </div>
      </div>
      {/* العنوان*/}
      <div className='flex flex-col gap-5 mt-10'>
        <h2 className="text-2xl font-bold">عنوان العميل</h2>
        <div className='flex flex-wrap gap-5'>
          {/* <div className='gap-2 flex flex-col flex-wrap'>
            <h3>المحافظه</h3>
            <h3 className="font-bold text-xl">{MapGovId(Customer.GOV ?? -1)}</h3>
          </div> */}
          <div className='gap-2 flex flex-col flex-wrap'>
            <h3>المدينه</h3>
            <h3 className="font-bold text-xl">{MapCityId(Customer.CITY ?? -1)}</h3>
          </div>
          <div className='gap-2 flex flex-col flex-wrap'>
            <h3>تفصايل اخرى</h3>
            <h3 className="font-bold text-xl">{Customer.ADDRESS_DETAILS ?? "لا يوجد"}</h3>
          </div>
        </div>
      </div>
      {/* الوظيف*/}
      <div className='flex flex-col gap-5 mt-10'>
        <h2 className="text-2xl font-bold">وظيفه العميل</h2>
        <div className='flex flex-wrap gap-5'>
          <div className='gap-2 flex flex-col flex-wrap'>
            <h3>الوظيفه</h3>
            <h3 className="font-bold text-xl">{MapJobId(Customer.CUSTOMER_JOB_ID)}</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientWindow