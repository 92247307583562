import React from 'react'
import { FacebookLogo, InstagramLogo, THETEAM, TwitterLogo, YoutubeLogo } from '../../../../components/Icons/Icons'

const Footer = () => {
    return (
        <div className='w-full bg-white pt-[1em]'>
            <div className='mx-4 md:mx-24  flex flex-col'>
                <div className='flex flex-row gap-4'>
                    <div className='w-[25%]'>
                        <h3 className='text-sm md:text-base mb-4 text-[#000] font-bold'>المدن</h3>
                        <h6 className='text-[#86abb7] text-sm md:text-base'>القاهره الجديده</h6>
                        <h4 className='text-[#86abb7] text-sm md:text-base'>العاصمه الإداريه</h4>
                        <h4 className='text-[#86abb7] text-sm md:text-base'>السادس من اكتوبر</h4>
                        <h4 className='text-[#86abb7] text-sm md:text-base'>الساحل الشمالي</h4>
                        <h4 className='text-[#86abb7] text-sm md:text-base'>العين السخنه</h4>
                        <h4 className='text-[#86abb7] text-sm md:text-base'> مدينه المستقبل</h4>
                        <h4 className='text-[#86abb7] text-sm md:text-base'> الشيخ زايد</h4>
                        <h4 className='text-[#86abb7] text-sm md:text-base'> هليوبليس الجديده</h4>
                    </div>
                    <div className='w-[25%]'>
                        <h3 className='text-sm md:text-base mb-4 text-[#000] font-bold'>اشهر الوحدات</h3>
                        <h4 className='text-[#86abb7] text-sm md:text-base'>القاهره الجديده</h4>
                        <h4 className='text-[#86abb7] text-sm md:text-base'>العاصمه الإداريه</h4>
                        <h4 className='text-[#86abb7] text-sm md:text-base'>السادس من اكتوبر</h4>
                        <h4 className='text-[#86abb7] text-sm md:text-base'>الساحل الشمالي</h4>
                        <h4 className='text-[#86abb7] text-sm md:text-base'>العين السخنه</h4>
                        <h4 className='text-[#86abb7] text-sm md:text-base'> مدينه المستقبل</h4>
                        <h4 className='text-[#86abb7] text-sm md:text-base'> الشيخ زايد</h4>
                        <h4 className='text-[#86abb7] text-sm md:text-base'> هليوبليس الجديده</h4>
                    </div>
                    <div className='w-[25%]'>
                        <h3 className='text-sm md:text-base mb-4 text-[#000] font-bold'>افضل المسوقين</h3>
                        <h4 className='text-[#86abb7] text-sm md:text-base'>المستخدم 1</h4>
                        <h4 className='text-[#86abb7] text-sm md:text-base'>المستخدمه 2</h4>
                        <h4 className='text-[#86abb7] text-sm md:text-base'>المستخدم 3</h4>
                    </div>
                    <div className='w-[25%]'>
                        <h3 className='text-sm md:text-base mb-4 text-[#000] font-bold'>احدث المدن</h3>
                        <h4 className='text-[#86abb7] text-sm md:text-base'>الساحل الشمالي</h4>
                        <h4 className='text-[#86abb7] text-sm md:text-base'>العين السخنه</h4>
                        <h4 className='text-[#86abb7] text-sm md:text-base'> مدينه المستقبل</h4>
                        <h4 className='text-[#86abb7] text-sm md:text-base'> الشيخ زايد</h4>
                        <h4 className='text-[#86abb7] text-sm md:text-base'> هليوبليس الجديده</h4>
                    </div>

                </div>
                <hr className='my-2'></hr>
                <div className='flex flex-row justify-between items-center'>
                    <div className='flex flex-row gap-2'>
                        <a href='https://www.facebook.com/benaa.the.team'>
                            <div className='w-[2em] h-[2em] p-1 bg-[#000] rounded-lg hover:cursor-pointer'>
                                <FacebookLogo />
                            </div>
                        </a>
                        <div className='w-[2em] h-[2em] p-1 bg-[#000] rounded-lg hover:cursor-pointer'>

                            <YoutubeLogo />
                        </div>
                        <div className='w-[2em] h-[2em] p-1 bg-[#000] rounded-lg hover:cursor-pointer'>
                            <InstagramLogo />
                        </div>
                        <div className='w-[2em] h-[2em] p-1 bg-[#000] rounded-lg hover:cursor-pointer'>
                            <TwitterLogo />
                        </div>
                    </div>
                    <div className='flex flex-row items-center gap-2' dir='ltr'>
                        <h3 className='text-sm text-[#000] font-bold'>© Copyright 2024 - TheTeam</h3>
                    </div>
                </div>
                <div>
                    <div className='flex flex-row items-center justify-center gap-2' dir='ltr'>
                        <h3 className='text-sm text-[#000] font-bold'>POWERED BY</h3>
                        <div className='w-[3em] h-[3em]'>
                            <THETEAM />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer