import { createSlice, PayloadAction, createAsyncThunk, AsyncThunkPayloadCreator } from '@reduxjs/toolkit'
import { getAllProjects, GetCompany, GetConnectionFromServer1, Login, SaveCompany, SaveProject } from '../../Service/Apis';
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingFalse, setLoadingTrue } from './LoadingSlice';
import { hideFeedBack, showFeedBack } from './Feedback';
import { saveCompanyRQ } from '../../Service/models/company/SaveCompanyRQ';
import { GetCompanyRS } from '../../Service/models/company/GetCompanyRS';
import { AppDispatch, RootState } from '../store';
import { ProjectModel } from '../../Service/models/projects/ProjectModel';
import { AxiosError } from 'axios';
import { GetError } from '../../Globals';
import { AddAction } from './ErrorsSlice';



export interface IProjectSlice {
  status: ProjectModel[],
  Error: string,
  Loading: boolean,
  Empty: ProjectModel[]
}

const initialState: IProjectSlice = {
  status: [],
  Error: "",
  Loading: false,
  Empty: []
}

export const ProjectSlice = createSlice({
  name: 'Project',
  initialState,
  reducers: {
    GetAllProjects: (state, action: PayloadAction<ProjectModel[]>) => {
      state.status = action.payload as ProjectModel[]
    },

  },
  extraReducers: (builder) => {

    // When we send a request,
    // `fetchTodos.pending` is being fired:
    builder.addCase(GetProjectsAsync.pending, (state) => {
      // At that moment,
      // we change status to `loading` 
      // and clear all the previous errors:
      // state.status = "loading";
      // state.error = null;
    });

    // When a server responses with the data,
    // `fetchTodos.fulfilled` is fired:
    builder.addCase(GetProjectsAsync.fulfilled,
      (state, { payload }) => {
        // We add all the new todos into the state
        // and change `status` back to `idle`:
        // state.list.push(...payload);
        // state.status = "idle";
        state.status = payload as ProjectModel[];
      });

    // When a server responses with an error:
    builder.addCase(GetProjectsAsync.rejected,
      (state, { payload }) => {
        // We show the error message
        // and change `status` back to `idle` again.
        // if (payload) state.error = payload.message;
        //state.Error = payload as string;

      });
  }
})

type FetchTodosError = {
  message: string;
};

export const GetProjectsAsync = createAsyncThunk<ProjectModel[] | string >(
  // The first argument is the action name:
  "Project/get",

  // The second one is a function
  // called payload creator.
  // It contains async logic of a side-effect.
  // We can perform requests here,
  // work with device API, 
  // or any other async APIs we need to.

  // The second argument, `thunkApi`, is an object
  // that contains all those fields
  // and the `rejectWithValue` function:

  // The third type-parameter is an object with:
  // `{dispatch?, state?, extra?, rejectValue?}`` fields.
  //
  // `extra` is useful when we need to pass 
  // some static data to the request function,
  // like jwt-token or HTTP-headers.
  //
  // `rejectValue` is useful when we need to type 
  // possible errors.

  async (project, thunkApi) => {

    thunkApi.dispatch(setLoadingTrue());

    return getAllProjects().then(res => {
      thunkApi.dispatch(setLoadingFalse());
      // thunkApi.dispatch(showFeedBack({ show: true, message: "تم تحديث بيانت المشاريع", status: "success" }))
      return res;
    }).catch((err) => {
      return  GetError(err,thunkApi) 
    });
  }
);

export const SaveProjectAsync = createAsyncThunk<void,ProjectModel>(
  // The first argument is the action name:
  "Project/Save",

  // The second one is a function
  // called payload creator.
  // It contains async logic of a side-effect.
  // We can perform requests here,
  // work with device API, 
  // or any other async APIs we need to.

  // The second argument, `thunkApi`, is an object
  // that contains all those fields
  // and the `rejectWithValue` function:

  // The third type-parameter is an object with:
  // `{dispatch?, state?, extra?, rejectValue?}`` fields.
  //
  // `extra` is useful when we need to pass 
  // some static data to the request function,
  // like jwt-token or HTTP-headers.
  //
  // `rejectValue` is useful when we need to type 
  // possible errors.

  async (project, thunkApi) => {

    thunkApi.dispatch(setLoadingTrue());

    return SaveProject(project).then(res => {
      thunkApi.dispatch(setLoadingFalse());
      thunkApi.dispatch(AddAction({ message: "تم", status: "success" }))
      //return "Done";
    }).catch(err => {
      return  GetError(err,thunkApi) 
      //return ErrMsg;
    });
  }
);

export const ProjectsState = (state: RootState) => state.ProjectStore;

export const { GetAllProjects } = ProjectSlice.actions

export default ProjectSlice.reducer