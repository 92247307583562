import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store';

export interface LoadingState {
  Loading  : boolean
}

const initialState: LoadingState = {
    Loading : false
}

export const LoadingSlice = createSlice({
  name: 'Loading',
  initialState,
  reducers: {
    setLoadingTrue: (state) => {
      state.Loading = true; 
    },
    setLoadingFalse: (state) => {
      state.Loading = false ;
    },
  },
})

export const LoadingState = (state: RootState) => state.loading.Loading;

export const { setLoadingTrue, setLoadingFalse} = LoadingSlice.actions
export default LoadingSlice.reducer