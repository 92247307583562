import { useDispatch } from "react-redux";
import { CashBoxBalance, TRANSACTIONS } from "../../../Service/models/TransActions_model";
import { AppDispatch } from "../../../redux/store";
import { useEffect, useRef, useState } from "react";
import { setLoadingFalse, setLoadingTrue } from "../../../redux/slices/LoadingSlice";
import { GetAllBoxTransactions, Get_Safe_Balance, axiosConfig, trans_all } from "../../../Service/Apis";
import { BoxName, Boxes, GetErrorAxios } from "../../../Globals";
import { EditLogo } from "../../../components/Icons/Icons";
import DevExtremTable from "../../../components/shared/EGDExtremeTable";
import { DropDownData, DropTextField } from "../Actions/ActionsTypesScreen/AppointMents";





const AllBoxes = () => {

    // State for box transactions
    const [boxTransactions, setBoxTransactions] = useState<TRANSACTIONS[]>([]);
    // const TransferOperations = useRef<HTMLDivElement>(null);

    // Boxes Ids 
    const [selectedBox, setSelectedBox] = useState<Boxes>(Boxes.CommissionBox)


    const box: DropDownData[] = [
        { id: Boxes.CommissionBox, desc: BoxName(Boxes.CommissionBox) },
        { id: Boxes.ExpensesBox, desc: BoxName(Boxes.ExpensesBox) },
        { id: Boxes.CompanyBox, desc: BoxName(Boxes.CompanyBox) },
        { id: Boxes.TheTeamBox, desc: BoxName(Boxes.TheTeamBox) },
        { id: Boxes.FoundersBox, desc: BoxName(Boxes.FoundersBox) },
        { id: Boxes.TaxBox, desc: BoxName(Boxes.TaxBox) },
        { id: Boxes.ZakahBox, desc: BoxName(Boxes.ZakahBox) },
    ];





    useEffect(() => {

        const fetchBoxTransactions = async (boxId: Boxes) => {
            try {
                const data = await GetAllBoxTransactions({ BOX_ID: boxId });
                setBoxTransactions(data);

            } catch (error) {
                console.error('Error :', error);
            }
        };

        fetchBoxTransactions(selectedBox);
    }, [selectedBox]);



    return (
        <>

            <div>
                <div className="flex flex-col w-[80%] gap-3 justify-between mb-5">
                    <div className="flex w-full gap-10 mb-5">
                        <div className="flex gap-2 items-center">
                            <label className="text-base w-20"> الصندوق</label>

                            <DropTextField
                                classes="bg-white w-48"
                                initalValue={selectedBox}
                                data={box}
                                onchange={(e) => {
                                    let boxId = e.target.value as Boxes
                                    setSelectedBox(boxId)
                                }}
                            />
                        </div>

                    </div>
                </div>

                <DevExtremTable ID="ID" tableData={[
                    { Caption: "رقم مرجعي", Type: 'string', Value: 'ID' },
                    { Caption: "دائن", Type: 'string', Value: 'DEPOSIT_AMT' },
                    { Caption: "مدين", Type: 'string', Value: 'WITHDRAW_AMT' },
                    { Caption: "نوع الحركه", Type: 'string', Value: 'TRANSACTION_TYPE_DESC' },
                    { Caption: "السبب", Type: 'string', Value: 'NOTES' },
                ]}
                    dataSource={boxTransactions}
                    Actions={(data) => {
                        return (
                            <div className=" flex items-center gap-4 hover:cursor-pointer  p-1">
                                <div className='flex flex-col items-center hover:text-[#e7c498]'>
                                    <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1 hover:cursor-pointer' onClick={() => {

                                    }}>
                                        <EditLogo />
                                    </div>
                                    <p className='text-sm font-bold'>تعديل</p>
                                </div>

                            </div>
                        )
                    }}
                />


            </div>
        </>
    )
}

export default AllBoxes