import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import './style.css'

import { DropDownData } from '../../screens/DashBoard/Actions/ActionsTypesScreen/AppointMents'
import { Cancelbox } from '../Icons/Icons'

type DefaultData = {
    IDS: number[]
}

type TMulitSelectDropDown = {
    OuterDropDownData: DropDownData[],
    SingleSelection?: boolean,
    ShipComponent?: JSX.Element,
    DropDownComponent?: JSX.Element,
    CheckBoxComponents?: JSX.Element,
    ExactMatch?: boolean,
    CheckBox?: boolean,
    SelectedIds: (ids: number[]) => void,
    InitialSelectedIds?: number[];
    IsImage?: boolean;
}



const MulitDD = ({ OuterDropDownData, SingleSelection, ShipComponent, DropDownComponent, ExactMatch, SelectedIds, InitialSelectedIds, IsImage }: TMulitSelectDropDown) => {
    if (ExactMatch === undefined) ExactMatch = false;
    if (SingleSelection === undefined) SingleSelection = false
    if (IsImage === undefined) IsImage = false
    // if (ShipComponent === undefined) ShipComponent = <Ship />

    //const
    const [mainData, setMainData] = useState<DropDownData[]>([])
    const [filteredData, setFilteredData] = useState<DropDownData[]>([])
    const [selectedItems, setSelectedItems] = useState<DropDownData[]>([])
    //States
    const [currentInitialValues, setCurrentInitialValues] = useState<number[]>([])
    const [returnedIDS, setReturnedIDS] = useState<number[]>([])
    //Refs 
    const MainContainer = useRef<HTMLDivElement>(null)
    const InputElement = useRef<HTMLInputElement>(null)
    const DropDownContainer = useRef<HTMLDivElement>(null)
    //Functions
    const displayDropDown = () => {
        if (InputElement.current && MainContainer.current) {
            let dropDownElement = DropDownContainer.current

            dropDownElement?.classList.add('DisplayOnMainContainerClick')
            dropDownElement?.classList.remove('HideOnMainContainerClick')
        }
    }
    const HideDropDown = () => {
        if (InputElement.current && MainContainer.current) {
            let dropDownElement = DropDownContainer.current

            dropDownElement?.classList.remove('DisplayOnMainContainerClick')
            dropDownElement?.classList.add('HideOnMainContainerClick')
        }
    }
    const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value.toLowerCase()
        let filterdVals: DropDownData[]

        if (val === "") {
            // setFilteredData(mainData)
            changeFilterDataToAllButInSelected()
            return;
        }

        if (ExactMatch === false) {
            filterdVals = mainData.filter((item, idx) => item.desc.toLowerCase().includes(val))
        } else {
            filterdVals = mainData.filter((item, idx) => item.desc.toLowerCase() === val)
        }


        setFilteredData(filterdVals)
    }
    const addToFiltteredData = (id: number) => {
        let selectedItem = mainData.find(item => item.id === id)
        if (selectedItem) {
            let filterDataHolder = filteredData
            filterDataHolder.push(selectedItem)
            filterDataHolder.sort((a, b) => a.id - b.id)
            setFilteredData(filterDataHolder)
        }
    }
    const removeFromSelectedItems = (id: number) => {
        let selecteItemsAfterRemove = selectedItems.filter(items => items.id !== id).sort((a, b) => a.id - b.id)
        setSelectedItems(selecteItemsAfterRemove)
        SelectedIds(selecteItemsAfterRemove.map(item => item.id))
        addToFiltteredData(id)
    }
    const AddSelectedItemsToResponse = (ids: number[]) => {
        SelectedIds(ids)
    }
    const removeselectedItemsToResponse = (id: number) => {
        let newSelectedIds = filteredData.filter(item => item.id !== id).map(items => items.id)
        SelectedIds(newSelectedIds)
    }
    const clearInputData = () => {

        // if (InputElement.current) {
        //     InputElement.current.value = ""
        // }
    }
    const changeFilterDataToAllButInSelected = () => {
        let allDataButSelected = mainData.filter(item => !selectedItems.includes(item))
        setFilteredData(allDataButSelected)
    }

    // Use Memo
    const ReturnedIds = useMemo(() => {
        return [...selectedItems.map((item, idx) => (item.id))]
    }, [selectedItems])

    // use Effects

    // handling MainContainer Data
    useEffect(() => {
        HideDropDown()

        if (InputElement.current && MainContainer.current && DropDownContainer.current) {
            let mainContainerElement = MainContainer.current
            let inputElement = InputElement.current

            mainContainerElement.onclick = () => {
                inputElement.focus()
                displayDropDown()
            }

            DropDownContainer.current.onmouseleave = () => {
                inputElement.blur()
                HideDropDown()
            }

        }
    }, [])

    // set main container data
    useEffect(() => {
        let sortedDropDownData = OuterDropDownData.sort((a, b) => a.id - b.id)
        setMainData(sortedDropDownData)
        setFilteredData(sortedDropDownData)
        setSelectedItems([])

        if (InitialSelectedIds !== undefined && InitialSelectedIds.length > 0) {
            setCurrentInitialValues(InitialSelectedIds)
        }

    }, [OuterDropDownData])

    useEffect(() => {
        setFilteredData(mainData);
      }, [mainData]);


    // set initialSelectedIds SelectedIDs 
    useEffect(() => {
        if (InitialSelectedIds !== undefined && InitialSelectedIds.length > 0) {
            setCurrentInitialValues(InitialSelectedIds)
        }
    }, [InitialSelectedIds])


    useEffect(() => {
        let ItemsSelected = mainData.filter(item => currentInitialValues.includes(item.id))
        // let ItemsSelected = mainData.filter(item => item.id === InitialSelectedIds)
        let RemovedFromDropDownData = mainData.filter(item => !currentInitialValues.includes(item.id))
        // let RemovedFromDropDownData = mainData.filter(item => item.id !== InitialSelectedIds)
        setSelectedItems(ItemsSelected)
        setFilteredData(RemovedFromDropDownData)
    }, [currentInitialValues])


    useEffect(() => {
        // SelectedIds([...selectedItems.map((item, idx) => (item.id))])
    }, [selectedItems])

    return (
        <div className='w-full' ref={MainContainer}>
            {/* DropDown main component */}
            <div className='bg-white border-2 border-gray-300 rounded-md p-2 flex flex-wrap gap-1 justify-start items-center relative'>
                {selectedItems && selectedItems.length > 0 && selectedItems.map((item, idx) => {
                    return <>
                        {/* {ShipComponent} */}
                        {IsImage === true && (
                            <Ship ShipItem={item} img={item.image} close={(id) => {
                                removeFromSelectedItems(id)
                            }} />
                        )}
                        {IsImage === false && (
                            <Ship ShipItem={item} close={(id) => {
                                removeFromSelectedItems(id)
                            }} />
                        )}
                    </>
                })}
                <input ref={InputElement} onChange={handleTextChange} type="text" id="chips-input" className='clearBorder bg-transparent w-20' />
                {/* Fixed DropDown Reflected to input */}
                <div ref={DropDownContainer} className='z-[1000] absolute DisplayOnMainContainerClick -bottom-[0] left-0 w-full max-h-52  bg-slate-100 rounded-lg translate-y-[102%] shadow-lg overflow-y-auto p-2'>
                    {filteredData && filteredData.length > 0 && filteredData.map((item, idx) => {
                        return <div key={item.id} onClick={() => {
                            let removedItemFromFilteredData = filteredData.filter((Filtereditems, idx) => Filtereditems.id !== item.id)
                            if (SingleSelection === true) {
                                setSelectedItems(prev => ([item]))
                                let remianData = mainData.filter(Mainitem => Mainitem.id !== item.id)
                                setFilteredData(remianData)
                                SelectedIds([item.id])
                                // AddSelectedItemsToResponse([item.id])
                            } else {
                                setSelectedItems(prev => ([...prev, item]))
                                // AddSelectedItemsToResponse([...filteredData.map(item => (item.id)),item.id])
                                setFilteredData(removedItemFromFilteredData)
                                SelectedIds([...selectedItems.map(sitem => sitem.id), item.id])
                            }

                        }} className='z-[1000] hover:cursor-pointer hover:bg-blue-500 rounded-xl p-2 hover:text-white'>
                            <p>{item.desc}</p>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default MulitDD
// export default React.memo(MulitDD)



type TShip = {
    ShipItem: DropDownData,
    close: (id: number) => void,
    img?: string
}


const Ship = ({ close, ShipItem, img }: TShip) => {

    return <>
        <div className='relative bg-blue-500 h-full rounded flex justify-center items-center px-2 py-2'>
            {(img !== undefined) && (
                <div className='w-16 h-16 rounded-md ml-3 overflow-hidden'>
                    <img src={img}  className='object-cover w-full h-full' alt='UserImg' />
                </div>
            )}
            <p className='text-white'>{ShipItem.desc}</p>
            <div className='rounded-lg hover:cursor-pointer absolute -top-2 -left-2 flex justify-center items-center' onClick={() => {
                close(ShipItem.id)
            }}>
                <div className='w-4 h-4'>
                    <Cancelbox />
                </div>
            </div>
        </div>
    </>
}