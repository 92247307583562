import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetAllUsersThunk, UsersState } from '../../../redux/slices/UserSlice';
import { AppDispatch } from '../../../redux/store';
import { GetAllSecondMeetingRequests } from '../../../Service/Apis'
import { SECOND_MEETING_REQ } from '../../../Service/models/SecondMeeting';
import ExampleWithLocalizationProvider from '../../../components/Table';

const MeetingRequest = () => {

    const [MeetingAsManager, changeMeetingAsManager] = useState<SECOND_MEETING_REQ[]>([]);
    const [MeetingAsAgent, changeMeetingAsAgent] = useState<SECOND_MEETING_REQ[]>([]);
    const [allMeeting, changeAllMeeting] = useState<SECOND_MEETING_REQ[]>([]);
    const user = useSelector(UsersState);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {

        if (user.currentUser === undefined) {
            dispatch(GetAllUsersThunk(null))
        }

        GetAllSecondMeetingRequests().then(res => {
            let AllRequestsFromAPI: SECOND_MEETING_REQ[] = res;
            let AsManagerMeetings: SECOND_MEETING_REQ[] = [];
            let AsAgentMeetings: SECOND_MEETING_REQ[] = []

            if (AllRequestsFromAPI.length > 0) {
                AllRequestsFromAPI.map((req, idx) => {
                    if (user.currentUser.ID === req.USER_ID) {
                        AsManagerMeetings.push(req)
                    } else {
                        AsAgentMeetings.push(req)
                    }
                })
            }

            changeMeetingAsManager(AsManagerMeetings)
            changeMeetingAsAgent(AsAgentMeetings)
            changeAllMeeting(AllRequestsFromAPI)


        }).catch(err => {

        })
    }, [])

    return (
        <>
            <div className='w-full min-h-[85vh] flex flex-col gap-5'>
                <ExampleWithLocalizationProvider data={allMeeting} />
            </div>
        </>
    )
}

export default MeetingRequest


const Meeting = ({ Requests, title }: { Requests: SECOND_MEETING_REQ[], title: string }) => {


    const [MeetingRequests, changeMeetingRequests] = useState<SECOND_MEETING_REQ[]>(Requests);


    useEffect(() => {
        changeMeetingRequests(Requests)
    }, [Requests])


    return <>
        <div className='flex flex-col w-full h-[5em]'>
            <div className='flex w-full flex-wrap gap-4 items-center justify-between bg-[#f0f0f0] shadow-md p-2 rounded-lg my-5  relative'>
                <h2 className='font-bold text-xl'>{title}</h2>
                <div className='absolute hover:cursor-pointer top-2 left-2 i-material-symbols-keyboard-double-arrow-up-rounded text-blue-800 text-3xl' >
                </div>
            </div>
            <div>
                {MeetingRequests && MeetingRequests.length > 0 && MeetingRequests.map((req, idx) => {
                    return <div key={req.ACTION_ID}>
                        <h1>{"test"}</h1>
                        <h1>{req.TIME_FROM.toString()}</h1>
                    </div>
                })}
            </div>
        </div>
    </>
}
