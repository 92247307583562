
import moment from "moment";
import 'moment/locale/ar';
import 'moment/locale/en-gb';
import 'moment/locale/tr';
import { momentLocalizer } from "react-big-calendar";
import './Actions.css';
// import Dialog from "material-ui/Dialog";
// import FlatButton from "material-ui/FlatButton";
// import TextField from "material-ui/TextField";
// import TimePicker from "material-ui/TimePicker";
import { Modal } from "@mui/base";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddLogo, EditLogo } from "../../../components/Icons/Icons";
import { GetErrorAxios } from "../../../Globals";
import { ContactState, GetAllContactsThunk } from "../../../redux/slices/ContactsSlice";
import { AddAction } from "../../../redux/slices/ErrorsSlice";
import { AppDispatch } from "../../../redux/store";
import { axiosConfig } from "../../../Service/Apis";
import { ContactsModel } from "../../../Service/models/ContactsModel/ContactsMOdel";
import ModalAction from "./ActionsModal";
import ContactsActionsComp from "./ContactsActionsComp";
import DevExtremTable from "../../../components/shared/EGDExtremeTable";
require("react-big-calendar/lib/css/react-big-calendar.css");

moment.locale('ar');
const localizer = momentLocalizer(moment);


const Actions = () => {
  const [openActions, setOpenActions] = useState(false);
  const handleActionModelClose = () => setOpenActions(false);
  const handleActionModelOpen = () => setOpenActions(true);

  const UsersData = useSelector(ContactState);


  const [ContactsWithActions, setContactsWithActions] = useState<ContactsModel[]>([]);

  const [currentContact, setCurrentContact] = useState<ContactsModel>();


  const dispatch = useDispatch<AppDispatch>();

  const GetAllUserContactsHasActions = "/contacts/getAllUserContactsHasActions"

  useEffect(() => {
    axios.get(`/api/${GetAllUserContactsHasActions}`, axiosConfig).then(res => {
      setContactsWithActions(res.data)
    }).catch(err => {
      dispatch(AddAction({ status: 'error', message: GetErrorAxios(err, dispatch) }))
    });
  }, [])

  return (
    <>
      {ContactsWithActions.length >= 0 && (
        // <div className="w-full">
        //   <ContactsActionsComp contacts={ContactsWithActions} openModal={(row) => {
        //     setCurrentContact(row)
        //     handleActionModelOpen();
        //   }} />
        // </div>
        <div className="w-full h-screen">
          <DevExtremTable ID="CONTACT_ID" tableData={[
            { Caption: "الإسم", Type: 'string', Value: 'NAME' },
            { Caption: "رقم التليفون", Type: 'string', Value: 'MOBILE_NO' },
            { Caption: "رقم التليفون الثاني", Type: 'string', Value: 'MOBILE_NO_2' },
            { Caption: "ملاحظات", Type: 'string', Value: 'NOTES' },
          ]}
            dataSource={ContactsWithActions}
            Actions={(data) => {
              let currentAction = data.data as ContactsModel
              return (
                <div className='flex flex-row w-full h-full gap-1 justify-around'>
                  <div className='bg-[#e2edf1] rounded shadow h-11 w-11 p-1 hover:cursor-pointer' onClick={() => {
                    setCurrentContact(currentAction)
                    handleActionModelOpen();
                  }}>
                    <EditLogo />
                  </div>
                </div>
              )
            }}
          />
        </div>
      )}
      <Modal
        open={openActions}
        onClose={handleActionModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalAction closeFeed={() => handleActionModelClose()} contact_Current={currentContact!} ActionData={[]} />
      </Modal>

    </>
  )
}

export default Actions
