import { MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomTextField } from '../../../CustomComps/Customs';
import { DaysNames, GetErrorAxios, MonthsNames } from '../../../Globals';
import { SaveUserFreeTimeThunk } from '../../../redux/slices/CalenderSlice';
import { AddAction } from '../../../redux/slices/ErrorsSlice';
import { setLoadingFalse } from '../../../redux/slices/LoadingSlice';
import { AppDispatch } from '../../../redux/store';
import { EgButton } from '../../../screens/Shared/Shared';
import { SaveUserFreeTime } from '../../../Service/Apis';
import { USER_CALENDER } from '../../../Service/models/CalenderModel/CalenderModel';
import { tokens } from '../../../theme';
import { ActionTypes } from './events';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ openFlagStatus, openFlag, closeFlag, From, To, success_data }: { success_data?: (data: USER_CALENDER) => void, openFlagStatus: boolean, openFlag: () => void, closeFlag: () => void, From: Date, To: Date }) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selectedType, setSelectedType] = useState<number>(ActionTypes.Prospect)
    const [title, setTitle] = useState<string>("");
    const dispatch = useDispatch<AppDispatch>();

    const [date, setDate] = useState<{ from: Date, To: Date }>({ from: From, To: To });
    const [hoursMins, changeHourMins] = useState<{ hour: number, mins: number }>({ hour: 0, mins: 0 });
    const [TohoursMins, changeToHourMins] = useState<{ hour: number, mins: number }>({ hour: 0, mins: 0 });


    const handleClickOpen = () => {
        openFlag();
    };

    const handleClose = () => {
        closeFlag()
    };


    const BookAppointment = () => {

        let Obj = { CALENDER_TYPE: selectedType, IS_RESERVE: false, TIME_FROM: date.from, TIME_TO: date.To, TITLE: title }

        SaveUserFreeTime({
            CALENDER_TYPE: selectedType, IS_RESERVE: false, TIME_FROM: date.from, TIME_TO: date.To, TITLE: title
        }).then(res => {
            dispatch(setLoadingFalse());
            dispatch(AddAction({ message: "تم إضافه المواعيد", status: "success" }))
            handleClose()
            if (success_data) {
                success_data({
                    CALENDER_TYPE: selectedType, IS_RESERVE: false, TIME_FROM: date.from, TIME_TO: date.To, TITLE: title
                })
            }
            return "Done"
        }).catch(err => {
            return GetErrorAxios(err, dispatch)
        });
    }

    useEffect(() => {
       
        var NewFromDate: Date = new Date(From);
        var NewToDate: Date = new Date(To);

        NewFromDate.setHours(hoursMins.hour)
        NewFromDate.setMinutes(hoursMins.mins)

        NewToDate.setHours(TohoursMins.hour)
        NewToDate.setMinutes(TohoursMins.mins)


        setDate({ from: NewFromDate, To: NewToDate })

    }, [From, To])

    useEffect(() => {
    }, [date])

    return (
        <div className=''>
            <Dialog
                open={openFlagStatus}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth='md'
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <div className='w-full h-full flex flex-col gap-10 p-5 rounded-md'>
                    <div>
                        <h4>اليوم</h4>
                        <h4>{DaysNames[From.getDay()]}  {From.getDate()} {MonthsNames[From.getMonth()]} </h4>
                    </div>
                    <div className="w-full">
                        <CustomTextField style={{ width: '100%' }} type={'text'} InputProps={{
                        }} value={title} onChange={(event) => {
                            setTitle(event.target.value)
                        }}
                            colors={colors} theme={theme} label="العنوان" />
                    </div>
                    <div className='flex justify-around gap-5'>
                        <div className="w-4/5">
                            <label htmlFor="TimeFrom">وقت البدايه</label>
                            <CustomTextField id='TimeFrom' value={`${hoursMins.hour}:${hoursMins.mins}`} style={{ width: '100%' }} type={'time'} InputProps={{

                            }}
                                //  value={`${date.from.getHours()}:${date.from.getMinutes()}`}
                                onChange={(e) => {
                                    if (e !== undefined) {
                                        let hours = e.target.value.split(":")[0] as unknown as number;
                                        changeHourMins(prev => ({ ...prev, hour: hours }))
                                        let mints = e.target.value.split(":")[1] as unknown as number;
                                        changeHourMins(prev => ({ ...prev, mins: mints }))
                                        // let newDate = new Date(From);
                                        let newDate = new Date(From);
                                        newDate.setHours(hours)
                                        newDate.setMinutes(mints)
                                        setDate(prev => ({ ...prev, from: newDate }))
                                    }
                                }}
                                colors={colors} theme={theme} label="" />
                        </div>
                        <div className="w-4/5">
                            <label htmlFor="TimeFrom">وقت النهايه</label>
                            <CustomTextField style={{ width: '100%' }} value={`${TohoursMins.hour}:${TohoursMins.mins}`} type={'time'} InputProps={{
                            }}
                                // value={`${date.To.getHours()}:${date.from.getMinutes()}`}
                                onChange={(e) => {
                                    let hours = e.target.value.split(":")[0] as unknown as number;
                                    changeToHourMins(prev => ({ ...prev, hour: hours }))
                                    let mints = e.target.value.split(":")[1] as unknown as number;
                                    changeToHourMins(prev => ({ ...prev, mins: mints }))
                                    let newDate = From;
                                    newDate.setHours(hours)
                                    newDate.setMinutes(mints)
                                    setDate(prev => ({ ...prev, To: newDate }))
                                }}
                                colors={colors} theme={theme} label="" />
                        </div>
                    </div>
                    <div className='w-full flex flex-row gap-4'>
                        <Select
                            className='w-full'
                            value={selectedType}
                            onChange={(e: SelectChangeEvent<number>) => {
                                setSelectedType(e.target.value as number)
                            }
                            }
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value={-1}>{"اختار النوع"}</MenuItem>
                            <MenuItem value={ActionTypes.Calls}>{"إتصالات"}</MenuItem>
                            <MenuItem value={ActionTypes.Closing}>{"تقفيل"}</MenuItem>
                            <MenuItem value={ActionTypes.Followup}>{"متابعه"}</MenuItem>
                            <MenuItem value={ActionTypes.PlanMeeting}>{"اجتماعات"}</MenuItem>
                            <MenuItem value={ActionTypes.Presentation}>{"عرض"}</MenuItem>
                            <MenuItem value={ActionTypes.Prospect}>{"بحث"}</MenuItem>
                            <MenuItem value={ActionTypes.VisitSite}>{"زياره موقع"}</MenuItem>
                        </Select>
                    </div>
                    <div className='flex gap-5'>
                        <EgButton HandleClick={() => {
                            handleClose()
                        }}>
                            <h5 className="text-base md:text-lg">إلغاء</h5>
                        </EgButton>
                        <EgButton HandleClick={() => {
                            BookAppointment();
                        }}>
                            <h5 className="text-base md:text-lg">حجز</h5>
                        </EgButton>
                    </div>
                </div>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleClose}>Agree</Button>
                </DialogActions> */}
            </Dialog>
        </div>
    );
}
