import HLSVideoPlayer from "../VP/Hls"

const Welcome = () => {
  return (
    <div className="App">
      {/* <h1>Welcome</h1> */}
      <HLSVideoPlayer src="/api/playlist/Welcome.m3u8" />
    </div>
  )
}

export default Welcome