import { createSlice, PayloadAction, createAsyncThunk, AsyncThunkPayloadCreator } from '@reduxjs/toolkit'
import { GetCompany, GetConnectionFromServer1, Login, SaveCompany } from '../../Service/Apis';
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingFalse, setLoadingTrue } from './LoadingSlice';
import { hideFeedBack, showFeedBack } from './Feedback';
import { saveCompanyRQ } from '../../Service/models/company/SaveCompanyRQ';
import { GetCompanyRS } from '../../Service/models/company/GetCompanyRS';
import { AppDispatch, RootState } from '../store';
import { GetError } from '../../Globals';
import { AddAction } from './ErrorsSlice';



export interface ICompanySlice {
  status:  GetCompanyRS | saveCompanyRQ,
  Error: string,
  Loading: boolean,
  Empty: string
}

const initialState: ICompanySlice = {
  status: {} as GetCompanyRS | saveCompanyRQ,
  Error: "",
  Loading: false,
  Empty: ''
}

export const CompanySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    saveCompanyReducer: (state, action: PayloadAction<saveCompanyRQ>) => {
      state.status = action.payload as saveCompanyRQ
    },
    getCompanyReducer: (state, action: PayloadAction<GetCompanyRS>) => {
      state.status = action.payload as GetCompanyRS
    },

  },
  extraReducers: (builder) => {

    // When we send a request,
    // `fetchTodos.pending` is being fired:
    builder.addCase(SaveCompanyReq.pending, (state) => {
      // At that moment,
      // we change status to `loading` 
      // and clear all the previous errors:
      // state.status = "loading";
      // state.error = null;
    });

    // When a server responses with the data,
    // `fetchTodos.fulfilled` is fired:
    builder.addCase(SaveCompanyReq.fulfilled,
      (state, { payload }) => {
        // We add all the new todos into the state
        // and change `status` back to `idle`:
        // state.list.push(...payload);
        // state.status = "idle";
        state.status = payload as saveCompanyRQ;
      });

    // When a server responses with an error:
    builder.addCase(SaveCompanyReq.rejected,
      (state, { payload }) => {
        // We show the error message
        // and change `status` back to `idle` again.
        // if (payload) state.error = payload.message;
        // state.status = "idle";

      });


    builder.addCase(getCompanyData.pending, (state) => {
      // At that moment,
      // we change status to `loading` 
      // and clear all the previous errors:
      state.status = initialState.status; 
    });

    // When a server responses with the data,
    // `fetchTodos.fulfilled` is fired:
    builder.addCase(getCompanyData.fulfilled,
      (state, { payload }) => {
        // We add all the new todos into the state
        // and change `status` back to `idle`:
        // state.list.push(...payload);
        // state.status = "idle";
        state.status = payload as GetCompanyRS;
      });

    // When a server responses with an error:
    builder.addCase(getCompanyData.rejected,
      (state, { payload }) => {
        // We show the error message
        // and change `status` back to `idle` again.
        // if (payload) state.error = payload.message;
        state.status = initialState.status;

      });


  }
})

type FetchTodosError = {
  message: string;
};

export const SaveCompanyReq = createAsyncThunk<string | saveCompanyRQ, saveCompanyRQ>(
  // The first argument is the action name:
  "Company/Save",

  // The second one is a function
  // called payload creator.
  // It contains async logic of a side-effect.
  // We can perform requests here,
  // work with device API, 
  // or any other async APIs we need to.

  // The second argument, `thunkApi`, is an object
  // that contains all those fields
  // and the `rejectWithValue` function:

  // The third type-parameter is an object with:
  // `{dispatch?, state?, extra?, rejectValue?}`` fields.
  //
  // `extra` is useful when we need to pass 
  // some static data to the request function,
  // like jwt-token or HTTP-headers.
  //
  // `rejectValue` is useful when we need to type 
  // possible errors.

  async (company, thunkApi) => {

    thunkApi.dispatch(setLoadingTrue());

    return SaveCompany(company).then(res => {
      thunkApi.dispatch(setLoadingFalse());
      thunkApi.dispatch(AddAction({ message: "تم حفظ الشركه بنجاح", status: "success" }))
      return company;
    }).catch(err => {
      return  GetError(err,thunkApi) 
    });
  }
);

export const getCompanyData = createAsyncThunk<GetCompanyRS | string>(
  // The first argument is the action name:
  "Company/get",

  // The second one is a function
  // called payload creator.
  // It contains async logic of a side-effect.
  // We can perform requests here,
  // work with device API, 
  // or any other async APIs we need to.

  // The second argument, `thunkApi`, is an object
  // that contains all those fields
  // and the `rejectWithValue` function:

  // The third type-parameter is an object with:
  // `{dispatch?, state?, extra?, rejectValue?}`` fields.
  //
  // `extra` is useful when we need to pass 
  // some static data to the request function,
  // like jwt-token or HTTP-headers.
  //
  // `rejectValue` is useful when we need to type 
  // possible errors.

  async (company, thunkApi ) => {

    thunkApi.dispatch(setLoadingTrue());

    return GetCompany().then(res => {
      thunkApi.dispatch(setLoadingFalse());
      //thunkApi.dispatch(AddAction({ message: "تم بنجاح", status: "success" }))
      return res;
    }).catch(err => {
      return  GetError(err,thunkApi) 
    });
  }
);



export const { saveCompanyReducer, getCompanyReducer } = CompanySlice.actions

export default CompanySlice.reducer