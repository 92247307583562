import {
    Column, ColumnDef, ColumnFiltersState, createColumnHelper, FilterFn, flexRender, getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel,
    getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, SortingFn, sortingFns, Table,
    useReactTable
} from '@tanstack/react-table';

import {
    compareItems, RankingInfo,
    rankItem
} from '@tanstack/match-sorter-utils';


import { useEffect, useMemo, useReducer, useState } from 'react';
import { ContactsModel } from '../../../Service/models/ContactsModel/ContactsMOdel';
import { AddLogo, DelteLogo, EditLogo, RateIcon, ReportLogo } from '../../Icons/Icons';
import { TContactFeedBack } from '../../../screens/DashBoard/FeedBack/FeedBack';

declare module '@tanstack/table-core' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>
    }
    interface FilterMeta {
        itemRank: RankingInfo
    }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
        itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
}

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
    let dir = 0

    // Only sort by rank if the column has ranking information
    if (rowA.columnFiltersMeta[columnId]) {
        dir = compareItems(
            rowA.columnFiltersMeta[columnId]?.itemRank!,
            rowB.columnFiltersMeta[columnId]?.itemRank!
        )
    }

    // Provide an alphanumeric fallback for when the item ranks are equal
    return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
}

const TableFeedBacks = ({ FeedBacks, openModal }: { FeedBacks: TContactFeedBack[], openModal: (feedback: TContactFeedBack) => void }) => {

    const rerender = useReducer(() => ({}), {})[1]

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
        []
    )
    const [globalFilter, setGlobalFilter] = useState('')

    const columnHelper = createColumnHelper<TContactFeedBack>()

    const columns = useMemo<ColumnDef<TContactFeedBack, string>[]>(
        () => [
            {
                accessorFn: row => <div className='flex justify-center items-center w-full'>
                    <div className='w-12 h-12 object-center rounded-md overflow-hidden'><img className='object-fill' alt={row.USERS?.FULL_NAME} src={row.USERS?.PROFILE_PIC_URL} /></div>
                </div>,
                id: 'AgentPic',
                header: '',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                enableColumnFilter: false
                // filterFn: 'fuzzy',
                // sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.USERS?.FULL_NAME,
                id: 'AgentUserName',
                header: 'الوكيل',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => row.CONTACT.NAME,
                id: 'ContactUserName',
                header: 'الإسم',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => `${row.CONTACT.MOBILE_NO}`,
                id: 'Mobile1',
                header: 'رقم التليفون الرئيسي',
                cell: info => (
                    // <div className="ltr-cell">{info.getValue()}</div>
                    <div style={{ direction: 'ltr' }}>{info.getValue()}</div>
                  ),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,

            },
            {
                accessorFn: row => row.CONTACT.MOBILE_NO_2 ?? "-",
                id: 'Mobile2',
                header: 'رقم التليفون الثانوي',
                cell: info => (
                    // <div className="ltr-cell">{info.getValue()}</div>
                    <div style={{ direction: 'ltr' }}>{info.getValue()}</div>
                  ),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => `${row.CONTACT.NOTES}`,
                id: 'USER_NOTE',
                header: 'ملاحظات',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                filterFn: 'fuzzy',
                sortingFn: fuzzySort,
            },
            {
                accessorFn: row => <div className='flex flex-row w-full h-full gap-1 justify-around'>
                    <div className='bg-[#e2edf1] rounded shadow  h-11 w-11 p-1 hover:cursor-pointer' onClick={() => {
                        //   if(RateFN){
                        //     RateFN(row.CONTACT_ID!)
                        //   }
                        openModal(row);
                    }}>
                        <ReportLogo />
                    </div>
                </div>,
                id: 'Actions',
                header: 'الإستبيان',
                cell: info => info.getValue(),
                footer: props => props.column.id,
                enableColumnFilter: false,
                enableSorting: false
            }
            ,
        ],
        []
    )




    const [data, setData] = useState<TContactFeedBack[]>(FeedBacks)
    const refreshData = () => { }

    useEffect(() => {
        setData(FeedBacks);
    }, [FeedBacks])


    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            globalFilter,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        // debugTable: true,
        // debugHeaders: true,
        // debugColumns: false,
    })

    useEffect(() => {
        if (table.getState().columnFilters[0]?.id === 'fullName') {
            if (table.getState().sorting[0]?.id !== 'fullName') {
                table.setSorting([{ id: 'fullName', desc: false }])
            }
        }
    }, [table.getState().columnFilters[0]?.id])

    return (
        (
            <div className="p-2">
                <div className='flex flex-row justify-between items-end'>
                    <div>
                        <DebouncedInput
                            value={globalFilter ?? ''}
                            onChange={value => setGlobalFilter(String(value))}
                            className="p-2 leading-10 text-xl shadow border border-block"
                            placeholder="البحث في جميع المحددات"
                        />
                    </div>
                    {/* <div className='h-16 w-16 hover:cursor-pointer' onClick={() => { }}>
                        <AddLogo />
                    </div> */}
                </div>
                <div className="h-2" />
                <table className="table w-full" id="table_Container">
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <th key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder ? null : (
                                                <>
                                                    <div
                                                        {...{
                                                            className: header.column.getCanSort()
                                                                ? 'cursor-pointer select-none'
                                                                : '',
                                                            onClick: header.column.getToggleSortingHandler(),
                                                        }}
                                                    >
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                        {{
                                                            asc: '🔼',
                                                            desc: '🔽',
                                                        }[header.column.getIsSorted() as string] ?? null}
                                                    </div>
                                                    {header.column.getCanFilter() ? (
                                                        <div>
                                                            <Filter column={header.column} table={table} />
                                                        </div>
                                                    ) : null}
                                                </>
                                            )}
                                        </th>
                                    )
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map(row => {
                            return (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map(cell => {
                                        return (
                                            <td key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className="h-2" />
                <div className=" w-full bg-[#EDF3F4]  justify-between flex items-center p-5">
                    <div className='w-1/5 flex flex-row justify-between'>
                        <button
                            className="border rounded p-3 shadow hover:cursor-pointer bg-[#002F3F] text-lg text-white"
                            onClick={() => table.setPageIndex(0)}
                            disabled={!table.getCanPreviousPage()}
                        >
                            {'<<'}
                        </button>
                        <button
                            className="border rounded p-3 shadow hover:cursor-pointer bg-[#002F3F] text-lg text-white"
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                        >
                            {'<'}
                        </button>
                        <button
                            className="border rounded p-3 shadow hover:cursor-pointer bg-[#002F3F] text-lg text-white"
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                        >
                            {'>'}
                        </button>
                        <button
                            className="border rounded p-3 shadow hover:cursor-pointer bg-[#002F3F] text-lg text-white"
                            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                            disabled={!table.getCanNextPage()}
                        >
                            {'>>'}
                        </button>
                    </div>
                    <span className="flex items-center gap-1">
                        <strong>
                            of {table.getPageCount()}
                        </strong>
                        <div>Page {table.getState().pagination.pageIndex + 1}</div>
                    </span>
                    <span className="flex items-center gap-1">
                        <input
                            type="number"
                            defaultValue={table.getState().pagination.pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                table.setPageIndex(page)
                            }}
                            className="border p-1 rounded w-16"
                        />
                        | Go to page:
                    </span>
                    <select
                        value={table.getState().pagination.pageSize}
                        onChange={e => {
                            table.setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
                <div>{table.getPrePaginationRowModel().rows.length} Rows</div>
                <div>
                    <button onClick={() => rerender()}>Force Rerender</button>
                </div>
                <div>
                    <button onClick={() => refreshData()}>Refresh Data</button>
                </div>
                {/* <pre>{JSON.stringify(table.getState(), null, 2)}</pre> */}
            </div>
        )
    )
}

function Filter({
    column,
    table,
}: {
    column: Column<any, unknown>
    table: Table<any>
}) {
    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id)

    const columnFilterValue = column.getFilterValue()

    const sortedUniqueValues = useMemo(
        () =>
            typeof firstValue === 'number'
                ? []
                : Array.from(column.getFacetedUniqueValues().keys()).sort(),
        [column.getFacetedUniqueValues()]
    )

    return typeof firstValue === 'number' ? (
        <div>
            <div className="flex space-x-2">
                <DebouncedInput
                    type="number"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue as [number, number])?.[0] ?? ''}
                    onChange={value =>
                        column.setFilterValue((old: [number, number]) => [value, old?.[1]])
                    }
                    placeholder={`Min ${column.getFacetedMinMaxValues()?.[0]
                        ? `(${column.getFacetedMinMaxValues()?.[0]})`
                        : ''
                        }`}
                    className="w-24 border shadow rounded"
                />
                <DebouncedInput
                    type="number"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue as [number, number])?.[1] ?? ''}
                    onChange={value =>
                        column.setFilterValue((old: [number, number]) => [old?.[0], value])
                    }
                    placeholder={`Max ${column.getFacetedMinMaxValues()?.[1]
                        ? `(${column.getFacetedMinMaxValues()?.[1]})`
                        : ''
                        }`}
                    className="w-24 border shadow rounded"
                />
            </div>
            <div className="h-1" />
        </div>
    ) : (
        <>
            <datalist id={column.id + 'list'}>
                {sortedUniqueValues.slice(0, 5000).map((value: any) => (
                    <option className='text-blue-700' value={value} key={value} />
                ))}
            </datalist>
            <DebouncedInput
                type="text"
                value={(columnFilterValue ?? '') as string}
                onChange={value => column.setFilterValue(value)}
                placeholder={`محددات البحث (${column.getFacetedUniqueValues().size})`}
                className="w-full text-center border shadow rounded"
                list={column.id + 'list'}
            />
            <div className="h-1" />
        </>
    )
}


function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <input  {...props} value={value} onChange={e => setValue(e.target.value)} />
    )
}


export default TableFeedBacks

