import { Button, Drawer, TextField, Theme, Typography, styled, useTheme } from "@mui/material";
import { tokens } from "../theme";

// const theme = useTheme();
// const colors = tokens(theme.palette.mode);

export const NewDrawer = styled(Drawer)(({ drawercolor, openval }: { drawercolor: string, openval: string }) => ({
    height: 'calc(100vh - 80px)',
    // marginLeft : '10px',
    // marginRight : '10px', 
    // borderRadius : '8px',
    backgroundColor: drawercolor,
    width: openval ? 'auto' : 'auto', //drawer width
    overflowX: 'hidden',
    "& .MuiDrawer-paper": {
        //drawer width
        backgroundColor: drawercolor,
        position: "relative", //imp

    }
}));

export const CustomTextField = styled(TextField)(({ theme, colors }: { theme: Theme, colors: any }) => ({
    '.MuiInputBase-root': {
        height: '50px',
        fontSize: '12px',
        // height: '25px',
        // padding: '20px 6px', // adjust this value as needed
        padding: '20px 6px', // adjust this value as needed
        '& focus': { outline: 'none !important', },
    },
   


    '& .MuiOutlinedInput-root': {
        '& focus': { outline: 'none !important', },
        '& fieldset': {
            //borderColor: 'transparent !important', // Remove the border color
            boxShadow: 'none !important', // Remove the box shadow
            outline: 'none !important'
        },
        '&:hover fieldset': {
           //borderColor: 'transparent !important', // Remove the border color on hover
           outline: 'none !important'
        },
        '&.Mui-focused fieldset': {
           // borderColor: 'transparent !important', // Remove the border color when focused
            outline: 'none !important'
        },
    },


    // '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    //     borderColor: `red`,
    // },

    // "&  .MuiFormHelperText-root.Mui-error": { //<--- here
    //     backgroundColor: "red",
    //     margin:0,
    //     paddingLeft: 10
    //   },



    // "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    //     border: "1px solid #484850",
    //     borderRadius: "5px 5px 0 0"
    //   },

    // '& label.Mui-focused': {
    //     color: `${colors.primary[500]}`,
    //     marginTop: '0',

    // },


    // '& .MuiOutlinedInput-root': {
    //     borderRadius: '5px',
    //     //borderColor : 'red',
    //     borderWidth : '1px',
    //     '&.Mui-focused fieldset': {
    //         borderColor: `${colors.primary[500]}`,
    //         borderRadius: '5px',

    //     }
    // },


    '.MuiFormLabel-root': {
        fontSize: '14px !important',
        marginTop: '-7px',
        '&:hover': {
            marginTop: '0%',
        }
    },

    // '& fieldset': {
    //     borderRadius: '5px',
    // },

    "& legend": {
        textAlign: "right",
        // fontSize: "12px",
    },

    '& label': {
        transformOrigin: "right !important",
        // left: "inherit !important",
        left: "unset",
        right: "1.75rem !important",
        fontSize: "24px",
        // color: "#fff",
        fontWeight: 400,
        overflow: "unset",
    },
}));

export const CustomTextFieldFullHeight = styled(TextField)(({ theme, colors,height }: { height : number,theme: Theme, colors: any }) => ({
    '.MuiInputBase-root': {
        height: `${height}px`,
        fontSize: `${height/2}px`,
        // height: '25px',
        // padding: '20px 6px', // adjust this value as needed
        padding: '20px 4px', // adjust this value as needed
        '& focus': { outline: 'none !important', },

        
    },
   
    // "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    //     border: "1px solid #484850",
    //     borderRadius: "5px 5px 0 0"
    //   },

    '& .MuiOutlinedInput-root': {
        '& focus': { outline: 'none !important', },
        '& fieldset': {
            //borderColor: 'transparent !important', // Remove the border color
            boxShadow: 'none !important', // Remove the box shadow
            outline: 'none !important'
        },
        '&:hover fieldset': {
           //borderColor: 'transparent !important', // Remove the border color on hover
           outline: 'none !important'
        },
        '&.Mui-focused fieldset': {
           // borderColor: 'transparent !important', // Remove the border color when focused
            outline: 'none !important'
        },
    },


    // '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    //     borderColor: `red`,
    // },

    // "&  .MuiFormHelperText-root.Mui-error": { //<--- here
    //     backgroundColor: "red",
    //     margin:0,
    //     paddingLeft: 10
    //   },



    // "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    //     border: "1px solid #484850",
    //     borderRadius: "5px 5px 0 0"
    //   },

    // '& label.Mui-focused': {
    //     color: `${colors.primary[500]}`,
    //     marginTop: '0',

    // },


    // '& .MuiOutlinedInput-root': {
    //     borderRadius: '5px',
    //     //borderColor : 'red',
    //     borderWidth : '1px',
    //     '&.Mui-focused fieldset': {
    //         borderColor: `${colors.primary[500]}`,
    //         borderRadius: '5px',

    //     }
    // },


    '.MuiFormLabel-root': {
        fontSize: '14px !important',
        marginTop: '-7px',
        '&:hover': {
            marginTop: '0%',
        }
    },

    // '& fieldset': {
    //     borderRadius: '5px',
    // },

    "& legend": {
        textAlign: "right",
        // fontSize: "12px",
    },

    '& label': {
        transformOrigin: "right !important",
        // left: "inherit !important",
        left: "unset",
        right: "1.75rem !important",
        fontSize: "24px",
        // color: "#fff",
        fontWeight: 400,
        overflow: "unset",
    },
}));

export const EGButton = styled(Button)(({ theme, colors }: { theme: Theme, colors: any }) => ({
    background: `${colors.primary[500]}`,
    height: '100%',
    color: 'white',
    borderRadius: '8px',
    '&:hover': {
        background: `${colors.primary[600]}`,
    }
}));

export const EGNavButton = styled(Button)(({ theme, colors }: { theme?: Theme, colors?: any }) => ({
    // background: `${colors.primary[500]}`,
    height: '80%',
    // color: '#002F3F',
    color: '#000',
    textTransform: 'none',
    //borderRadius: '8px',
    '&:hover': {
        background: `#e7c498`,
        color: '#000'
    }
}));

export const EGNavButtonRegister = styled(Button)(({ theme, colors }: { theme?: Theme, colors?: any }) => ({
    background: `#e7c498`,
    height: '80%',
    color: '#fff',
    borderRadius: '10px',
    paddingRight: '15px',
    paddingLeft: '15px',
    '&:hover': {
        background: `#000`,
        color: '#e7c498'
    }
}));

export const NavBarText = styled(Typography)(() => (
    {
        fontWeight: "bold",
        fontSize: '12px',
    }
));

export const HeaderH1Text = styled(Typography)(({ weight }: { weight?: 'normal' | 'bold' | 'lighter' | 'bolder' }) => (
    {
        fontWeight: weight ? weight : 'normal',
        fontSize: '32px',
        color: '#000',
    }
));

export const HeaderH2Text = styled(Typography)(({ weight }: { weight?: 'normal' | 'bold' | 'lighter' | 'bolder' }) => (
    {
        fontWeight: weight ? weight : 'normal',
        fontSize: '24px',
        color: '#000',
    }
));

export const HeaderH3Text = styled(Typography)(({ weight }: { weight?: 'normal' | 'bold' | 'lighter' | 'bolder' }) => (
    {
        fontWeight: weight ? weight : 'normal',
        fontSize: '18px',
        color: '#000',
    }
));
