import React from 'react';

interface SpinnerProps {
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary' | 'accent';
}

const Spinner: React.FC<SpinnerProps> = ({ size = 'medium', color = 'primary' }) => {
  const sizeClass = {
    small: 'w-4 h-4',
    medium: 'w-8 h-8',
    large: 'w-12 h-12',
  }[size];

  const colorClass = {
    primary: 'border-t-blue-500',
    secondary: 'border-t-gray-500',
    accent: 'border-t-red-500',
  }[color];

  return (
    <div
      className={`border-4  rounded-full animate-spin ${sizeClass} ${colorClass}`}
    />
  );
};

export default Spinner;
