import React, { useState, createContext, useContext } from 'react';

interface DropdownProps {
  children: React.ReactNode;
}

interface DropdownContextType {
  isOpen: boolean;
  toggle: () => void;
}

const DropdownContext = createContext<DropdownContextType | undefined>(undefined);

export const DropdownWord: React.FC<DropdownProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <DropdownContext.Provider value={{ isOpen, toggle }}>
      <div className="relative">{children}</div>
    </DropdownContext.Provider>
  );
};

interface DropdownToggleProps {
  children: React.ReactNode;
}

export const DropdownToggle: React.FC<DropdownToggleProps> = ({ children }) => {
  const context = useContext(DropdownContext);
  if (!context) throw new Error('DropdownToggle must be used within a Dropdown');

  return (
    <button
      onClick={context.toggle}
      className="flex items-center space-x-2 focus:outline-none"
    >
      <span className='underline text-gray-500'>{children}</span>
      <svg
        className={`w-4 h-4 transition-transform ${
          context.isOpen ? 'transform rotate-180' : ''
        }`}
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fillRule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </button>
  );
};

interface DropdownMenuProps {
  children: React.ReactNode;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({ children }) => {
  const context = useContext(DropdownContext);
  if (!context) throw new Error('DropdownMenu must be used within a Dropdown');

  if (!context.isOpen) return null;

  return (
    <div className="absolute p-2 w-48 bg-slate-50 rounded-md shadow-xl z-10">
      {children}
    </div>
  );
};