import { type } from "os"
import { ACTIONS, CALL_ACTION } from "../../../../../Service/models/UserModel/Actions/ActionsModel"
import { useEffect, useState } from "react";
import { getDateTimeOnly, getYearMonthDayFromDate } from "../../../../../Globals";
import { GetCitiesAPI, getAllProjects } from "../../../../../Service/Apis";
import { CityModel } from "../../../../../Service/models/CityModel/CIty";
import { useSelector } from "react-redux";
import { citiesState } from "../../../../../redux/slices/CitySlice";
import { ProjectModel } from "../../../../../Service/models/projects/ProjectModel";
import { ProjectsState } from "../../../../../redux/slices/Projects";

const CallWindow = ({ History }: { History: ACTIONS }) => {
  let InitialDataTobeDisplayed = History.CALL_ACTION;

  const [DataTobeDisplayed, setDataToBeDisplayed] = useState<CALL_ACTION>(InitialDataTobeDisplayed ?? {});
  const [city, changeCity] = useState<CityModel>({} as CityModel)
  const [project, changeProject] = useState<ProjectModel>({} as ProjectModel)
  const cityFromGlobalState = useSelector(citiesState);
  const ProjectsFromGlobalState = useSelector(ProjectsState);



  useEffect(() => {
    setDataToBeDisplayed(History.CALL_ACTION ?? {})
  }, [History])

  useEffect(() => {
    if (cityFromGlobalState.length < 1) {
      GetCitiesAPI().then((res) => {
        let Cities: CityModel[] = res;
        let City = Cities.find((city) => city.ID === DataTobeDisplayed?.REQUEST?.CITY_ID)
        changeCity(City as CityModel)
      })
    } else {
      let City = cityFromGlobalState.find((city) => city.ID === DataTobeDisplayed?.REQUEST?.CITY_ID)
      changeCity(City as CityModel)
    }

    if (ProjectsFromGlobalState.status.length < 1) {
      getAllProjects().then((res) => {
        let Projects: ProjectModel[] = res;
        let Project = Projects.find((project) => project.ID === DataTobeDisplayed?.REQUEST?.PROJECT_ID)
        changeProject(Project as ProjectModel)
      })
    } else {
      let Project = ProjectsFromGlobalState.status.find((project) => project.ID === DataTobeDisplayed?.REQUEST?.PROJECT_ID)
      changeProject(Project as ProjectModel)
    }
  }, [])

  

  return (
    <div className="w-full flex flex-col">
      <div className="flex flex-wrap gap-4 w-full min-h-full">
        <div className='gap-2  flex flex-col flex-wrap'>
          <h3>محتاج مقابله ؟</h3>
          <h3 className="font-bold text-xl">{DataTobeDisplayed?.NEED_MEETING !== undefined && DataTobeDisplayed.NEED_MEETING === true ? "نعم" : "لا"}</h3>
        </div>
        <div className='gap-2 flex flex-col flex-wrap'>
          <h3>محتاج طلب محدد ؟</h3>
          <h3 className="font-bold text-xl">{DataTobeDisplayed?.NEED_REQUEST !== undefined && DataTobeDisplayed.NEED_REQUEST === true ? "نعم" : "لا"}</h3>
        </div>
        <div className='gap-2 flex flex-col flex-wrap'>
          <h3>هل العميل مهتم ؟</h3>
          <h3 className="font-bold text-xl">{DataTobeDisplayed?.NEVER_CARES !== undefined && DataTobeDisplayed.NEVER_CARES === false ? "لا" : "نعم"}</h3>
        </div>
      </div>
      {DataTobeDisplayed?.REQUEST && (
        <div className="flex flex-col gap-2 my-4">
          <h3 className="text-xl font-bold">طلب محدد</h3>
          <div className="flex flex-wrap gap-4 w-full min-h-full">
            <div className='gap-2  flex flex-col flex-wrap'>
              <h3>المدينه</h3>
              <h3 className="font-bold text-xl">{city.CITY_NAME}</h3>
            </div>
            <div className='gap-2  flex flex-col flex-wrap'>
              <h3>المشروع</h3>
              <h3 className="font-bold text-xl">{project.DESCRIPTION}</h3>
            </div>
          </div>
          <h3 className="text-xl font-bold">بيانات المشروع الجديد </h3>
          <div className="flex flex-wrap gap-4 w-full min-h-full">
            <div className='gap-2  flex flex-col flex-wrap'>
              <h3>المشروع</h3>
              <h3 className="font-bold text-xl">{DataTobeDisplayed?.REQUEST.PROJECT_NAME}</h3>
            </div>
            <div className='gap-2  flex flex-col flex-wrap'>
              <h3>الشركه</h3>
              <h3 className="font-bold text-xl">{DataTobeDisplayed?.REQUEST.COMPANY_NAME}</h3>
            </div>
            <div className='gap-2  flex flex-col flex-wrap'>
              <h3>المنطقه</h3>
              <h3 className="font-bold text-xl">{DataTobeDisplayed?.REQUEST.LOCATION}</h3>
            </div>
          </div>
          <h3 className="text-xl font-bold">بيانات الطلب الخارجي</h3>
          <div className="flex flex-wrap gap-4 w-full min-h-full">
            <div className='gap-2  flex flex-col flex-wrap'>
              <h3>ميزانيه المقدم</h3>
              <h3 className="font-bold text-xl">{DataTobeDisplayed?.REQUEST.BUDGET}</h3>
            </div>
            <div className='gap-2  flex flex-col flex-wrap'>
              <h3>الحد الأقصى للأقساط</h3>
              <h3 className="font-bold text-xl">{DataTobeDisplayed?.REQUEST.MAX_INSTALLMENT_AMOUNT}</h3>
            </div>
            <div className='gap-2  flex flex-col'>
              <h3>نوع الوحده</h3>
              <div className="flex flex-wrap w-full min-h-full">
                <h3 className="font-bold text-xl">{DataTobeDisplayed?.REQUEST.IS_PERSONAL_HOME && DataTobeDisplayed?.REQUEST.IS_PERSONAL_HOME === true ? "سكني" : ""}</h3>
                <h3 className="font-bold text-xl">{DataTobeDisplayed?.REQUEST.IS_ADMINISTRATIVE && DataTobeDisplayed?.REQUEST.IS_ADMINISTRATIVE === true ? "اداري" : ""} </h3>
                <h3 className="font-bold text-xl">{DataTobeDisplayed?.REQUEST.IS_COMMERCIAL && DataTobeDisplayed?.REQUEST.IS_COMMERCIAL === true ? "تجاري" : ""}</h3>
                <h3 className="font-bold text-xl">{DataTobeDisplayed?.REQUEST.IS_MEDICAL && DataTobeDisplayed?.REQUEST.IS_MEDICAL === true ? "طبي" : ""}</h3>
                <h3 className="font-bold text-xl">{DataTobeDisplayed?.REQUEST.IS_HOTEL && DataTobeDisplayed?.REQUEST.IS_HOTEL === true ? "فندقي" : ""}</h3>
              </div>
            </div>
            <div className='gap-2 flex flex-col'>
              <h3>الغرض</h3>
              <div className="flex flex-wrap gap-4 w-full min-h-full">
                <h3 className="font-bold text-xl">{DataTobeDisplayed?.REQUEST.IS_INVESTMENT && DataTobeDisplayed?.REQUEST.IS_INVESTMENT === true ? "استثمار" : ""}</h3>
                {/* <h3 className="font-bold text-xl">{DataTobeDisplayed?.REQUEST.IS_PERSONAL_HOME && DataTobeDisplayed?.REQUEST.IS_PERSONAL_HOME === true ? "استخدام شخصي" : ""}</h3> */}
              </div>
            </div>
            <div className='gap-2 flex flex-col'>
              <h3>المنطقه</h3>
              <div className="flex flex-wrap gap-4 w-full min-h-full">
                <h3 className="font-bold text-xl">{city.CITY_NAME}</h3>
                {/* <h3 className="font-bold text-xl">{DataTobeDisplayed?.REQUEST.IS_PERSONAL_HOME && DataTobeDisplayed?.REQUEST.IS_PERSONAL_HOME === true ? "استخدام شخصي" : ""}</h3> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {DataTobeDisplayed?.USER_RESERVATION && (
        <div className="flex flex-col gap-2 my-4">
          <h3 className="">درجه حماس العميل</h3>
          <div className="flex flex-wrap gap-4 w-full min-h-full">
            <div className='gap-2  flex flex-col flex-wrap'>
              <h3 className="font-bold text-xl">10 / {DataTobeDisplayed?.USER_RESERVATION.CUSTOMER_ENTHUSIASM_LEVEL}</h3>
            </div>
          </div>
          <h3 className="">موعد المقابله</h3>
          <div className="flex flex-wrap gap-4 w-full min-h-full">
            <div className='gap-2  flex flex-col flex-wrap'>
              <h3>من</h3>
              <h3 className="font-bold text-xl">{getYearMonthDayFromDate(DataTobeDisplayed?.USER_RESERVATION.TIME_FROM as Date)} - {getDateTimeOnly(DataTobeDisplayed?.USER_RESERVATION.TIME_FROM as Date)}</h3>
            </div>
            <div className='gap-2  flex flex-col flex-wrap'>
              <h3>الى</h3>
              <h3 className="font-bold text-xl">{getDateTimeOnly(DataTobeDisplayed?.USER_RESERVATION.TIME_TO as Date)}</h3>
            </div>
          </div>
          <h3 className="">هدف المقابله</h3>
          <div className="flex flex-wrap gap-4 w-full min-h-full">
            <h3 className="font-bold text-xl">{DataTobeDisplayed?.USER_RESERVATION?.IS_INVESTMENT && DataTobeDisplayed?.USER_RESERVATION?.IS_INVESTMENT === true ? "إستثمار" : ""}</h3>
            <h3 className="font-bold text-xl">{DataTobeDisplayed?.USER_RESERVATION?.IS_PERSONAL_HOME && DataTobeDisplayed?.USER_RESERVATION?.IS_PERSONAL_HOME === true ? "سكني" : ""}</h3>
            <h3 className="font-bold text-xl">{DataTobeDisplayed?.USER_RESERVATION?.IS_ADMINISTRATIVE && DataTobeDisplayed?.USER_RESERVATION?.IS_ADMINISTRATIVE === true ? "اداري" : ""} </h3>
            <h3 className="font-bold text-xl">{DataTobeDisplayed?.USER_RESERVATION?.IS_COMMERCIAL && DataTobeDisplayed?.USER_RESERVATION?.IS_COMMERCIAL === true ? "تجاري" : ""}</h3>
            <h3 className="font-bold text-xl">{DataTobeDisplayed?.USER_RESERVATION?.IS_MEDICAL && DataTobeDisplayed?.USER_RESERVATION?.IS_MEDICAL === true ? "طبي" : ""}</h3>
            <h3 className="font-bold text-xl">{DataTobeDisplayed?.USER_RESERVATION?.UNKNOWN && DataTobeDisplayed?.USER_RESERVATION?.UNKNOWN === true ? "غير معلوم" : ""}</h3>
          </div>
        </div>
      )}
    </div >
  )
}

export default CallWindow