import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMemo, useRef, useState } from 'react';
//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef
} from 'material-react-table';

//Material UI Imports
import {
  Box,
  ListItemIcon,
  MenuItem
} from '@mui/material';

//Icons Imports
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { getAllTimeData, getYearMonthDayFromDate } from '../../Globals';
import { SECOND_MEETING_REQ } from '../../Service/models/SecondMeeting';



import './style.css';
//Mock Data
// import { data } from './makeData';


//Real Data

export type Employee = {
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  salary: number;
  startDate: string;
  signatureCatchPhrase: string;
  avatar: string;
};

type SCREENS = {
  ACCEPT: boolean,
  REJECT: boolean,
}

const Example = ({ data }: { data: SECOND_MEETING_REQ[] }) => {

  // const [MeetingAsManager, changeMeetingAsManager] = useState<SECOND_MEETING_REQ[]>([]);
  // const [MeetingAsAgent, changeMeetingAsAgent] = useState<SECOND_MEETING_REQ[]>([]);
  // const [allMeetings, changeallMeetings] = useState<SECOND_MEETING_REQ[]>([]);
  // const user = useSelector(UsersState);
  // const dispatch = useDispatch<AppDispatch>();

  const [selectedMeeting, changeSelectedMeeting] = useState<SECOND_MEETING_REQ | null>(null);
  const [screenView, changeScreenView] = useState<SCREENS>({ ACCEPT: false, REJECT: false });

  const changeScreenToAccept = () => {
    changeScreenView({ ACCEPT: true, REJECT: false })
  }

  const changeScreenToReject = () => {
    changeScreenView({ ACCEPT: false, REJECT: true })
  }

  // useEffect(() => {

  //   if (user.currentUser === undefined) {
  //     dispatch(GetAllUsersThunk(null))
  //   }

  //   GetAllSecondMeetingRequests().then(res => {
  //     let AllRequestsFromAPI: SECOND_MEETING_REQ[] = res;
  //     let AsManagerMeetings: SECOND_MEETING_REQ[] = [];
  //     let AsAgentMeetings: SECOND_MEETING_REQ[] = []

  //     if (AllRequestsFromAPI.length > 0) {
  //       AllRequestsFromAPI.map((req, idx) => {
  //         if (user.currentUser.ID === req.USER_ID) {
  //           AsManagerMeetings.push(req)
  //         } else {
  //           AsAgentMeetings.push(req)
  //         }
  //       })
  //     }

  //     changeMeetingAsManager(AsManagerMeetings)
  //     changeMeetingAsAgent(AsAgentMeetings)
  //     changeallMeetings(AllRequestsFromAPI)


  //   }).catch(err => {

  //   })
  // }, [])

  const ConfirmScreenRef = useRef<HTMLDivElement>(null);


  const HideData = () => {
    ConfirmScreenRef.current?.classList.add('Hide')
    ConfirmScreenRef.current?.classList.remove('Show')
  }

  const ShowData = () => {
    ConfirmScreenRef.current?.classList.add('Show')
    ConfirmScreenRef.current?.classList.remove('Hide')
  }


  const columns = useMemo<MRT_ColumnDef<SECOND_MEETING_REQ>[]>(
    () => [
      {
        accessorFn: (row) => `${row.AGENT.FULL_NAME}`, //accessorFn used to join multiple data into a single cell
        id: 'AGENT_FULL_NAME', //id is still required when using accessorFn instead of accessorKey
        header: 'إسم الوكيل',
        size: 250,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              direction: 'rtl',
            }}
          >
            <div className='w-16 h-16 rounded-[50%] overflow-hidden'>
              <img
                alt="avatar"
                height={100}
                src={row.original.AGENT.PHOTO_URL}
                loading="lazy"
                style={{ borderRadius: '50%' }}
              />
            </div>
            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        //accessorKey: 'CONTACT_NAME', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        accessorFn: (row) => `${row.CONTACT_NAME}`,
        id: 'Contact_FullName',
        // enableClickToCopy: true,
        filterVariant: 'autocomplete',
        header: 'إسم العميل',
        size: 300,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              direction: 'rtl',
            }}
          >
            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      // {
      //   //accessorKey: 'CONTACT_NAME', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
      //   accessorFn: (row) => `${getYearMonthDayFromDate(new Date(row.TIME_FROM))}`,
      //   id: 'Meeting_Time_From',
      //   // enableClickToCopy: true,
      //   filterVariant: 'autocomplete',
      //   header: ' تاريخ الإجتماع',
      //   size: 300,
      //   Cell: ({ renderedCellValue, row }) => (
      //     <Box
      //       sx={{
      //         display: 'flex',
      //         alignItems: 'center',
      //         gap: '1rem',
      //         direction: 'rtl',
      //       }}
      //     >
      //       {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
      //       <span>{getAllTimeData(row.original.TIME_FROM)}</span>
      //     </Box>
      //   ),
      // },
      // {
      //   id: "Actions", //id used to define `group` column
      //   header: 'الإجتماعات',
      //   columns: [
      //     {
      //       accessorFn: (row) => `${row.AGENT.FULL_NAME}`, //accessorFn used to join multiple data into a single cell
      //       id: 'AGENT_FULL_NAME', //id is still required when using accessorFn instead of accessorKey
      //       header: 'إسم الوكيل',
      //       size: 250,
      //       Cell: ({ renderedCellValue, row }) => (
      //         <Box
      //           sx={{
      //             display: 'flex',
      //             alignItems: 'center',
      //             gap: '1rem',
      //             direction: 'rtl',
      //           }}
      //         >
      //           <div className='w-16 h-16 rounded-[50%] overflow-hidden'>
      //             <img
      //               alt="avatar"
      //               height={100}
      //               src={row.original.AGENT.PHOTO_URL}
      //               loading="lazy"
      //               style={{ borderRadius: '50%' }}
      //             />
      //           </div>
      //           {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
      //           <span>{renderedCellValue}</span>
      //         </Box>
      //       ),
      //     },
      //     {
      //       //accessorKey: 'CONTACT_NAME', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
      //       accessorFn: (row) => `${row.CONTACT_NAME}`,
      //       id: 'Contact_FullName',
      //       // enableClickToCopy: true,
      //       filterVariant: 'autocomplete',
      //       header: 'إسم العميل',
      //       size: 300,
      //       Cell: ({ renderedCellValue, row }) => (
      //         <Box
      //           sx={{
      //             display: 'flex',
      //             alignItems: 'center',
      //             gap: '1rem',
      //             direction: 'rtl',
      //           }}
      //         >
      //           {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
      //           <span>{renderedCellValue}</span>
      //         </Box>
      //       ),
      //     },
      //     {
      //       //accessorKey: 'CONTACT_NAME', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
      //       accessorFn: (row) => `${getYearMonthDayFromDate(new Date(row.TIME_FROM))}`,
      //       id: 'Meeting_Time_From',
      //       // enableClickToCopy: true,
      //       filterVariant: 'autocomplete',
      //       header: ' تاريخ الإجتماع',
      //       size: 300,
      //       Cell: ({ renderedCellValue, row }) => (
      //         <Box
      //           sx={{
      //             display: 'flex',
      //             alignItems: 'center',
      //             gap: '1rem',
      //             direction: 'rtl',
      //           }}
      //         >
      //           {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
      //           <span>{getAllTimeData(row.original.TIME_FROM)}</span>
      //         </Box>
      //       ),
      //     },
      //   ],
      // },
      // {
      //   id: 'id',
      //   header: 'Job Info',
      //   columns: [
      //     {
      //       accessorKey: 'salary',
      //       // filterVariant: 'range', //if not using filter modes feature, use this instead of filterFn
      //       filterFn: 'between',
      //       header: 'Salary',
      //       size: 200,
      //       //custom conditional format and styling
      //       Cell: ({ cell }) => (
      //         <Box
      //           component="span"
      //           sx={(theme) => ({
      //             backgroundColor:
      //               cell.getValue<number>() < 50_000
      //                 ? theme.palette.error.dark
      //                 : cell.getValue<number>() >= 50_000 &&
      //                   cell.getValue<number>() < 75_000
      //                   ? theme.palette.warning.dark
      //                   : theme.palette.success.dark,
      //             borderRadius: '0.25rem',
      //             color: '#fff',
      //             maxWidth: '9ch',
      //             p: '0.25rem',
      //           })}
      //         >
      //           {cell.getValue<number>()?.toLocaleString?.('en-US', {
      //             style: 'currency',
      //             currency: 'USD',
      //             minimumFractionDigits: 0,
      //             maximumFractionDigits: 0,
      //           })}
      //         </Box>
      //       ),
      //     },
      //     {
      //       accessorKey: 'jobTitle', //hey a simple column for once
      //       header: 'Job Title',
      //       size: 350,
      //     },
      //     {
      //       accessorFn: (row) => new Date(row.startDate), //convert to Date for sorting and filtering
      //       id: 'startDate',
      //       header: 'Start Date',
      //       filterVariant: 'date',
      //       filterFn: 'lessThan',
      //       sortingFn: 'datetime',
      //       Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
      //       Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
      //       muiFilterTextFieldProps: {
      //         sx: {
      //           minWidth: '250px',
      //         },
      //       },
      //     },
      //   ],
      // },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: true,
    enableColumnOrdering: false,
    enableGrouping: false,
    enableColumnPinning: false,
    enableFacetedValues: true,
    enableRowActions: true,
    enableRowSelection: false,
    initialState: { showColumnFilters: true, showGlobalFilter: true },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 30],
      shape: 'rounded',
      variant: 'outlined',
    },
    // renderDetailPanel: ({ row }) => (
    //   <Box
    //     sx={{
    //       display: 'flex',
    //       justifyContent: 'space-around',
    //       alignItems: 'center',
    //     }}
    //   >
    //     <img
    //       alt="avatar"
    //       height={200}
    //       src={row.original.AGENT.PHOTO_URL}
    //       loading="lazy"
    //       style={{ borderRadius: '50%' }}
    //     />
    //     <Box sx={{ textAlign: 'center' }}>
    //       <Typography variant="h4">Signature Catch Phrase:</Typography>
    //       <Typography variant="h1">
    //         &quot;{row.original.AGENT.FULL_NAME}&quot;
    //       </Typography>
    //     </Box>
    //   </Box>
    // ),
    renderRowActionMenuItems: ({ closeMenu, row }) => [
      <MenuItem
        key={0}
        onClick={() => {
          // View profile logic...
          closeMenu();
          changeSelectedMeeting(row.original);
          changeScreenToReject()
          ShowData()
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <CancelIcon style={{ color: '#fd5c63' }} />
        </ListItemIcon>
        رفض الميعاد
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          // Send email logic...
          closeMenu();
          changeSelectedMeeting(row.original);
          changeScreenToAccept()
          ShowData()
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <CheckCircleIcon style={{ color: '#17B169' }} />
        </ListItemIcon>
        تأكيد الموعد
      </MenuItem>,
    ],
    // renderTopToolbar: ({ table }) => {
    //   const handleDeactivate = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert('deactivating ' + row.getValue('name'));
    //     });
    //   };

    //   const handleActivate = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert('activating ' + row.getValue('name'));
    //     });
    //   };

    //   const handleContact = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert('contact ' + row.getValue('name'));
    //     });
    //   };

    //   return (
    //     <Box
    //       sx={(theme) => ({
    //         backgroundColor: lighten(theme.palette.background.default, 0.05),
    //         display: 'flex',
    //         gap: '0.5rem',
    //         p: '8px',
    //         justifyContent: 'space-between',
    //       })}
    //     >
    //       <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
    //         {/* import MRT sub-components */}
    //         <MRT_GlobalFilterTextField table={table} />
    //         <MRT_ToggleFiltersButton table={table} />
    //       </Box>
    //       <Box>
    //         <Box sx={{ display: 'flex', gap: '0.5rem' }}>
    //           <Button
    //             color="error"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleDeactivate}
    //             variant="contained"
    //           >
    //             Deactivate
    //           </Button>
    //           <Button
    //             color="success"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleActivate}
    //             variant="contained"
    //           >
    //             Activate
    //           </Button>
    //           <Button
    //             color="info"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleContact}
    //             variant="contained"
    //           >
    //             Contact
    //           </Button>
    //         </Box>
    //       </Box>
    //     </Box>
    //   );
    // },
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.paper, //change default background color
    }),
    muiTableBodyRowProps: { hover: false },
    muiTableProps: {
      sx: {
        borderTop: '1px solid rgba(81, 81, 81, .5)',
        borderBottom: 'none',
        borderLeft: "none",
        borderRight: "none",

      },
    },
    muiTableHeadCellProps: {
      sx: {
        // borderTop: '1px solid rgba(81, 81, 81, .5)',
        borderBottom: '1px solid rgba(81, 81, 81, .5)',
        // border: '1px solid rgba(81, 81, 81, .5)',
        borderLeft: "none",
        borderRight: "none",
        // fontStyle: 'italic',
        fontWeight: 'bold',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderTop: '1px solid rgba(81, 81, 81, .5)',
        borderBottom: '1px solid rgba(81, 81, 81, .5)',
        borderLeft: "none",
        borderRight: "none",
      },
    },
  });

  return <div>
    <MaterialReactTable table={table} />
    <div className='p-2 w-full h-full fixed bg-transform top-0 Hide z-50 duration-500 ease-in-out' ref={ConfirmScreenRef}>
      {screenView && screenView.ACCEPT === true && (
        <div className='w-full h-full relative flex justify-center items-center'>
          <div className='relative w-1/4 h-1/4 bg-slate-50 shadow-lg rounded-2xl p-7 flex flex-col justify-between'>
            <h1 className='font-bold text-2xl'>هل انت متأكد من تأكيد الموعد مع {selectedMeeting?.CONTACT_NAME} {getAllTimeData(selectedMeeting?.TIME_FROM!)} ؟</h1>
            <div className='flex justify-around'>
              <div>
                <button className='bg-red-500 text-white px-4 py-2 rounded-lg hover:cursor-pointer w-28' onClick={() => {
                  HideData()
                }}>لا</button>
              </div>
              <div>
                <button className='bg-green-500 text-white px-4 py-2 rounded-lg hover:cursor-pointer w-28' onClick={() => {
                  HideData()
                }}>نعم</button>
              </div>
            </div>
            <div className=' absolute left-2 top-2 i-material-symbols-close-rounded text-4xl text-red-500 hover:cursor-pointer' onClick={() => {
              HideData()
            }}>
            </div>
          </div>
        </div>
      )}
      {screenView && screenView.REJECT === true && (
        <div className='w-full h-full relative flex justify-center items-center'>
          <div className='relative w-1/4 h-1/4 bg-slate-50 shadow-lg rounded-2xl p-7 flex flex-col justify-between'>
            <h1 className='font-bold text-2xl'>هل انت متأكد من إلغاء الموعد مع {selectedMeeting?.CONTACT_NAME} {getAllTimeData(selectedMeeting?.TIME_FROM!)} ؟</h1>
            <div className='flex justify-around'>
              <div>
                <button className='bg-red-500 text-white px-4 py-2 rounded-lg hover:cursor-pointer w-28' onClick={() => {
                  HideData()
                }}>لا</button>
              </div>
              <div>
                <button className='bg-green-500 text-white px-4 py-2 rounded-lg hover:cursor-pointer w-28' onClick={() => {
                  HideData()
                }}>نعم</button>
              </div>
            </div>
            <div className=' absolute left-2 top-2 i-material-symbols-close-rounded text-4xl text-red-500 hover:cursor-pointer' onClick={() => {
              HideData()
            }}>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>;
};

//Date Picker Imports - these should just be in your Context Provider


const ExampleWithLocalizationProvider = ({ data }: { data: SECOND_MEETING_REQ[] }) => (
  //App.tsx or AppProviders file
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Example data={data} />
  </LocalizationProvider>
);

export default ExampleWithLocalizationProvider;
