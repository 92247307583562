import React, { useEffect, useReducer, useState } from 'react'


import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import {
    ActionStatisticsRetunType,
    BGCOLORs,
    Border_Color,
    getStatisticsByAction, getStatisticsByAction_Optimized,
    getStatisticsByBranch,
    STA_DRAW_TYPE, Station_DATA_Optimized, USER_ACTIONS_DATA, USER_DATA_Optimized
} from "./logic";
import { initialStatisticsState, STATISTIC_FROM_API, StatisticsReducer } from "./reducers/statisticsReducer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { setLoadingFalse, setLoadingTrue } from "../../redux/slices/LoadingSlice";
import { GET_ALL_STATIONS, GET_FOUNDERS_OWNERS_WALLET, GetAllUsers, GetAllUsers_online, getStatistics } from "../../Service/Apis";
import { ActionTypes, GetActionTypeString, GetErrorAxios, IsObjectEmpty } from "../../Globals";
import { STATISTICS } from "../../Service/models/statistics/indext";
import { Attendance, DropDownData, DropTextField } from "../DashBoard/Actions/ActionsTypesScreen/AppointMents";
import DOComp from './reducers/components/PieComp';
import { USER_Detail, UserModel } from '../../Service/models/UserModel/UserModel';
import EGDate from "../../components/shared/EGDate";
import { EgButton } from "../Shared/Shared";
import { DealsWallet, OwnersFounderStats } from '../../Service/models/Wallet';

ChartJS.register(ArcElement, Tooltip, Legend);


type Chart_and_users_dataType = {
    chartData: STA_DRAW_TYPE
    user_data: USER_DATA_Optimized
}

type Chart_and_station_dataType = {
    chartData: STA_DRAW_TYPE
    station_data: Station_DATA_Optimized
}

const Wallet = () => {
    const dispatch = useDispatch<AppDispatch>()
    const [statisticsReducer, dispatchStatistics] = useReducer(StatisticsReducer, initialStatisticsState);
    const [statistics_holder, setStatisticsHolder] = useState<STATISTICS>({} as STATISTICS)
    const [Actions_DD, setActions_DD] = useState<DropDownData[]>([]);
    const [branchesDropDown, setBranchesDropDown] = useState<DropDownData[]>([]);
    const [usersDropDown, setUsersDropDown] = useState<DropDownData[]>([]);
    const [users, setUsers] = useState<USER_Detail[]>([]);
    const [selectedType, setSelectedType] = useState<ActionTypes>(0);
    const [drawData, setDrawData] = useState<STA_DRAW_TYPE>({} as STA_DRAW_TYPE);
    const [drawDataTeam, setDrawDataTeam] = useState<STA_DRAW_TYPE>({} as STA_DRAW_TYPE);
    const [draw_branch_data, set_draw_branch_data] = useState<STA_DRAW_TYPE>({} as STA_DRAW_TYPE);
    const [all_draw_data, set_all_draw_data] = useState<STA_DRAW_TYPE[]>([]);
    const [users_stat, set_all_users_stat] = useState<USER_DATA_Optimized[]>([])
    const [teamSta, setTeamSta] = useState<ActionStatisticsRetunType[]>([])
    const [branch_sta, set_baranch_sta] = useState<ActionStatisticsRetunType[]>([])
    const [stationID, setStationID] = useState<number>(-1)
    const [selectedUser, setSelectedUser] = useState<number>(-2)
    const now = new Date(); // Make sure to initialize 'now'
    const currentMonth = now.getMonth(); // getMonth() returns a value between 0 (January) and 11 (December)
    let per;
    if (currentMonth >= 0 && currentMonth <= 2) {
        per = 1; // January, February, March
    } else if (currentMonth >= 3 && currentMonth <= 5) {
        per = 2; // April, May, June
    } else {
        per = 3; // July to December
    }
    const [duration_Time, set_duration_time] = useState<number>(per)
    const [start_date, set_start_date] = useState<Date>(new Date())
    const [end_date, set_end_date] = useState<Date>(new Date())
    const [all_users_stat_data, set_all_users_stat_data] = useState<Chart_and_users_dataType[]>([])
    const [all_stations_stat_data, set_all_stations_stat_data] = useState<Chart_and_station_dataType[]>([])


    const [FO_holder, set_fo_holder] = useState<OwnersFounderStats>({
        YEAR: 0,
        DEAL_WALLET: {
            MY_WALLET: 0,
            MY_WALLET_STRING: "",
            MY_WALLET_TAFKIT: "",
            USER: {} as USER_Detail
        } as DealsWallet,
        FOUNDER_STATISTICS: [],
        OWNER_STATISTICS: []
    } as OwnersFounderStats);



    useEffect(() => {
        GET_FOUNDERS_OWNERS_WALLET({ PERIOD_ENUM: duration_Time }).then(data => {
            set_fo_holder(data);
        }).catch(err => {
            GetErrorAxios(err, dispatch)
        })
    }, [duration_Time])

    useEffect(() => {
        // أحصائيات المستخدم
        let data: USER_DATA_Optimized[] = getStatisticsByAction_Optimized(statistics_holder, selectedUser === -2 ? 'TEAM' : 'USER', stationID, selectedUser);
        let all_users_data_and_chart_data_arr: Chart_and_users_dataType[] = [];

        data.map(users => {
            let DrawData: STA_DRAW_TYPE = {
                labels: ["بيانات عميل", "مكالمه", "موعد", "زياره موقع", "تقفيل الديل (الإستماره)",],
                datasets: [{
                    label: 'عدد المرات',
                    data: [users.CUSTOMER_DETAILS, users.CALL_ACTION, users.Appointment, users.SITE_VISIT_REQ, users.DEALS],
                    backgroundColor: [BGCOLORs()[0], BGCOLORs()[1], BGCOLORs()[2], BGCOLORs()[3], BGCOLORs()[4]],
                    borderColor: [Border_Color()[0], Border_Color()[1], Border_Color()[2], Border_Color()[3], Border_Color()[4]],
                    borderWidth: 1
                }]
            }
            all_users_data_and_chart_data_arr.push({ user_data: users, chartData: DrawData })
        })

        set_all_users_stat_data(all_users_data_and_chart_data_arr)

        //احصائيات الفرع كامل
        let branchs_data: Station_DATA_Optimized[] = getStatisticsByBranch(statistics_holder, stationID)
        let all_stations_data_and_chart_data_arr: Chart_and_station_dataType[] = [];


        branchs_data.map(branch => {
            let DrawData: STA_DRAW_TYPE = {
                labels: ["بيانات عميل", "مكالمه", "موعد", "زياره موقع", "تقفيل الديل (الإستماره)",],
                datasets: [{
                    label: 'عدد المرات',
                    data: [branch.CUSTOMER_DETAILS, branch.CALL_ACTION, branch.Appointment, branch.SITE_VISIT_REQ, branch.DEALS],
                    backgroundColor: [BGCOLORs()[0], BGCOLORs()[1], BGCOLORs()[2], BGCOLORs()[3], BGCOLORs()[4]],
                    borderColor: [Border_Color()[0], Border_Color()[1], Border_Color()[2], Border_Color()[3], Border_Color()[4]],
                    borderWidth: 1
                }]
            }
            all_stations_data_and_chart_data_arr.push({ station_data: branch, chartData: DrawData })
        })
        set_all_stations_stat_data(all_stations_data_and_chart_data_arr)
    }, [Actions_DD, statistics_holder, stationID, selectedUser])


    return (
        <>
            {/*  header & filter Data*/}
            <div>
                <div
                    className={'w-5/6  bg-slate-100 rounded-xl shadow-md mx-auto mb-2 p-3 flex justify-center items-center '}>
                    <div className={'w-full'}>
                        <div className='w-full flex flex-wrap gap-5'>
                            {/* <div className="flex items-center gap-1">
                                <label className='text-base'>فرع الشركه</label>
                                <DropTextField initalValue={stationID} data={[
                                    { id: -1, desc: 'الكل' }, ...branchesDropDown
                                ]} onchange={e => {
                                    let val = e.target.value as number
                                    setStationID(val)
                                }} />
                            </div> */}
                            {/* <div className="flex  items-center gap-1">
                                <label className='text-base'>المستخدم</label>
                                <DropTextField initalValue={-2} data={[
                                    { id: -2, desc: "فريقي" }, { id: -1, desc: 'الكل' }, ...usersDropDown
                                ]} onchange={e => {
                                    let val = e.target.value as number
                                    setSelectedUser(val)
                                }} />
                            </div> */}
                            <div className="flex items-center gap-1">
                                <label className='text-base'>المده</label>
                                <DropTextField initalValue={duration_Time} data={[
                                    { id: 24, desc: 'اخر 24 ساعه' },
                                    { id: 24 * 7, desc: 'اخر 7 ايام' },
                                    { id: 24 * 30, desc: 'اخر 30 يوم' },
                                    { id: 1, desc: 'الربع الأول' },
                                    { id: 2, desc: 'الربع الثاني' },
                                    { id: 3, desc: 'الربع الثالث' },
                                    { id: 4, desc: 'الربع الرابع' },
                                    { id: 0, desc: 'مده محدده' },
                                ]} onchange={e => {
                                    let val = e.target.value as number
                                    set_duration_time(val)

                                }} />
                            </div>
                            {duration_Time === 0 && (
                                <div className={'flex flex-row gap-5'}>
                                    <p>من</p>
                                    <EGDate DateValue={(date) => {
                                        set_start_date(date)
                                    }} />
                                    <p>الى</p>
                                    <EGDate DateValue={(date) => {
                                        set_end_date(date)
                                    }} />

                                </div>
                            )}
                            <div>
                                <EgButton HandleClick={() => {
                                    dispatch(setLoadingTrue())
                                    if (duration_Time === 0) {
                                        GET_FOUNDERS_OWNERS_WALLET({
                                            START_DATE: start_date,
                                            END_DATE: end_date,
                                            PERIOD_ENUM: duration_Time
                                        }).then(res => {
                                            set_fo_holder(res)
                                            dispatch(setLoadingFalse())
                                        }).catch(err => {
                                            dispatch(setLoadingFalse())
                                            GetErrorAxios(err, dispatch)
                                        })
                                    } else {
                                        GET_FOUNDERS_OWNERS_WALLET({
                                            PERIOD_ENUM: duration_Time
                                        }).then(res => {
                                            set_fo_holder(res)
                                            dispatch(setLoadingFalse())
                                        }).catch(err => {
                                            dispatch(setLoadingFalse())
                                            GetErrorAxios(err, dispatch)
                                        })
                                    }

                                }}>
                                    <h6>عرض الإحصائيات</h6>
                                </EgButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* data itself with test */}
            <div>
                {/*the all width chart if exist*/}
                <div>

                </div>

                {((FO_holder.FOUNDER_STATISTICS !== undefined && FO_holder.FOUNDER_STATISTICS?.length > 0) || (FO_holder.OWNER_STATISTICS !== undefined && FO_holder.OWNER_STATISTICS?.length > 0) || (FO_holder.DEAL_WALLET !== undefined)) && (
                    <>
                        {FO_holder.DEAL_WALLET !== undefined && (
                            <>
                                <p className='text-2xl font-bold my-5'>مبيعاتي</p>
                                <hr className={'my-3'} />
                                <div className='w-full bg-slate-200 rounded-xl shadow-3xl p-10'>
                                    <div className='w-80 rounded-3xl shadow bg-white p-4 relative'>
                                        <div className={'rounded-full w-20 h-20 overflow-hidden'}>
                                            <img alt='Personal' src={FO_holder.DEAL_WALLET.USER?.PHOTO_URL}
                                                style={{ objectFit: 'contain' }}
                                                className='w-full h-full' />
                                        </div>
                                        <div>
                                            <p className={'text-center truncate'}>إحصائيات</p>
                                            <h5 className={'text-center truncate'} dir={'ltr'}>{FO_holder.DEAL_WALLET.USER?.FULL_NAME ?? "متسماش"}</h5>
                                            <div className={'mt-10'}>
                                                <div className={'flex gap-5'}>
                                                    <h6>اجمالي المحفظه كـ Direct</h6>
                                                    <p>{FO_holder.DEAL_WALLET.DIRECT_SALES_STRING ?? "لايوجد"}</p>
                                                </div>
                                                <hr className={'my-3'} />
                                                <div>
                                                    <h6>اجمالي المحفظه كـ Level 1</h6>
                                                    <p>{FO_holder.DEAL_WALLET.LVL1_SALES_STRING ?? "لايوجد"}</p>
                                                </div>
                                                <hr className={'my-3'} />
                                                <div>
                                                    <h6>اجمالي المحفظه كـ Level 2</h6>
                                                    <p>{FO_holder.DEAL_WALLET.LVL2_SALES_STRING ?? "لايوجد"}</p>
                                                </div>
                                                <hr className={'my-3'} />
                                                <div>
                                                    <h6>اجمالي المحفظه كـ Level 3</h6>
                                                    <p>{FO_holder.DEAL_WALLET.LVL3_SALES_STRING ?? "لايوجد"}</p>
                                                </div>
                                                <hr className={'my-3'} />
                                                <div>
                                                    <h6>اجمالي المحفظه كـ Level 4</h6>
                                                    <p>{FO_holder.DEAL_WALLET.LVL4_SALES_STRING ?? "لايوجد"}</p>
                                                </div>
                                                <hr className={'my-3'} />
                                                <div className={'flex gap-5'}>
                                                    <h6>اجمالي المحفظه</h6>
                                                    <p>{FO_holder.DEAL_WALLET.MY_WALLET_STRING}</p>
                                                </div>
                                                <hr className={'my-3'} />
                                                <div className={'flex gap-5'}>
                                                    <h6>فقط</h6>
                                                    <p>{FO_holder.DEAL_WALLET.MY_WALLET_TAFKIT}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {FO_holder.TEAM_WALLET !== undefined && (
                            <>
                                <p className='text-2xl font-bold my-5'>مبيعاتي</p>
                                <hr className={'my-3'} />
                                <div className='w-full bg-slate-200 rounded-xl shadow-3xl p-10'>
                                    <div className='w-80 rounded-3xl shadow bg-white p-4 relative'>
                                        <div className={'rounded-full w-20 h-20 overflow-hidden'}>
                                            <img alt='Personal' src={FO_holder.TEAM_WALLET.USER?.PHOTO_URL}
                                                style={{ objectFit: 'contain' }}
                                                className='w-full h-full' />
                                        </div>
                                        <div>
                                            <p className={'text-center truncate'}>إحصائيات</p>
                                            <h5 className={'text-center truncate'} dir={'ltr'}>{FO_holder.TEAM_WALLET.USER?.FULL_NAME ?? "متسماش"}</h5>
                                            <div className={'mt-10'}>
                                                <div className={'flex gap-5'}>
                                                    <h6>الرصيد المعلق</h6>
                                                    <p>{(FO_holder.TEAM_WALLET.MY_WALLET! - FO_holder.TEAM_WALLET.MY_WALLET_COLLECTED!) ?? "لايوجد"}</p>
                                                </div>
                                                <hr className={'my-3'} />
                                                <div className={'flex gap-5'}>
                                                    <h6>الرصيد المتاح</h6>
                                                    <p>{FO_holder.TEAM_WALLET.MY_WALLET_COLLECTED ?? "لايوجد"}</p>
                                                </div>
                                                <hr className={'my-3'} />
                                                <div className={'flex gap-5'}>
                                                    <h6>إجمالي محفظتي</h6>
                                                    <p>{FO_holder.TEAM_WALLET.MY_WALLET_STRING ?? "لايوجد"}</p>
                                                </div>
                                                <hr className={'my-3'} />
                                                <div className={'flex gap-5'}>
                                                    <h6>إجمالي محفظه الفريق</h6>
                                                    <p>{FO_holder.TEAM_WALLET.TOT_TEAM_STRING ?? "لايوجد"}</p>
                                                </div>
                                                <hr className={'my-3'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {FO_holder.BOX_WALLET !== undefined && (
                            <>
                                <hr className={'my-3'} />
                                <p className='text-2xl font-bold my-5'>الصناديق</p>
                                <>
                                    <div className={'w-full bg-slate-200 rounded-xl shadow-3xl grid gap-10 grid-cols-[repeat(auto-fill,minmax(250px,250px))] p-5'}>
                                        <div className='w-80 rounded-3xl shadow bg-white p-4 relative'>
                                            <div className={'rounded-full w-20 h-20 overflow-hidden'}>
                                                <img alt='Personal' src={"https://the-team.co/api/images/img?id=6"}
                                                    style={{ objectFit: 'contain' }}
                                                    className='w-full h-full' />
                                            </div>
                                            <div>
                                                <h5 className={'text-center truncate'} dir={'ltr'}>{"صندوق المصروفات"}</h5>
                                                <div className={'mt-10'}>
                                                    <hr className={'my-3'} />
                                                    <div className={'flex gap-5'}>
                                                        <h6>الرصيد المعلق</h6>
                                                        <p>{((FO_holder.BOX_WALLET.TOT_BOX ?? 0) - (FO_holder.BOX_WALLET.TOT_BOX_COLLCETED ?? 0)) ?? "لايوجد"}</p>
                                                    </div>
                                                    <div className={'flex gap-5'}>
                                                        <h6>الرصيد المتاح</h6>
                                                        <p>{FO_holder.BOX_WALLET.TOT_BOX_COLLCETED ?? "لايوجد"}</p>
                                                    </div>
                                                    <div className={'flex gap-5'}>
                                                        <h6>إجمالي الصندوق</h6>
                                                        <p>{FO_holder.BOX_WALLET.TOT_BOX_STRING ?? "لايوجد"}</p>
                                                    </div>
                                                    <hr className={'my-3'} />
                                                    <div className={'flex gap-5'}>
                                                        <hr className={'my-3'} />
                                                        <h6>فقط</h6>
                                                        <p>{FO_holder.BOX_WALLET.TOT_BOX_TAFKIT}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </>
                        )}
                        {(FO_holder.FOUNDER_STATISTICS !== undefined && FO_holder.FOUNDER_STATISTICS.length > 0) && (
                            <>
                                <p className='text-2xl font-bold mb-5'>المؤسسين</p>
                                <div className={'w-full bg-slate-200 rounded-xl shadow-3xl grid gap-10 grid-cols-[repeat(auto-fill,minmax(250px,250px))] p-5'}>
                                    {FO_holder.FOUNDER_STATISTICS !== undefined && FO_holder.FOUNDER_STATISTICS.map((founder) => {
                                        return (
                                            <>
                                                <div className='w-80 rounded-3xl shadow bg-white p-4 relative'>
                                                    <div className={'mb-5 rounded-full w-20 h-20 overflow-hidden'}>
                                                        <img alt='Personal' src={founder.USER?.PHOTO_URL}
                                                            style={{ objectFit: 'contain' }}
                                                            className='w-full h-full' />
                                                    </div>
                                                    <div>
                                                        <p className={'text-center truncate'}>إحصائيات</p>
                                                        <h5 className={'text-center truncate'} dir={'ltr'}>{founder.USER?.FULL_NAME ?? "متسماش"}</h5>
                                                        <div className={'mt-10'}>
                                                            <hr className={'my-3'} />
                                                            <div className={'flex gap-5'}>
                                                                <h6>عدد الأسهم </h6>
                                                                <p>{founder.NO_FOUNDERS ?? 0}</p>
                                                            </div>
                                                            <hr className={'my-3'} />
                                                            <div className={'flex gap-5'}>
                                                                <h6>اجمالي عدد الديلات </h6>
                                                                <p>{founder.TOTAL_DEAL_COUNT ?? 0}</p>
                                                            </div>
                                                            <hr className={'my-3'} />
                                                            <div className={'flex gap-5'}>
                                                                <h6>الرصيد المعلق</h6>
                                                                <p>{(founder.MY_WALLET ?? 0) - (founder.MY_WALLET_COLLECTED ?? 0)}</p>
                                                            </div>
                                                            <hr className={'my-3'} />
                                                            <div className={'flex gap-5'}>
                                                                <h6>الرصيد المتاح</h6>
                                                                <p>{founder.MY_WALLET_COLLECTED ?? 0}</p>
                                                            </div>
                                                            <hr className={'my-3'} />
                                                            <div className={'flex gap-5'}>
                                                                <h6>اجمالي المحفظه</h6>
                                                                <p>{founder.MY_WALLET_STRING ?? "لا يوجد"}</p>
                                                            </div>
                                                            <hr className={'my-3'} />
                                                            <div className={'flex gap-5'}>
                                                                <h6>فقط</h6>
                                                                <p>{founder.MY_WALLET_TAFKIT ?? "لايوجد"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                                <hr className={'my-3'} />
                            </>
                        )}
                        {(FO_holder.OWNER_STATISTICS !== undefined && FO_holder.OWNER_STATISTICS.length > 0) && (
                            <>
                                <p className='text-2xl font-bold my-5'>الملاك</p>
                                <div className={'w-full bg-slate-200 rounded-xl shadow-3xl grid gap-10 grid-cols-[repeat(auto-fill,minmax(250px,250px))] p-5'}>
                                    {FO_holder.OWNER_STATISTICS !== undefined && FO_holder.OWNER_STATISTICS.map((owner) => {
                                        return (
                                            <>
                                                <div className='w-80 rounded-3xl shadow bg-white p-4 relative'>
                                                    <div className={'rounded-full w-20 h-20 overflow-hidden'}>
                                                        <img alt='Personal' src={owner.USER?.PHOTO_URL}
                                                            style={{ objectFit: 'contain' }}
                                                            className='w-full h-full' />
                                                    </div>
                                                    <div>
                                                        <p className={'text-center truncate'}>إحصائيات</p>
                                                        <h5 className={'text-center truncate'} dir={'ltr'}>{owner.USER?.FULL_NAME ?? "متسماش"}</h5>
                                                        <div className={'mt-10'}>
                                                            <hr className={'my-3'} />
                                                            <div className={'flex gap-5'}>
                                                                <h6>النسبة </h6>
                                                                <p>{owner.SHARED_PREC ?? 0}</p>
                                                            </div>
                                                            <hr className={'my-3'} />
                                                            <div className={'flex gap-5'}>
                                                                <h6>اجمالي عدد الديلات </h6>
                                                                <p>{owner.TOTAL_DEAL_COUNT ?? 0}</p>
                                                            </div>
                                                            <hr className={'my-3'} />
                                                            <div className={'flex gap-5'}>
                                                                <h6>الرصيد المعلق</h6>
                                                                <p>{( owner.MY_WALLET ?? 0 )  - (owner.MY_WALLET_COLLECTED ?? 0)}</p>
                                                            </div>
                                                            <hr className={'my-3'} />
                                                            <div className={'flex gap-5'}>
                                                                <h6>الرصيد المتاح</h6>
                                                                <p>{owner.MY_WALLET_COLLECTED ?? "لا يوجد"}</p>
                                                            </div>
                                                            <hr className={'my-3'} />
                                                            <div className={'flex gap-5'}>
                                                                <h6>قيمه المحفظه</h6>
                                                                <p>{owner.MY_WALLET_STRING ?? "لايوجد"}</p>
                                                            </div>
                                                            <hr className={'my-3'} />
                                                            <div className={'flex gap-5'}>
                                                                <h6>فقط</h6>
                                                                <p>{owner.MY_WALLET_TAFKIT ?? "لا يوجد"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </>
                        )}
                    </>

                )}
            </div >
        </>
    )
}

export default Wallet
