import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ButtonAnimated, { animationType } from '../../../../components/ButtonAnimated'
import GInput from '../../../../components/GInput'
import EGDate from '../../../../components/shared/EGDate'
import { GetErrorAxios, getYearMonthDayFromDate, HavePermission, PERMISSIONS } from '../../../../Globals'
import { AppDispatch } from '../../../../redux/store'
import { savePaymentPlanForFinishing } from '../../../../Service/Apis'
import { FINISHING_PAYMENT_PLAN, FINISHING_REQUEST, SaveFinishingPaymentPlanReq } from '../../../../Service/models/Finishing'
import { UsersState } from '../../../../redux/slices/UserSlice'


type PaymentPlaneProps = {
    finishApp: FINISHING_REQUEST
}

const PaymentPlane = (Props: PaymentPlaneProps) => {

    const { finishApp } = Props
    const dispatch = useDispatch<AppDispatch>()
    const [paymentPlan, setPaymentPlan] = useState<FINISHING_PAYMENT_PLAN[]>([{ DUE_DATE: new Date(), PAID_AMOUNT: 0, FrontEndID: -1 }]);
    const [maxIDnumber, setMaxIDnumber] = useState<number>(0);
    const [registerPayment, setRegisterPayment] = useState<animationType>('IDLE')
    const [margin, setMargin] = useState<number>(0)
    const UsersData = useSelector(UsersState);

    useEffect(() => {
        if (finishApp.FINISHING_PAYMENT_PLAN) {
            // setPaymentPlan(finishApp.FINISHING_PAYMENT_PLAN.map((plan) => ({ ...plan, FrontEndID: plan.FINISHING_REQ_ID })))

        }
    }, [finishApp])


    const handlePaymendAmountChange = (plan: FINISHING_PAYMENT_PLAN, value: number | number | Date, key: keyof FINISHING_PAYMENT_PLAN) => {
        setPaymentPlan(paymentPlan.map((p) => {
            if (p.FrontEndID === plan.FrontEndID) {
                return { ...p, [key]: value }
            } else {
                return p
            }
        }))
    }


    const savePaymentPlan = () => {
        setRegisterPayment('LOADING')

        let request: SaveFinishingPaymentPlanReq = { MARGIN: margin, PAYMENT_PLANS: paymentPlan, REQUEST_ID: finishApp.ID }

        // console.log(request)
        savePaymentPlanForFinishing(request).then((res) => {
            setRegisterPayment('SUCCESS')

            setTimeout(() => {
                window.location.reload()
            }, 3000);


        }).catch((err) => {
            setRegisterPayment('ERROR')
            GetErrorAxios(err, dispatch)
        })

    }


    return (
        <div className='mb-5'>
            <p className='text-2xl font-black my-2'>الدفعات المطلوبه</p>
            <>
                <div className='flex flex-col gap-5'>
                    <div className='flex flex-wrap gap-5'>
                        {UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.SAVE_PAYMENT_PLAN, UsersData.currentUser.PERMISSIONS) && (
                            <div className='w-56'>
                                <p className='mb-2'>نسبه الإشراف</p>
                                <GInput isNumber inputChangedVal={(val) => {
                                    if (val !== '') {
                                        setMargin(parseFloat(val.toString()))
                                    }
                                }} DefaultValue={margin.toString()} />
                            </div>
                        )}
                    </div>
                    {paymentPlan.map((plan, idx) => {
                        return <>
                            {UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.SAVE_PAYMENT_PLAN, UsersData.currentUser.PERMISSIONS) && (
                                <div className='flex flex-wrap gap-10'>
                                    <div className='w-56'>
                                        <p className='mb-2'> قيمه الدفعه</p>
                                        <GInput isNumber inputChangedVal={(val) => {
                                            if (val !== '') {
                                                handlePaymendAmountChange(plan, parseFloat(val.toString()), 'INSALLMENT_AMT')
                                            }
                                        }} DefaultValue={plan.INSALLMENT_AMT?.toString()} />
                                    </div>
                                    <div className='flex'>
                                        <div className='w-56'>
                                            <p className='mb-2'>تاريخ الإستحقاق</p>
                                            <EGDate DateValue={(date) => {
                                                handlePaymendAmountChange(plan, date as Date, 'DUE_DATE')
                                            }} initialValue={plan.DUE_DATE} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    })}

                    <div className='flex gap-4 w-fit items-center  hover:cursor-pointer' onClick={() => {
                        setMaxIDnumber(maxIDnumber + 1)
                        setPaymentPlan([...paymentPlan, { FrontEndID: maxIDnumber, DUE_DATE: new Date(), PAID_AMOUNT: 0, PAYMENT_DATE: new Date() }])
                    }}>
                        {UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.SAVE_PAYMENT_PLAN, UsersData.currentUser.PERMISSIONS) && (
                            <div className='i-material-symbols-add-box-outline-rounded text-4xl text-gray-500 my-3' >
                            </div>
                        )}
                        {UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.SAVE_PAYMENT_PLAN, UsersData.currentUser.PERMISSIONS) && (
                            <h5 className='text-xl font-bold'>دفعه أخرى</h5>
                        )}
                    </div>
                    {UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.SAVE_PAYMENT_PLAN, UsersData.currentUser.PERMISSIONS) && (
                        <div className='self-end'>
                            <ButtonAnimated animate={registerPayment} onClick={() => {
                                savePaymentPlan()
                            }}>
                                <p>تسجيل الدفعات</p>
                            </ButtonAnimated>
                        </div>
                    )}
                    {/* <div className='w-full rounded shadow-md bg-slate-100 flex flex-wrap gap-16 p-4'>
                        <div className='flex flex-col gap-2'>
                            <p className='text-sm'>إجمالي التكلفه</p>
                            <h3>{finishApp.FINISHING_COST?.reduce((acc, current) => (acc + (current.TOTAL_PRICE ?? 0)), 0)} جنيه</h3>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p className='text-sm'>المدفوع حتى الآن</p>
                            <h3>{finishApp.FINISHING_PAYMENT_PLAN?.reduce((acc, item) => { return acc + (item.PAID_AMOUNT ?? 0) }, 0) ?? 0}  جنيه</h3>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p className='text-sm'>المتبقي</p>
                            <h3>{((finishApp.FINISHING_COST || []).reduce((acc, current) => (acc + (current.TOTAL_PRICE ?? 0)), 0) - (finishApp.FINISHING_PAYMENT_PLAN?.reduce((acc, item) => { return acc + (item.PAID_AMOUNT ?? 0) }, 0) ?? 0))}  جنيه</h3>
                        </div>

                    </div> */}
                    <FinishInstalmentsTable Installments={finishApp.FINISHING_PAYMENT_PLAN ?? []} />
                </div>
            </> : <>
                <div className='w-full rounded shadow-md bg-slate-100 flex flex-wrap gap-16 p-4'>
                    {/* <div className='flex flex-col gap-2'>
                            <p>إجمالي التكلفه</p>
                            <h5>{finishApp.FINISHING_COST?.reduce((acc, current) => (acc + (current.TOTAL_PRICE ?? 0)), 0)} جنيه</h5>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>المقدم</p>
                            <h5>{paymentPlan?.FIRST_DEPOSIT} جنيه</h5>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>عدد الأقساط</p>
                            <h5>{paymentPlan?.FINISHING_INSTALLS?.length} قسط</h5>
                        </div> */}
                    {/* <div className='flex flex-col gap-2'>
                            <p>تاريخ الإستحقاق</p>
                            <h5>{getYearMonthDayFromDate(paymentPlan?.DEPOSIT_DUE_DATE!, true)}</h5>
                        </div> */}
                    {/* <div className='flex flex-col gap-2'>
                            <p>تاريخ أول قسط</p>
                            <h5>{getYearMonthDayFromDate(paymentPlan?.FIRST_INSTALL_DATE!, true)}</h5>
                        </div> */}
                </div>

            </>



        </div>
    )
}

export default PaymentPlane



type FinishingAppInstalments = {
    Installments: FINISHING_PAYMENT_PLAN[]
}
const FinishInstalmentsTable = (Props: FinishingAppInstalments) => {
    const { Installments } = Props


    // useEffect(() => {
    // }, [])


    return <div className="mt-5">
        <table className="min-w-full overflow-hidden rounded-lg shadow-md border-none">
            <thead className="bg-slate-100 rounded overflow-hidden border-0">
                <tr className="border-0 shadow">
                    <th className=" font-semibold border-none">#</th>
                    <th className=" font-semibold border-y-0  border-x-2 border-gray-200">قيمه الدفعه</th>
                    <th className=" font-semibold border-y-0  border-x-2 border-gray-200">تاريخ تسجيل الدفعه</th>
                    <th className=" font-semibold border-y-0  border-x-2 border-gray-200">المدفوع</th>
                    <th className=" font-semibold border-none">تاريخ الدفع</th>
                    <th className=" font-semibold border-y-0  border-x-2 border-gray-200">المتبقي</th>
                </tr>
            </thead>
            <tbody>
                {Installments.map((install, idx) => {
                    return <>
                        <tr className="border-t border-gray-200">
                            <td className=" border-x-2 border-gray-200">{idx + 1}</td>
                            <td className=" border-x-2 border-gray-200"><span className="bg-blue-100 text-blue-800 px-2 py-1 rounded"> {install.INSALLMENT_AMT} جنيه </span></td>
                            <td className=" border-x-2 border-gray-200">{getYearMonthDayFromDate(install.DUE_DATE!, true)}</td>
                            <td className=" border-x-2 border-gray-200">
                                {install.PAID_AMOUNT! > (install.INSALLMENT_AMT! / 2) ? <span className="bg-green-100 text-green-800 px-2 py-1 rounded"> {install.PAID_AMOUNT ?? 0}</span> : <span className="bg-red-100 text-red-800 px-2 py-1 rounded"> {install.PAID_AMOUNT ?? 0} </span>
                                }
                            </td>
                            <td className=" border-x-2 border-gray-200">{install.PAYMENT_DATE !== undefined ? getYearMonthDayFromDate(install.PAYMENT_DATE, true) : "لم يتم"}</td>
                            <td className=" border-x-2 border-gray-200"><span className="bg-blue-100 text-blue-800 px-2 py-1 rounded"> {((install.INSALLMENT_AMT ?? 0) - (install.PAID_AMOUNT ?? 0))} </span></td>
                        </tr>
                    </>
                })}

            </tbody>
        </table>
    </div >
}