import React, { useEffect, useState } from 'react'
import { DEAL_MODEL } from '../../Service/models/DealApplication'
import EGDate from '../../components/shared/EGDate'
import { CustomTextField } from '../../CustomComps/Customs'
import { useTheme } from '@mui/material'
import { tokens } from '../../theme'
import { convertToBase64, EgButton } from '../Shared/Shared'
import { GetErrorAxios, getYearMonthDayFromDate } from '../../Globals'
import { AccountantActions } from '../../Service/Apis'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import { AddAction } from '../../redux/slices/ErrorsSlice'
import { setLoadingFalse, setLoadingTrue } from '../../redux/slices/LoadingSlice'
import { ImageTypeReference } from "../../Service/models/projects/ProjectModel";
import { ImageType } from "../DashBoard/Projects/projectList";


const AccountantAction = ({ deal }: { deal: DEAL_MODEL }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [allowWithdraw, setAllowWithdraw] = useState<boolean>(false);
    const [allowTransfer, setAllowTransfer] = useState<boolean>(false);
    const [dealRequest, setDealRequest] = useState<DEAL_MODEL>(deal);
    const [dealOriginal, setdealOriginal] = useState<DEAL_MODEL>(deal);
    const dispatch = useDispatch<AppDispatch>()

    const updateStateRequest = (val: any, item: keyof DEAL_MODEL) => {
        setDealRequest({ ...dealRequest, [item]: val })
    }
    const FilesMaxSize2mb = (input: any): boolean => {
        if (input.files.length > 0) {
            const fileSize = input.files[0].size; // in bytes
            const maxSizeInBytes = 60 * 1024 * 1024; // 2 MB

            if (fileSize > maxSizeInBytes) {
                alert('حجم الملف اكبر من 60 ميجا , برجاء اختيار ملف حجمه اصغر');
                input.value = ''; // Clear the file input
                return false
            }
        }
        return true
    }

    const handleAcountAction = () => {
        dispatch(setLoadingTrue());
        AccountantActions(dealRequest).then((res) => {
            setdealOriginal(res);
            dispatch(setLoadingFalse());
            dispatch(AddAction({ message: 'تم الحفظ بنجاح', status: 'success' }))
            if (res.GOT_CHECK !== undefined && res.GOT_CHECK === true) {
                setAllowWithdraw(true)
            }

            if (res.CHECK_HAS_DEPOSITED !== undefined && res.CHECK_HAS_DEPOSITED === true) {
                setAllowTransfer(true)
            }
        }).catch((err) => {
            dispatch(setLoadingFalse());
            GetErrorAxios(err, dispatch)

        })
    }

    const onFileAdded = async (e: any) => {
        const files = e.target.files;

        if (FilesMaxSize2mb(e.target)) {
            for (const file of files) { // Use for...of to iterate over FileList
                

                let data = await convertToBase64(file) as string;
                setDealRequest({ ...dealRequest, REQUEST_PDF_BASE64: data, REQUEST_PDF_FILE_NAME: file.name });
            }
        }

    }

    useEffect(() => {
        setDealRequest(deal);
        setdealOriginal(deal);
        

        if (deal.GOT_CHECK !== undefined && deal.GOT_CHECK) {
            setAllowWithdraw(true)
        }

        if (deal.CHECK_HAS_DEPOSITED !== undefined && deal.CHECK_HAS_DEPOSITED) {
            setAllowTransfer(true)
        }
    }, [deal])



    return (
        <div className='flex flex-col'>
            <div className='grid grid-cols-4 gap-4 p-10'>
                <div className='bg-white rounded-3xl p-5 shadow-xl'>
                    <h3 className='mb-5'>مطالبه الشيك</h3>
                    <div className='flex flex-col justify-between'>
                        <div>

                            {dealOriginal.SENT_REQUEST_TO_DEVELOPER === true ? <h6 className='text-green-400'>تم </h6> :
                                <input type="checkbox" id="Require" name="Require" className='w-[1.5em] h-[1.5em]'
                                    onChange={(e: any) => {
                                        
                                        updateStateRequest(e.target.checked, 'SENT_REQUEST_TO_DEVELOPER');
                                    }} />
                            }
                            <label htmlFor='Require' className='text-base font-bold mr-4'>تم المطالبه ؟</label>
                        </div>
                        <div className='justify-between flex flex-col gap-2 flex-wrap mt-5'>
                            {(dealOriginal.SENT_REQUEST_NOTES !== undefined || dealOriginal.SENT_REQUEST_NOTES !== null) ?
                                <h6>{dealOriginal.SENT_REQUEST_NOTES}</h6> :
                                <CustomTextField style={{ width: '100%' }} type={'text'} InputProps={{}}
                                    onChange={(event) => {
                                        updateStateRequest(event.target.value, 'CHECK_DEPOSIT_NOTES');
                                    }}
                                    colors={colors} theme={theme} label="ملاحظات" />
                            }
                        </div>
                        <div className='justify-between flex flex-col gap-2 flex-wrap mt-5'>
                            <h6 className='font-bold'>تاريخ الإستلام</h6>
                            {(dealOriginal.REQ_STAMP_DATE !== undefined && dealOriginal.REQ_STAMP_DATE !== null) ?
                                <h6>{getYearMonthDayFromDate(dealOriginal.REQ_STAMP_DATE)}</h6> :
                                <EGDate DateValue={(dateVal) => {
                                    
                                    updateStateRequest(new Date(dateVal), 'CHECK_DATE');
                                }}
                                />
                            }
                        </div>
                    </div>
                    {(dealOriginal.REQUEST_PDF_FILE_NAME !== undefined && dealOriginal.REQUEST_PDF_FILE_NAME !== null) ? <>
                        <p className='mt-2'>إسم الملف</p>
                        <p>{dealRequest.REQUEST_PDF_FILE_NAME ?? "لم يتم اختيار ملف"}</p>
                        <div dir="rtl" className='w-4/5 text-right'>
                            <a href={dealOriginal.REQUEST_PDF_URL}>
                                <h6 className='truncate' >تحميل</h6>
                            </a>
                        </div>
                    </> : <>
                        <label htmlFor="OtherFiles">
                            <div className='grow h-10 border-[2px] border-gray-600 border-dashed mt-10 rounded-lg flex justify-center items-center gap-4 hover:cursor-pointer'>
                                <div
                                    className='i-material-symbols-add-a-photo-outline-rounded text-xl text-gray-500 '>
                                </div>
                                <input style={{ visibility: 'hidden', display: 'none' }} type="file" accept="*/*" id="OtherFiles"
                                    onChange={onFileAdded} />
                                <p>إضافه ملفات اخرى</p>
                            </div>
                        </label>
                        <p className='mt-2'>إسم الملف</p>
                        <p>{dealRequest.REQUEST_PDF_FILE_NAME ?? "لم يتم الإختيار بعد"}</p>
                    </>}

                </div>
                <div className='bg-white rounded-3xl p-5 shadow-xl'>
                    <h3 className='mb-5'>إستلام الشيك</h3>
                    <div className='flex flex-col justify-between'>
                        <div>

                            {dealOriginal.GOT_CHECK === true ? <h6 className='text-green-400'>تم </h6> :
                                <input type="checkbox" id="ReceiveCheck" name="ReceiveCheck"
                                    className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {
                                        
                                        updateStateRequest(e.target.checked, 'GOT_CHECK');
                                    }} />
                            }
                            <label htmlFor='ReceiveCheck' className='text-base font-bold mr-4'>تم الإستلام ؟</label>
                        </div>
                        <div className='justify-between flex flex-col gap-2 flex-wrap mt-5' >
                            <h6 className='font-bold'>تاريخ الإستلام</h6>
                            {(dealOriginal.CHECK_DATE !== undefined && dealOriginal.CHECK_DATE !== null) ? <h6>{getYearMonthDayFromDate(dealOriginal.CHECK_DATE)}</h6> :
                                <EGDate DateValue={(dateVal) => {
                                    
                                    updateStateRequest(new Date(dateVal), 'CHECK_DATE');
                                }}
                                />
                            }
                        </div>
                    </div>
                </div>
                {/* <div className='bg-white rounded-3xl p-5 shadow-xl'>
                    <h3 className='mb-5'>صرف الشيك</h3>
                    {allowWithdraw === false ? <h6 className='text-red-400'>لايمكن صرف الشيك قبل إستلامه</h6> :
                        <div>
                            <div>

                                {dealOriginal.CHECK_HAS_DEPOSITED === true ? <h6 className='text-green-400'>تم </h6> :
                                    <input type="checkbox" id="ReceiveCheck" name="ReceiveCheck" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {
                                        updateStateRequest(e.target.checked, 'CHECK_HAS_DEPOSITED');
                                    }} />
                                }
                                <label htmlFor='ReceiveCheck' className='text-base font-bold mr-4'>تم الصرف ؟</label>

                            </div>
                            <div className='justify-between flex flex-col gap-2 flex-wrap mt-5' >
                                <h6 className='font-bold'>تاريخ الصرف</h6>
                                {(dealOriginal.CHECK_DEPOSIT_DATE !== undefined && dealOriginal.CHECK_DEPOSIT_DATE !== null) ? <h6>{getYearMonthDayFromDate(dealOriginal.CHECK_DEPOSIT_DATE)}</h6> :
                                    <EGDate DateValue={(dateVal) => {
                                        updateStateRequest(new Date(dateVal), 'CHECK_DEPOSIT_DATE');
                                    }}
                                    />
                                }
                            </div>
                            <div className='mt-5'>
                                {(dealOriginal.CHECK_DEPOSIT_NOTES !== undefined || dealOriginal.CHECK_DEPOSIT_NOTES !== null) ? <h6>{dealOriginal.CHECK_DEPOSIT_NOTES}</h6> :
                                    <CustomTextField style={{ width: '100%' }} type={'text'} InputProps={{
                                    }} onChange={(event) => {
                                        updateStateRequest(event.target.value, 'CHECK_DEPOSIT_NOTES');
                                    }}
                                        colors={colors} theme={theme} label="ملاحظات" />
                                }
                            </div>
                        </div>
                    }
                </div>
                <div className='bg-white rounded-3xl p-5 shadow-xl'>
                    <h3 className='mb-5'>تحويل الأرباح</h3>
                    {allowTransfer === false ? <h6 className='text-red-400'>لايمكن تحويل الأرباح قبل صرف الشيك</h6> :
                        <div>
                            <div>

                                {dealOriginal.MONEY_TRANSFEERED === true ? <h6 className='text-green-400'>تم </h6> :
                                    <input type="checkbox" id="ReceiveCheck" name="ReceiveCheck" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {
                                        updateStateRequest(e.target.checked, 'MONEY_TRANSFEERED');
                                    }} />
                                }
                                <label htmlFor='ReceiveCheck' className='text-base font-bold mr-4'>تم التحويل ؟</label>
                            </div>
                            <div className='justify-between flex flex-col gap-2 flex-wrap mt-5' >
                                <h6 className='font-bold'>تاريخ التحويل</h6>
                                {(dealOriginal.TRANSFEER_DATE !== undefined && dealOriginal.TRANSFEER_DATE !== null) ? <h6>{getYearMonthDayFromDate(dealOriginal.TRANSFEER_DATE)}</h6> :
                                    <EGDate DateValue={(dateVal) => {
                                        updateStateRequest(new Date(dateVal), 'TRANSFEER_DATE');
                                    }}
                                    />
                                }
                            </div>
                            <div className='mt-5'>
                                {(dealOriginal.TRANSFEER_NOTES !== undefined || dealOriginal.TRANSFEER_NOTES !== null) ? <h6>{dealOriginal.TRANSFEER_NOTES}</h6> :
                                    <CustomTextField style={{ width: '100%' }} type={'text'} InputProps={{
                                    }} onChange={(event) => {
                                        updateStateRequest(event.target.value, 'TRANSFEER_NOTES');
                                    }}
                                        colors={colors} theme={theme} label="ملاحظات" />
                                }
                            </div>
                        </div>
                    }
                </div> */}

            </div>
            {
                (dealOriginal.MONEY_TRANSFEERED === false || dealOriginal.MONEY_TRANSFEERED === undefined) &&
                <div className='w-1/6 my-10 self-end mx-10'>
                    <EgButton style={{ width: '100%', margin: '0 auto' }} HandleClick={() => {
                        handleAcountAction()
                    }}>
                        <h5>حفظ</h5>
                    </EgButton>
                </div>
            }
        </div >
    )
}

export default AccountantAction