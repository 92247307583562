import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { EgButton } from '../../../Shared/Shared'

const ProjectAdd = () => {
  return (
    <Stack direction='row' justifyContent='space-around'>
      <Box width='20%'>
        <Link to='/dashboard/project'>
          <EgButton HandleClick={() => { }} >
            <Typography fontWeight='bold' variant="h6">إضافه</Typography>
          </EgButton>
        </Link>
      </Box>
    </Stack>
  )
}

export default ProjectAdd