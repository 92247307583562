import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface ILang {
    langDirection : "rtl" |'ltr'
}

const initialState: ILang = {
    langDirection : 'rtl'
}

export const LanguageSlice = createSlice({
  name: 'Language',
  initialState,
  reducers: {
    languageDirection : (state, action : PayloadAction<'rtl' | 'ltr'>) => {
        state.langDirection = action.payload
    }

  },
})

export const langDirState = (state: RootState) => state.Language.langDirection;
export const { languageDirection } = LanguageSlice.actions

export default LanguageSlice.reducer