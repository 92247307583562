import React, { useEffect, useState } from 'react'
import ImageUploader from '../../../../../components/ImageUploader'
import { FINISHING_DESIGINS, SaveFinishingDesginsReq } from '../../../../../Service/models/Finishing'
import { AppDispatch } from '../../../../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { SaveFinishingDesign } from '../../../../../Service/Apis'
import { AddAction } from '../../../../../redux/slices/ErrorsSlice'
import { DesignCategory, getDesignCategoryString, GetErrorAxios, HavePermission, PERMISSIONS } from '../../../../../Globals'
import { EgButton } from '../../../../Shared/Shared'
import { setLoadingFalse, setLoadingTrue } from '../../../../../redux/slices/LoadingSlice'
import GModal from '../../../../../components/GModal'
import { DropTextField } from '../../../Actions/ActionsTypesScreen/AppointMents'

type IMGS = {
    fileName: string,
    Base64: string,
    DesignCategory?: DesignCategory
}


type CompProps = {
    id: number
}


const UploadImgs = (Props: CompProps) => {

    const { id, ...restProps } = Props

    const [imgs, setImgs] = useState<IMGS[]>([])
    const [DesignsSaveRequest, setDesignsSaveRequest] = useState<SaveFinishingDesginsReq>({ REQUEST_ID: id, FINISHING_DESIGINS: [] })
    const [NewImageModal, setNewImageModal] = useState(false)
    const dispatch = useDispatch<AppDispatch>()
    const [initialDropDownVal, setInitialDropDownVal] = useState<DesignCategory>(1 as DesignCategory)


    const saveImages = () => {
        dispatch(setLoadingTrue())
        SaveFinishingDesign(DesignsSaveRequest).then((res) => {
            dispatch(AddAction({ message: "تم الحفظ بنجاح", status: 'success' })
            )
            dispatch(setLoadingFalse())
            window.location.reload()
        }).catch(err => {
            GetErrorAxios(err, dispatch)
        })
    }





    // useEffect(() => {
    //     let imgsHolder: FINISHING_DESIGINS[] = []
    //     imgs.map(img => {
    //         imgsHolder.push({
    //             DATA_BASE: img.Base64
    //         })
    //     })
    //     setDesignsSaveRequest(prev => ({ ...prev, FINISHING_DESIGINS: imgsHolder }))
    // }, [imgs])


    return (
        <>
            <div className='w-full flex flex-col gap-8 bg-white p-5'>
                <p className='text-red-600 font-bold my-8'>*بإمكانك سحب او ادخال عده صور في نفس المكان</p>
                <div className='flex flex-col justify-center items-center gap-2'>
                    <div className='flex gap-1 w-fit items-center  hover:cursor-pointer' onClick={() => {
                        setNewImageModal(true)

                    }}>
                        <div className='i-material-symbols-add-box-outline-rounded text-4xl text-gray-500 my-3' >
                        </div>
                        <h5 className='text-xl font-bold'>إضافه صور</h5>
                    </div>
                </div>
                <div className='flex gap-2'>
                    {DesignsSaveRequest.FINISHING_DESIGINS?.map((item, index) => {
                        console.log(item)
                        return <div className='flex flex-col justify-center items-center gap-2'>
                            <div className=' w-32 h-32 rounded-lg overflow-hidden'>
                                <img src={item.DATA_BASE} alt='img' className='w-full h-full object-cover' />
                            </div>
                            <p>{getDesignCategoryString(item.DESIGN_CATEGORY!)}</p>
                        </div>
                    })}
                </div>
                <div className='bg-red-400 flex gap-4'>
                </div>

                <div className='w-1/6 self-end'>
                    <EgButton HandleClick={() => {
                        saveImages()
                    }}>
                        <p className='font-bold'>حفظ التصاميم</p>
                    </EgButton>
                </div>
            </div>
            <GModal onCloseModal={() => {
                setNewImageModal(false)
            }} OpenModal={NewImageModal}>
                <div className='w-full bg-white p-4 gap-8 rounded-lg flex flex-col h-full items-center'>

                    <div className='flex flex-col gap-4'>
                        <ImageUploader onImageUpload={(data) => {
                            let imgsConvert: IMGS[] = data.map(item => ({ Base64: item.base64, fileName: item.fileName }))
                            setImgs(imgsConvert)
                        }} />
                    </div>
                    {/* create drop down data with categories types */}
                    <div className='w-1/2 bg-red-300'>
                        <DropTextField classes='w-full bg-white' initalValue={initialDropDownVal} data={[
                            { id: 8, desc: getDesignCategoryString(DesignCategory.DesignInterface) },
                            { id: 1, desc: getDesignCategoryString(DesignCategory.DesignMainRoom) },
                            { id: 4, desc: getDesignCategoryString(DesignCategory.DesignReception) },
                            { id: 5, desc: getDesignCategoryString(DesignCategory.DesignKitchen) },
                            { id: 6, desc: getDesignCategoryString(DesignCategory.DesignBathroom1) },
                            { id: 7, desc: getDesignCategoryString(DesignCategory.DesignBathroom2) },
                            { id: 2, desc: getDesignCategoryString(DesignCategory.DesignChildRoom1) },
                            { id: 3, desc: getDesignCategoryString(DesignCategory.DesignChildRoom2) },

                        ]} onchange={e => {
                            let value = e.target.value as DesignCategory
                            setInitialDropDownVal(value)
                            // setImgs(prev => prev.map(item => ({ ...item, DesignCategory: value })))
                        }} />
                    </div>

                    <div className='w-1/6 flex justify-center mt-4'>
                        <EgButton HandleClick={() => {
                            setNewImageModal(false)
                            setDesignsSaveRequest(prev => ({ ...prev, FINISHING_DESIGINS: [...prev.FINISHING_DESIGINS ?? [], ...imgs.map(item => ({ DATA_BASE: item.Base64, DESIGN_CATEGORY: initialDropDownVal }))] }))
                            setImgs([])
                            setInitialDropDownVal(1 as DesignCategory)
                        }}>
                            <p className='font-bold'>إضافه</p>
                        </EgButton>
                    </div>
                </div>
            </GModal>
        </>
    )
}

export default UploadImgs