import React, { useEffect, useState } from 'react'
import GInput from '../../../../components/GInput'
import MulitDD from '../../../../components/MultiDD'
import { GetAllUserDetail, SaveFinishApplication } from '../../../../Service/Apis'
import { getAllUnitTypes, GetErrorAxios, HavePermission, PERMISSIONS } from '../../../../Globals'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../../redux/store'
import { DropDownData } from '../../Actions/ActionsTypesScreen/AppointMents'
import { USER_Detail } from '../../../../Service/models/UserModel/UserModel'
import { EgButton } from '../../../Shared/Shared'
import { FINISHING_REQUEST } from '../../../../Service/models/Finishing'
import { AddAction } from '../../../../redux/slices/ErrorsSlice'
import { setLoadingFalse, setLoadingTrue } from '../../../../redux/slices/LoadingSlice'
import { UsersState } from '../../../../redux/slices/UserSlice'

const NewRequest = () => {
    const dispatch = useDispatch<AppDispatch>();
    const UsersData = useSelector(UsersState);
    const [dropDownData, setDropDownData] = useState<DropDownData[]>([])
    const [users, setUsers] = useState<USER_Detail[]>([]);

    const [saveFinishRequest, setSaveFinishRequest] = useState<FINISHING_REQUEST>({
        AVLIABLE_WORK: undefined,
        FINISHING_LEVEL: undefined,
        FINISHING_STAGE: undefined,
        FLOOR_NO: undefined,
        TOTAL_AREA: undefined,
        UNIT_LEVEL: undefined,
        UNIT_NO: undefined,
        UNIT_TYPE: undefined,
        BUILDING_NO: undefined,
        UNIT_ADDRESS: undefined,
        USER_ID: undefined
    } as FINISHING_REQUEST)

    useEffect(() => {
        GetAllUserDetail({ WithAdmin: false, WithNewUsers: true, SortedByNewUsers: true }).then(res => {
            setUsers(res)
            let usersDropDownData: DropDownData[] = res.map((item, idx) => ({ id: item.ID, desc: item.FULL_NAME, image: item.PHOTO_URL }))
            setDropDownData(usersDropDownData)
        }).catch(err => {
            GetErrorAxios(err, dispatch)
        })
    }, [])


    const saveFinishingRequestAPI = () => {
        let allowCall = true


        // if (saveFinishRequest.USER_ID === undefined) {
        //     dispatch(AddAction({ message: "برجاء تحديد المستخدم", status: 'error' }))
        //     return;
        // }
        if (saveFinishRequest.UNIT_ADDRESS === undefined) {
            dispatch(AddAction({ message: "برجاء تحديد العنوان", status: 'error' }))
            return;
        }
        if (saveFinishRequest.BUILDING_NO === undefined) {
            dispatch(AddAction({ message: "برجاء تحديد رقم العماره", status: 'error' }))
            return;
        }
        if (saveFinishRequest.FLOOR_NO === undefined) {
            dispatch(AddAction({ message: "برجاء تحديد رقم الدور", status: 'error' }))
            return;
        }
        if (saveFinishRequest.UNIT_NO === undefined) {
            dispatch(AddAction({ message: "برجاء تحديد رقم الوحده", status: 'error' }))
            return;
        }
        if (saveFinishRequest.TOTAL_AREA === undefined) {
            dispatch(AddAction({ message: "برجاء تحديد المساحه", status: 'error' }))
            return;
        }
        if (saveFinishRequest.FINISHING_LEVEL === undefined) {
            dispatch(AddAction({ message: "برجاء تحديد مستوى التشطيب", status: 'error' }))
            return;
        }
        if (saveFinishRequest.FINISHING_STAGE === undefined) {
            dispatch(AddAction({ message: "برجاء تحديد مرحله التشطيب", status: 'error' }))
            return;
        }
        if (saveFinishRequest.AVLIABLE_WORK === undefined) {
            dispatch(AddAction({ message: "برجاء تحديد موقف الإستلام", status: 'error' }))
            return;
        }
        if (saveFinishRequest.UNIT_TYPE === undefined) {
            dispatch(AddAction({ message: "برجاء تحديد نوع الوحده", status: 'error' }))
            return;
        }
        if (saveFinishRequest.UNIT_LEVEL === undefined) {
            dispatch(AddAction({ message: "برجاء تحديد مستوى الإستلام", status: 'error' }))
            return;
        }
        if (saveFinishRequest.COMPANY_ID === undefined) {
            dispatch(AddAction({ message: "برجاء تحديد فرع الشركه", status: 'error' }))
            return;
        }

        if (allowCall) {
            setLoadingTrue()
            SaveFinishApplication(saveFinishRequest).then(() => {
                dispatch(AddAction({ message: "تم التسجيل بنجاح", status: 'success' })
                )
                window.location.reload()
            }).catch(err => {
                GetErrorAxios(err, dispatch)
                setLoadingFalse()
            })
        }
    }

    return (
        <div className='flex flex-col '>
            <div className='grid grid-rows-2 gap-4 mb-4'>
                <div className='row-span-full flex justify-center items-center'>
                    {/* GET_ALL_FINISHING_REQUESTS */}
                    {UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.GET_ALL_FINISHING_REQUESTS, UsersData.currentUser.PERMISSIONS) && (
                        <div className='w-1/2'>
                            <p>برجاء إختيار مستخدم</p>
                            <MulitDD SingleSelection IsImage OuterDropDownData={dropDownData} SelectedIds={(ids) => {
                                setSaveFinishRequest(prev => ({ ...prev, USER_ID: ids[0] }))
                            }} />
                        </div>
                    )}
                </div>
                <div className='grid grid-cols-2 p-3 bg-slate-100 rounded-xl shadow-md gap-4'>
                    <div className='w-full'>
                        <p className='mb-2'>العنوان</p>
                        <GInput inputChangedVal={(val) => {
                            setSaveFinishRequest(prev => ({ ...prev, UNIT_ADDRESS: val as string }))

                        }} />
                    </div>
                    <div className='w-full'>
                        <p className='mb-2'>رقم العماره</p>
                        <GInput isNumber inputChangedVal={(val) => {
                            setSaveFinishRequest(prev => ({ ...prev, BUILDING_NO: parseInt(val.toString()) }))
                        }} />
                    </div>
                    <div className='w-full'>
                        <p className='mb-2'>الدور</p>
                        <GInput isNumber inputChangedVal={(val) => {
                            setSaveFinishRequest(prev => ({ ...prev, FLOOR_NO: parseInt(val.toString()) }))

                        }} />
                    </div>
                    <div className='w-full'>
                        <p className='mb-2'>رقم الوحده</p>
                        <GInput isNumber inputChangedVal={(val) => {
                            setSaveFinishRequest(prev => ({ ...prev, UNIT_NO: parseInt(val.toString()) }))
                        }} />
                    </div>
                    <div className='w-full'>
                        <p className='mb-2'>المساحه</p>
                        <GInput isNumber inputChangedVal={(val) => {
                            setSaveFinishRequest(prev => ({ ...prev, TOTAL_AREA: parseInt(val.toString()) }))
                        }} />
                    </div>
                    <div className='w-full'>
                        <p className='mb-2'>مستوى الإستلام</p>
                        <MulitDD SingleSelection InitialSelectedIds={[saveFinishRequest.UNIT_LEVEL ?? -1]} OuterDropDownData={[{ id: 0, desc: "طوب" }, { id: 1, desc: "محاره" }, { id: 2, desc: "ترميمات" }]} SelectedIds={(ids) => {
                            setSaveFinishRequest(prev => ({ ...prev, UNIT_LEVEL: ids[0] }))
                        }} />
                    </div>
                    <div className='col-span-full'>
                        <p className='mb-2'>الموقف من الإستلام</p>
                        <MulitDD SingleSelection InitialSelectedIds={saveFinishRequest.AVLIABLE_WORK === undefined ? [] : [saveFinishRequest.AVLIABLE_WORK === true ? 0 : 1]} OuterDropDownData={[{ id: 0, desc: "نعم" }, { id: 1, desc: "لا" }]} SelectedIds={(ids) => {
                            setSaveFinishRequest(prev => ({ ...prev, AVLIABLE_WORK: ids[0] === 0 ? true : false }))
                        }} />
                    </div>
                    <div className='w-full'>
                        <p className='mb-2'>مستوى التشطيب</p>
                        <MulitDD SingleSelection InitialSelectedIds={[saveFinishRequest.FINISHING_LEVEL ?? -1]} OuterDropDownData={[{ id: 0, desc: "عالي" }, { id: 1, desc: "متوسط" }, { id: 2, desc: "منخفض" }]} SelectedIds={(ids) => {
                            setSaveFinishRequest(prev => ({ ...prev, FINISHING_LEVEL: ids[0] }))
                        }} />
                    </div>
                    <div className='w-full'>
                        <p className='mb-2'>مراحل التشطيب</p>
                        <MulitDD SingleSelection InitialSelectedIds={[saveFinishRequest.FINISHING_STAGE ?? -1]} OuterDropDownData={[{ id: 0, desc: "نص تشطيب" }, { id: 1, desc: "تشطيب كامل" }, { id: 2, desc: "تشطيب كامل بالفرش" }]} SelectedIds={(ids) => {
                            setSaveFinishRequest(prev => ({ ...prev, FINISHING_STAGE: ids[0] }))
                        }} />
                    </div>
                    <div className='w-full'>
                        <p className='mb-2'>نوع الوحده</p>
                        <MulitDD SingleSelection InitialSelectedIds={[saveFinishRequest.UNIT_TYPE ?? -1]} OuterDropDownData={[
                            { id: 1, desc: 'شقه' },
                            { id: 2, desc: 'فيلا' },
                            { id: 3, desc: 'توين هاوس' },
                            { id: 4, desc: 'تاون هاوس' },
                            { id: 5, desc: 'دوبلكس' },
                            { id: 6, desc: 'بنتهاوس' },
                            { id: 7, desc: 'شاليه' },
                            { id: 8, desc: 'استديو' },
                            { id: 9, desc: 'كابينه' },
                            { id: 10, desc: 'عياده' },
                            { id: 11, desc: 'مكتب' },
                            { id: 12, desc: 'متجر' },
                        ]} SelectedIds={(ids) => {
                            setSaveFinishRequest(prev => ({ ...prev, UNIT_TYPE: ids[0] }))
                        }} />
                    </div>
                    <div className='w-full'>
                        <p className='mb-2'>الفرع</p>
                        <MulitDD SingleSelection InitialSelectedIds={[saveFinishRequest.COMPANY_ID ?? -1]} OuterDropDownData={[
                            { id: 1, desc: 'المنيا' },
                            { id: 2, desc: 'سوهاج' },
                        ]} SelectedIds={(ids) => {
                            setSaveFinishRequest(prev => ({ ...prev, COMPANY_ID: ids[0] }))
                        }} />
                    </div>
                </div>
            </div>
            <div className='w-1/5 self-end'>
                <EgButton HandleClick={saveFinishingRequestAPI}>
                    <h6>حفظ الطلب</h6>
                </EgButton>
            </div>
        </div>
    )
}

export default NewRequest