import {STATISTICS, STATISTICS_REQ} from "../../../Service/models/statistics/indext";
import {getStatistics} from "../../../Service/Apis";
import {ActionTypes, GetErrorAxios} from "../../../Globals";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../redux/store";


enum actionTypesEnum {
    getStatistics = 'GET_STATISTICS',
    getStatisticsByAction = 'GET_STATISTICS_BY_ACTION'
}

type setStatisticsFromAPI = {
    type: actionTypesEnum.getStatistics
    payLoad: STATISTICS
}

type getStatisticsByAction = {
    type: actionTypesEnum.getStatisticsByAction
    payLoad: ActionTypes
}

export type ReducerActionTypes = setStatisticsFromAPI | getStatisticsByAction

export const STATISTIC_FROM_API = (data: STATISTICS): setStatisticsFromAPI => {
    return {
        type: actionTypesEnum.getStatistics,
        payLoad: data
    }
}


export const initialStatisticsState: STATISTICS = {
    ALL_STATISTICS: [],
    TOTAL_STATION_STATISTICS: []
}


export const StatisticsReducer = (state: STATISTICS, action: ReducerActionTypes) => {

        switch (action.type) {
            case actionTypesEnum.getStatistics:
                return action.payLoad;

            case actionTypesEnum.getStatisticsByAction : {
                return state
            }

            default:
                return state;
        }
    }
;