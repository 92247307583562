import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetErrorAxios } from "../../../../Globals";
import { SaveFinishingItems } from "../../../../Service/Apis";
import { FINISHING_ITEMS, FINISHING_ITEMS_DETAILS } from "../../../../Service/models/Finishing";
import InputCustom from "../../../../components/InputType";
import { setLoadingFalse, setLoadingTrue } from "../../../../redux/slices/LoadingSlice";
import { AppDispatch } from "../../../../redux/store";
import { EgButton } from "../../../Shared/Shared";
import GInput from "../../../../components/GInput";

interface AdditemProps {
    item: FINISHING_ITEMS | null;
}

const Additem = ({ item }: AdditemProps) => {

    const [description, setDescription] = useState<string>('');
    const [price, setPrice] = useState<number>(0);
    const [progressItems, setProgressItems] = useState<FINISHING_ITEMS_DETAILS[]>([])
    const [maxNumber, setMaxNumber] = useState<number>(0);



    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (item) {
            setDescription(item.DESCRIPTION!);
            setPrice(item.EXPECTED_PRICE);
            let NewDetails: FINISHING_ITEMS_DETAILS[] = item.FINISHING_ITEMS_DETAILS
            ?.map(item => ({ ...item, FrontID: item.ID! }))
            .sort((a, b) => a.FrontID - b.FrontID) as FINISHING_ITEMS_DETAILS[];
            setProgressItems(NewDetails)
            if (NewDetails && NewDetails.length >0){
                setMaxNumber(NewDetails[NewDetails.length - 1].FrontID + 1)
            }else{
                setMaxNumber(1)
            }
        }
    }, [item]);

    const handleInputChange = (val: string | number, type: 'description' | 'price') => {
        if (type === 'description') {
            setDescription(val as string);
        } else if (type === 'price') {
            setPrice(Number(val));
        }
    };

    const handleSave = () => {
        const newItem: FINISHING_ITEMS = {
            ID: item ? item.ID : undefined,
            DESCRIPTION: description,
            EXPECTED_PRICE: price,
            FINISHING_ITEMS_DETAILS: progressItems ?? []
        };

        dispatch(setLoadingTrue());

        SaveFinishingItems(newItem)
            .then((res) => {
                dispatch(setLoadingFalse());
                window.location.reload();
            })
            .catch((error) => {
                GetErrorAxios(error, dispatch);
            });
    };

    const AddItemProgress = () => {
        if (progressItems){
            setProgressItems(prev => [...prev, { FrontID: maxNumber, DETAILS: "", DETAILS_PROGRESS: 0.0 }])
        }else{
            setProgressItems([{ FrontID: maxNumber, DETAILS: "", DETAILS_PROGRESS: 0.0 }])
        }
        setMaxNumber(maxNumber + 1)
    }

    const updateProgressItems = (params: { id: number, val: number | string, item: "percentage" | "name" }) => {
        const { id, item, val } = params
        let currentItem = progressItems.find(item => item.FrontID === id)
        if (currentItem) {
            if (item === 'name') {
                currentItem.DETAILS = val as string
            } else {
                currentItem.DETAILS_PROGRESS = parseFloat(val as string)
            }



            let restOfItems = progressItems.filter(item => item.FrontID !== id)
            restOfItems.push(currentItem)
            restOfItems.sort((a, b) => a.FrontID - b.FrontID)

            setProgressItems(restOfItems)
        }

    }

    const removeProgItem = (id: number) => {
        let currentItem: FINISHING_ITEMS_DETAILS[]
        
        if (item) {
            currentItem = progressItems.filter(item => item.ID !== id)
        } else {
            currentItem = progressItems.filter(item => item.FrontID !== id)
        }

        currentItem.sort((a, b) => a.FrontID - b.FrontID)
        setProgressItems(currentItem)
    }

    return (
        <>
            <div className='container mx-auto'>
                <div className='mb-4'>
                    <label>وصف</label>
                    <InputCustom
                        disabled={false}
                        DefaultValue={description}
                        inputChangedVal={(val) => handleInputChange(val, 'description')}
                    />
                </div>
                <div className='mt-4 mb-4'>
                    <label>سعر</label>
                    <InputCustom
                        disabled={false}
                        DefaultValue={price.toString()}
                        isNumber
                        inputChangedVal={(val) => handleInputChange(val, 'price')}
                    />
                </div>
                <div>
                    {(
                        <>
                            <p className="font-bold text-2xl mb-5">عناصر التحديث</p>
                            {(progressItems === undefined || progressItems.length === 0) && (
                                <div className='flex gap-1 items-center' onClick={AddItemProgress}>
                                    <div className='i-material-symbols-add-box-outline-rounded text-2xl text-gray-500 hover:cursor-pointer my-3'></div>
                                    <h4 className='text-xl font-bold'>إضافه عنصر</h4>
                                </div>
                            )}

                        </>
                    )}
                    <div className="flex flex-col gap-3">
                        {progressItems && progressItems.map(item => {
                            return <ItemPercentage EditDefaultval={item} key={item.FrontID} maxID={maxNumber + 1} ID={item.FrontID} AddNewItem={AddItemProgress} removeItem={removeProgItem} updateValues={updateProgressItems} />
                        })}
                    </div>
                </div>
                <div className='mt-4 w-1/4'>
                    <EgButton HandleClick={handleSave}>
                        <h6>{item ? 'تحديث البند' : 'حفظ البند'}</h6>
                    </EgButton>
                </div>
            </div>

        </>
    );
};

export default Additem;

type TItemPercentage = {
    AddNewItem: () => void
    updateValues: (params: { id: number, val: number | string, item: "percentage" | "name" }) => void,
    removeItem: (id: number) => void,
    ID: number,
    maxID: number,
    EditDefaultval?: FINISHING_ITEMS_DETAILS
}


const ItemPercentage = (Props: TItemPercentage) => {

    const { AddNewItem, updateValues, ID, removeItem, maxID, EditDefaultval } = Props

    const AddItemsToMainArray = () => {
        AddNewItem()
    }
    const RemoveItemsToMainArray = () => {
        removeItem(ID)
    }

    return <div className="flex w-fit shadow bg-slate-200 gap-2 p-1">
        <div className=''>
            <label>عنصر</label>
            <InputCustom
                disabled={false}
                DefaultValue={EditDefaultval?.DETAILS ?? ""}
                inputChangedVal={(val) => {
                    updateValues({ id: ID, item: 'name', val })
                }}
            />
        </div>
        <div className=''>
            <label>النسبه من الإنجاز الإجمالي</label>
            <GInput
                disabled={false}
                isNumber
                DefaultValue={EditDefaultval ? EditDefaultval.DETAILS_PROGRESS.toString() : "0"}
                inputChangedVal={(val) => {
                    updateValues({ id: ID, item: 'percentage', val: val === '' ? 0 : parseFloat(val.toString()) })
                    if (!isNaN(val as number)) {
                    }
                }}
            />
        </div>
        <div className='flex gap-1 items-center self-end' onClick={AddItemsToMainArray}>
            <div className='i-material-symbols-add-box-outline-rounded text-4xl text-green-500 hover:cursor-pointer my-3'></div>
        </div>
        <div className='flex gap-1 items-center self-end' onClick={RemoveItemsToMainArray}>
            <div className='i-material-symbols-close-rounded text-4xl text-red-500 hover:cursor-pointer my-3'></div>
        </div>
    </div>
}