import { useEffect, useState } from "react";
import { GetFinishingItems } from "../../../../Service/Apis";
import DevExtremTable from "../../../../components/shared/EGDExtremeTable";
import { FINISHING_ITEMS } from "../../../../Service/models/Finishing";
import { DetailsIcons } from "../../../../components/Icons/Icons";
import { GetErrorAxios } from "../../../../Globals";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import GModal from "../../../../components/GModal";
import Additem from "./Additem";

const Finishingitems = () => {
    const [Finishitem, setFinishitem] = useState<FINISHING_ITEMS[]>([]);
    const [newEventModal, setNewEventModal] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const [selectedItem, setSelectedItem] = useState<FINISHING_ITEMS | null>(null);

    useEffect(() => {
        GetFinishingItems()
            .then((res) => {
                setFinishitem(res);
            })
            .catch((err) => {
                GetErrorAxios(err, dispatch);
            });
    }, [dispatch]);

    const handleEditClick = (item: FINISHING_ITEMS) => {
        setSelectedItem(item);
        setNewEventModal(true);
    };

    return (
        <div>
            <div className='flex gap-1 items-center' onClick={() => setNewEventModal(true)}>
                <div className='i-material-symbols-add-box-outline-rounded text-2xl text-gray-500 hover:cursor-pointer my-3'></div>
                <h4 className='text-xl font-bold'>إضافه بند</h4>
            </div>
            <DevExtremTable
                ID="ID"
                tableData={[
                    { Caption: "البند", Type: 'string', Value: 'DESCRIPTION' },
                    { Caption: " القيمة", Type: 'string', Value: 'EXPECTED_PRICE' },
                ]}
                dataSource={Finishitem}
                Actions={(data) => {
                    let currentData = data.data as FINISHING_ITEMS;
                    return (
                        <div className="flex items-center gap-4 hover:cursor-pointer p-1">
                            <div className='flex flex-col items-center hover:text-[#e7c498]' onClick={() => handleEditClick(currentData)}>
                                <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1 hover:cursor-pointer'>
                                    <DetailsIcons />
                                </div>
                                <p className='text-sm font-bold'>تعديل</p>
                            </div>
                        </div>
                    );
                }}
            />
            <GModal OpenModal={newEventModal} onCloseModal={() => setNewEventModal(false)}>
                <Additem item={null} />
            </GModal>
            <GModal OpenModal={newEventModal} onCloseModal={() => setNewEventModal(false)}>
                <Additem item={selectedItem} />
            </GModal>
        </div>
    );
};

export default Finishingitems;
