import { useSelector } from 'react-redux'
import { SendIcon } from '../../../../components/Icons/Icons'
import InputCustom from '../../../../components/InputType'
import { DESGINE_COMMENTS, FINISHING_DESIGINS } from '../../../../Service/models/Finishing'
import { UsersState } from '../../../../redux/slices/UserSlice'
import { useEffect, useState } from 'react'
import { AcceptFinishDesign, GetAllFinishingRequest, SaveFinishingComment } from '../../../../Service/Apis'
import { DesignCategory, GetErrorAxios } from '../../../../Globals'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../redux/store'
import { setLoadingFalse, setLoadingTrue } from '../../../../redux/slices/LoadingSlice'
import { AddAction } from '../../../../redux/slices/ErrorsSlice'
import { EgButton } from '../../../Shared/Shared'
import { Carousel, CarouselItem } from '../../../../components/Carousel2'

type CompProps = {
    Designs: FINISHING_DESIGINS[],
    AppCreator: number,
    RequestID: number,
    Accepted: boolean,
}

type DetailedFinishType = {
    EntranceFinch: FINISHING_DESIGINS[],
    receptionFinish: FINISHING_DESIGINS[],
    mainRoomFinish: FINISHING_DESIGINS[],
    kitchenFinish: FINISHING_DESIGINS[],
    bathroomFinish1: FINISHING_DESIGINS[],
    bathroomFinish2: FINISHING_DESIGINS[],
    childRoomFinish1: FINISHING_DESIGINS[],
    childRoomFinish2: FINISHING_DESIGINS[]
}

const ImgDiscussion = (Props: CompProps) => {
    const { Designs, AppCreator, RequestID, Accepted, ...rest } = Props
    const [finishDesign, setFinishDesigns] = useState<FINISHING_DESIGINS[]>(Designs)
    const [detailedFinish, setDetailedFinish] = useState<DetailedFinishType>({} as DetailedFinishType)
    const UsersData = useSelector(UsersState);

    const [CommentReq, setCommentReq] = useState<DESGINE_COMMENTS>({ USER_ID: UsersData.currentUser.ID } as DESGINE_COMMENTS)

    const dispatch = useDispatch<AppDispatch>()

    const sendDesignCommentToServer = async () => {
        try {
            if (CommentReq.COMMENT === 'علق هنا' || CommentReq.COMMENT === '') {
                dispatch(AddAction({ message: "برجاء ادخال تعليق", status: "error" }))
                return;
            }
            dispatch(setLoadingTrue())
            await SaveFinishingComment(CommentReq)
            dispatch(setLoadingFalse())

            window.location.reload()
        } catch (err) {
            GetErrorAxios(err, dispatch)
        }

    }

    const ApproveDesign = async () => {
        try {
            dispatch(setLoadingTrue())
            if (RequestID) {
                await AcceptFinishDesign({ REQUEST_ID: RequestID })
            }
            dispatch(setLoadingFalse())
            window.location.reload()
        } catch (err) {
            GetErrorAxios(err, dispatch)
        }

    }


    useEffect(() => {
        setFinishDesigns(Designs)

        let EntranceFinch: FINISHING_DESIGINS[] = []
        let receptionFinish: FINISHING_DESIGINS[] = []
        let mainRoomFinish: FINISHING_DESIGINS[] = []
        let kitchenFinish: FINISHING_DESIGINS[] = []
        let bathroomFinish1: FINISHING_DESIGINS[] = []
        let bathroomFinish2: FINISHING_DESIGINS[] = []
        let childRoomFinish1: FINISHING_DESIGINS[] = []
        let childRoomFinish2: FINISHING_DESIGINS[] = []


        Designs.map(design => {
            if (design.DESIGN_CATEGORY === DesignCategory.DesignInterface) {
                EntranceFinch.push(design)
            }

            if (design.DESIGN_CATEGORY === DesignCategory.DesignReception) {
                receptionFinish.push(design)
            }

            if (design.DESIGN_CATEGORY === DesignCategory.DesignMainRoom) {
                mainRoomFinish.push(design)
            }

            if (design.DESIGN_CATEGORY === DesignCategory.DesignKitchen) {
                kitchenFinish.push(design)
            }

            if (design.DESIGN_CATEGORY === DesignCategory.DesignBathroom1) {
                bathroomFinish1.push(design)
            }

            if (design.DESIGN_CATEGORY === DesignCategory.DesignBathroom2) {
                bathroomFinish2.push(design)
            }

            if (design.DESIGN_CATEGORY === DesignCategory.DesignChildRoom1) {
                childRoomFinish1.push(design)
            }

            if (design.DESIGN_CATEGORY === DesignCategory.DesignChildRoom2) {
                childRoomFinish2.push(design)
            }

            setDetailedFinish({ EntranceFinch: EntranceFinch, receptionFinish: receptionFinish, mainRoomFinish: mainRoomFinish, kitchenFinish: kitchenFinish, bathroomFinish1: bathroomFinish1, bathroomFinish2: bathroomFinish2, childRoomFinish1: childRoomFinish1, childRoomFinish2: childRoomFinish2 })

        })
    }, [Designs]);



    return (
        <div className='flex flex-col'>
            <div className='w-[20%]  overflow-hidden'>
                <h2 className='truncate'>التصاميم</h2>
                <hr className='my-2 border-2 border-[#e7c498]' />
            </div>
            {/* <div className='flex flex-row gap-2'> */}
            {/* <div className='grid gap-10 grid-cols-[repeat(auto-fill,minmax(250px,250px))]'>
                {finishDesign.map(design => {
                    let user = UsersData.currentUser.ID;
                    return <>
                        <div className='w-72 p-2 rounded-md bg-white shadow-lg'>
                            <a className='hover:cursor-pointer' href={design.URL} target='_blank' rel="noreferrer"  >
                                <img src={design.URL} alt={design.ID?.toString()} className='w-full  object-cover' />
                            </a>

                            <div className='flex flex-col my-20 gap-2'>
                                {design.DESGINE_COMMENTS?.slice((design.DESGINE_COMMENTS.length - 1) - 3, design.DESGINE_COMMENTS.length).map(comment => {
                                    return <div className={`${user === comment.USER_ID ? "self-start bg-blue-100" : "self-end bg-yellow-100"} p-2 rounded-md shadow`}>
                                        <h6 className='text-center'>{comment.STAMP_USER}</h6>
                                        <div>{comment.COMMENT}</div>
                                    </div>
                                })}
                            </div>

                            <div className='w-full my-8'>
                                <InputCustom DefaultValue='علق هنا' inputChangedVal={(val) => {
                                    let value = val as string
                                    setCommentReq(prev => ({ ...prev, COMMENT: value, DESGIN_ID: design.ID! }))

                                }} EndActionIcon={
                                    <div className='w-full h-full flex items-center justify-center'>
                                        <SendIcon />
                                    </div>
                                } EndActionCallback={() => {
                                    sendDesignCommentToServer()
                                }} />
                            </div>

                            <div className='mt-2 ml-0 hover:pr-2 hover:font-bold transition-all duration-300 hover:cursor-pointer'>
                                <p className='underline text-blue-500'>المحادثه كامله !!</p>
                            </div>

                        </div>
                    </>
                })}
            </div> */}
            <div className='w-full bg-slate-200 rounded-2xl shadow-md flex flex-col p-3'>
                {detailedFinish?.EntranceFinch?.length > 0 && (
                    <>
                        <p>المدخل</p>
                        <Carousel itemWidth={20}>
                            {detailedFinish.EntranceFinch.map(design => {
                                let user = UsersData.currentUser.ID;
                                return <>
                                    <CarouselItem>
                                        <div className='w-full p-2 rounded-md bg-white shadow-lg'>
                                            <a className='hover:cursor-pointer' href={design.URL} target='_blank' rel="noreferrer"  >
                                                <img src={design.URL} alt={design.ID?.toString()} className='w-full  object-cover' />
                                            </a>

                                            <div className='flex flex-col my-20 gap-2'>
                                                {design.DESGINE_COMMENTS?.slice((design.DESGINE_COMMENTS.length - 1) - 3, design.DESGINE_COMMENTS.length).map(comment => {
                                                    return <div className={`${user === comment.USER_ID ? "self-start bg-blue-100" : "self-end bg-yellow-100"} p-2 rounded-md shadow`}>
                                                        <h6 className='text-center'>{comment.STAMP_USER}</h6>
                                                        <div>{comment.COMMENT}</div>
                                                    </div>
                                                })}
                                            </div>

                                            <div className='w-full h-full my-8'>
                                                <InputCustom DefaultValue='علق هنا' inputChangedVal={(val) => {
                                                    let value = val as string
                                                    setCommentReq(prev => ({ ...prev, COMMENT: value, DESGIN_ID: design.ID! }))

                                                }} EndActionIcon={
                                                    <div className='w-full h-full flex items-center justify-center'>
                                                        <SendIcon />
                                                    </div>
                                                } EndActionCallback={() => {
                                                    sendDesignCommentToServer()
                                                }} />
                                            </div>

                                            <div className='mt-2 ml-0 hover:pr-2 hover:font-bold transition-all duration-300 hover:cursor-pointer'>
                                                <p className='underline text-blue-500'>المحادثه كامله !!</p>
                                            </div>

                                        </div>
                                    </CarouselItem>
                                </>
                            })}
                        </Carousel>
                    </>
                )}
                {detailedFinish?.receptionFinish?.length > 0 && (
                    <>
                        <p>الإستقبال</p>
                        <Carousel itemWidth={20}>
                            {detailedFinish.receptionFinish.map(design => {
                                let user = UsersData.currentUser.ID;
                                return <>
                                    <CarouselItem>
                                        <div className='w-full p-2 rounded-md bg-white shadow-lg'>
                                            <a className='hover:cursor-pointer' href={design.URL} target='_blank' rel="noreferrer"  >
                                                <img src={design.URL} alt={design.ID?.toString()} className='w-full  object-cover' />
                                            </a>

                                            <div className='flex flex-col my-20 gap-2'>
                                                {design.DESGINE_COMMENTS?.slice((design.DESGINE_COMMENTS.length - 1) - 3, design.DESGINE_COMMENTS.length).map(comment => {
                                                    return <div className={`${user === comment.USER_ID ? "self-start bg-blue-100" : "self-end bg-yellow-100"} p-2 rounded-md shadow`}>
                                                        <h6 className='text-center'>{comment.STAMP_USER}</h6>
                                                        <div>{comment.COMMENT}</div>
                                                    </div>
                                                })}
                                            </div>

                                            <div className='w-full h-full my-8'>
                                                <InputCustom DefaultValue='علق هنا' inputChangedVal={(val) => {
                                                    let value = val as string
                                                    setCommentReq(prev => ({ ...prev, COMMENT: value, DESGIN_ID: design.ID! }))

                                                }} EndActionIcon={
                                                    <div className='w-full h-full flex items-center justify-center'>
                                                        <SendIcon />
                                                    </div>
                                                } EndActionCallback={() => {
                                                    sendDesignCommentToServer()
                                                }} />
                                            </div>

                                            <div className='mt-2 ml-0 hover:pr-2 hover:font-bold transition-all duration-300 hover:cursor-pointer'>
                                                <p className='underline text-blue-500'>المحادثه كامله !!</p>
                                            </div>

                                        </div>
                                    </CarouselItem>
                                </>
                            })}
                        </Carousel>
                    </>
                )}
                {detailedFinish?.mainRoomFinish?.length > 0 && (
                    <>
                        <p>الغرفه الرئيسيه</p>
                        <Carousel itemWidth={20}>
                            {detailedFinish.mainRoomFinish.map(design => {
                                let user = UsersData.currentUser.ID;
                                return <>
                                    <CarouselItem>
                                        <div className='w-full p-2 rounded-md bg-white shadow-lg'>
                                            <a className='hover:cursor-pointer' href={design.URL} target='_blank' rel="noreferrer"  >
                                                <img src={design.URL} alt={design.ID?.toString()} className='w-full  object-cover' />
                                            </a>

                                            <div className='flex flex-col my-20 gap-2'>
                                                {design.DESGINE_COMMENTS?.slice((design.DESGINE_COMMENTS.length - 1) - 3, design.DESGINE_COMMENTS.length).map(comment => {
                                                    return <div className={`${user === comment.USER_ID ? "self-start bg-blue-100" : "self-end bg-yellow-100"} p-2 rounded-md shadow`}>
                                                        <h6 className='text-center'>{comment.STAMP_USER}</h6>
                                                        <div>{comment.COMMENT}</div>
                                                    </div>
                                                })}
                                            </div>

                                            <div className='w-full h-full my-8'>
                                                <InputCustom DefaultValue='علق هنا' inputChangedVal={(val) => {
                                                    let value = val as string
                                                    setCommentReq(prev => ({ ...prev, COMMENT: value, DESGIN_ID: design.ID! }))

                                                }} EndActionIcon={
                                                    <div className='w-full h-full flex items-center justify-center'>
                                                        <SendIcon />
                                                    </div>
                                                } EndActionCallback={() => {
                                                    sendDesignCommentToServer()
                                                }} />
                                            </div>

                                            <div className='mt-2 ml-0 hover:pr-2 hover:font-bold transition-all duration-300 hover:cursor-pointer'>
                                                <p className='underline text-blue-500'>المحادثه كامله !!</p>
                                            </div>

                                        </div>
                                    </CarouselItem>
                                </>
                            })}
                        </Carousel>
                    </>
                )}
                {detailedFinish?.kitchenFinish?.length > 0 && (
                    <>
                        <p>المطبخ</p>
                        <Carousel itemWidth={20}>
                            {detailedFinish.kitchenFinish.map(design => {
                                let user = UsersData.currentUser.ID;
                                return <>
                                    <CarouselItem>
                                        <div className='w-full p-2 rounded-md bg-white shadow-lg'>
                                            <a className='hover:cursor-pointer' href={design.URL} target='_blank' rel="noreferrer"  >
                                                <img src={design.URL} alt={design.ID?.toString()} className='w-full  object-cover' />
                                            </a>

                                            <div className='flex flex-col my-20 gap-2'>
                                                {design.DESGINE_COMMENTS?.slice((design.DESGINE_COMMENTS.length - 1) - 3, design.DESGINE_COMMENTS.length).map(comment => {
                                                    return <div className={`${user === comment.USER_ID ? "self-start bg-blue-100" : "self-end bg-yellow-100"} p-2 rounded-md shadow`}>
                                                        <h6 className='text-center'>{comment.STAMP_USER}</h6>
                                                        <div>{comment.COMMENT}</div>
                                                    </div>
                                                })}
                                            </div>

                                            <div className='w-full h-full my-8'>
                                                <InputCustom DefaultValue='علق هنا' inputChangedVal={(val) => {
                                                    let value = val as string
                                                    setCommentReq(prev => ({ ...prev, COMMENT: value, DESGIN_ID: design.ID! }))

                                                }} EndActionIcon={
                                                    <div className='w-full h-full flex items-center justify-center'>
                                                        <SendIcon />
                                                    </div>
                                                } EndActionCallback={() => {
                                                    sendDesignCommentToServer()
                                                }} />
                                            </div>

                                            <div className='mt-2 ml-0 hover:pr-2 hover:font-bold transition-all duration-300 hover:cursor-pointer'>
                                                <p className='underline text-blue-500'>المحادثه كامله !!</p>
                                            </div>

                                        </div>
                                    </CarouselItem>
                                </>
                            })}
                        </Carousel>
                    </>
                )}
                {detailedFinish?.childRoomFinish1?.length > 0 && (
                    <>
                        <p>غرفه نوم اطفال 1</p>
                        <Carousel itemWidth={20}>
                            {detailedFinish.childRoomFinish1.map(design => {
                                let user = UsersData.currentUser.ID;
                                return <>
                                    <CarouselItem>
                                        <div className='w-full p-2 rounded-md bg-white shadow-lg'>
                                            <a className='hover:cursor-pointer' href={design.URL} target='_blank' rel="noreferrer"  >
                                                <img src={design.URL} alt={design.ID?.toString()} className='w-full  object-cover' />
                                            </a>

                                            <div className='flex flex-col my-20 gap-2'>
                                                {design.DESGINE_COMMENTS?.slice((design.DESGINE_COMMENTS.length - 1) - 3, design.DESGINE_COMMENTS.length).map(comment => {
                                                    return <div className={`${user === comment.USER_ID ? "self-start bg-blue-100" : "self-end bg-yellow-100"} p-2 rounded-md shadow`}>
                                                        <h6 className='text-center'>{comment.STAMP_USER}</h6>
                                                        <div>{comment.COMMENT}</div>
                                                    </div>
                                                })}
                                            </div>

                                            <div className='w-full h-full my-8'>
                                                <InputCustom DefaultValue='علق هنا' inputChangedVal={(val) => {
                                                    let value = val as string
                                                    setCommentReq(prev => ({ ...prev, COMMENT: value, DESGIN_ID: design.ID! }))

                                                }} EndActionIcon={
                                                    <div className='w-full h-full flex items-center justify-center'>
                                                        <SendIcon />
                                                    </div>
                                                } EndActionCallback={() => {
                                                    sendDesignCommentToServer()
                                                }} />
                                            </div>

                                            <div className='mt-2 ml-0 hover:pr-2 hover:font-bold transition-all duration-300 hover:cursor-pointer'>
                                                <p className='underline text-blue-500'>المحادثه كامله !!</p>
                                            </div>

                                        </div>
                                    </CarouselItem>
                                </>
                            })}
                        </Carousel>
                    </>
                )}
                {detailedFinish?.childRoomFinish2?.length > 0 && (
                    <>
                        <p>غرفه نوم اطفال 2</p>
                        <Carousel itemWidth={20}>
                            {detailedFinish.childRoomFinish2.map(design => {
                                let user = UsersData.currentUser.ID;
                                return <>
                                    <CarouselItem>
                                        <div className='w-full p-2 rounded-md bg-white shadow-lg'>
                                            <a className='hover:cursor-pointer' href={design.URL} target='_blank' rel="noreferrer"  >
                                                <img src={design.URL} alt={design.ID?.toString()} className='w-full  object-cover' />
                                            </a>

                                            <div className='flex flex-col my-20 gap-2'>
                                                {design.DESGINE_COMMENTS?.slice((design.DESGINE_COMMENTS.length - 1) - 3, design.DESGINE_COMMENTS.length).map(comment => {
                                                    return <div className={`${user === comment.USER_ID ? "self-start bg-blue-100" : "self-end bg-yellow-100"} p-2 rounded-md shadow`}>
                                                        <h6 className='text-center'>{comment.STAMP_USER}</h6>
                                                        <div>{comment.COMMENT}</div>
                                                    </div>
                                                })}
                                            </div>

                                            <div className='w-full h-full my-8'>
                                                <InputCustom DefaultValue='علق هنا' inputChangedVal={(val) => {
                                                    let value = val as string
                                                    setCommentReq(prev => ({ ...prev, COMMENT: value, DESGIN_ID: design.ID! }))

                                                }} EndActionIcon={
                                                    <div className='w-full h-full flex items-center justify-center'>
                                                        <SendIcon />
                                                    </div>
                                                } EndActionCallback={() => {
                                                    sendDesignCommentToServer()
                                                }} />
                                            </div>

                                            <div className='mt-2 ml-0 hover:pr-2 hover:font-bold transition-all duration-300 hover:cursor-pointer'>
                                                <p className='underline text-blue-500'>المحادثه كامله !!</p>
                                            </div>

                                        </div>
                                    </CarouselItem>
                                </>
                            })}
                        </Carousel>
                    </>
                )}
                {detailedFinish?.bathroomFinish1?.length > 0 && (
                    <>
                        <p>حمام 1</p>
                        <Carousel itemWidth={20}>
                            {detailedFinish.bathroomFinish1.map(design => {
                                let user = UsersData.currentUser.ID;
                                return <>
                                    <CarouselItem>
                                        <div className='w-full p-2 rounded-md bg-white shadow-lg'>
                                            <a className='hover:cursor-pointer' href={design.URL} target='_blank' rel="noreferrer"  >
                                                <img src={design.URL} alt={design.ID?.toString()} className='w-full  object-cover' />
                                            </a>

                                            <div className='flex flex-col my-20 gap-2'>
                                                {design.DESGINE_COMMENTS?.slice((design.DESGINE_COMMENTS.length - 1) - 3, design.DESGINE_COMMENTS.length).map(comment => {
                                                    return <div className={`${user === comment.USER_ID ? "self-start bg-blue-100" : "self-end bg-yellow-100"} p-2 rounded-md shadow`}>
                                                        <h6 className='text-center'>{comment.STAMP_USER}</h6>
                                                        <div>{comment.COMMENT}</div>
                                                    </div>
                                                })}
                                            </div>

                                            <div className='w-full h-full my-8'>
                                                <InputCustom DefaultValue='علق هنا' inputChangedVal={(val) => {
                                                    let value = val as string
                                                    setCommentReq(prev => ({ ...prev, COMMENT: value, DESGIN_ID: design.ID! }))

                                                }} EndActionIcon={
                                                    <div className='w-full h-full flex items-center justify-center'>
                                                        <SendIcon />
                                                    </div>
                                                } EndActionCallback={() => {
                                                    sendDesignCommentToServer()
                                                }} />
                                            </div>

                                            <div className='mt-2 ml-0 hover:pr-2 hover:font-bold transition-all duration-300 hover:cursor-pointer'>
                                                <p className='underline text-blue-500'>المحادثه كامله !!</p>
                                            </div>

                                        </div>
                                    </CarouselItem>
                                </>
                            })}
                        </Carousel>
                    </>
                )}
                {detailedFinish?.bathroomFinish2?.length > 0 && (
                    <>
                        <p>حمام 2</p>
                        <Carousel itemWidth={20}>
                            {detailedFinish.bathroomFinish2.map(design => {
                                let user = UsersData.currentUser.ID;
                                return <>
                                    <CarouselItem>
                                        <div className='w-full p-2 rounded-md bg-white shadow-lg'>
                                            <a className='hover:cursor-pointer' href={design.URL} target='_blank' rel="noreferrer"  >
                                                <img src={design.URL} alt={design.ID?.toString()} className='w-full  object-cover' />
                                            </a>

                                            <div className='flex flex-col my-20 gap-2'>
                                                {design.DESGINE_COMMENTS?.slice((design.DESGINE_COMMENTS.length - 1) - 3, design.DESGINE_COMMENTS.length).map(comment => {
                                                    return <div className={`${user === comment.USER_ID ? "self-start bg-blue-100" : "self-end bg-yellow-100"} p-2 rounded-md shadow`}>
                                                        <h6 className='text-center'>{comment.STAMP_USER}</h6>
                                                        <div>{comment.COMMENT}</div>
                                                    </div>
                                                })}
                                            </div>

                                            <div className='w-full h-full my-8'>
                                                <InputCustom DefaultValue='علق هنا' inputChangedVal={(val) => {
                                                    let value = val as string
                                                    setCommentReq(prev => ({ ...prev, COMMENT: value, DESGIN_ID: design.ID! }))

                                                }} EndActionIcon={
                                                    <div className='w-full h-full flex items-center justify-center'>
                                                        <SendIcon />
                                                    </div>
                                                } EndActionCallback={() => {
                                                    sendDesignCommentToServer()
                                                }} />
                                            </div>

                                            <div className='mt-2 ml-0 hover:pr-2 hover:font-bold transition-all duration-300 hover:cursor-pointer'>
                                                <p className='underline text-blue-500'>المحادثه كامله !!</p>
                                            </div>

                                        </div>
                                    </CarouselItem>
                                </>
                            })}
                        </Carousel>
                    </>
                )}
            </div>
            {(UsersData.currentUser.ID === AppCreator) && !Accepted && (
                <div className='mt-4 w-1/4 self-end'>
                    <EgButton HandleClick={ApproveDesign}>
                        <h6>الموافقه ع التصميم</h6>
                    </EgButton>
                </div>
            )}


        </div>
    )
}

export default ImgDiscussion